import React, { useState, useCallback } from 'react';
import PropTypes from "prop-types"
import FormContainer from '../../forms/FormContainer'

const RelatedProducts = (props) =>  {

  const [tab, setTab] = useState(0)
  const [brands, setBrands] = useState(props.suggested_brands)
  const [includedBrands, setIncludedBrands] = useState([])

  const toggleBrand = function(brand, key) {
    if(includedBrands.includes(brand)) {
      //remove brand
      let newBrands = includedBrands.filter(obj=>obj.id!=brand.id)
      setIncludedBrands(newBrands)
      props.removeBrandFromCatalog(brand.id)
    } else {
      //add brand
      setIncludedBrands([...includedBrands, brand])
      props.addBrandToCatalog(brand.id)
    }
  }

  const renderBrands = function() {
    if(brands.length === 0) { return (<p>No brands selected</p>) }
    return brands.map((brand, i) => {
      return(
        <div key={i} className="selectedProductListItem">
          <img src={brand.brand_image_url} />
          <p>{brand.name}</p>
          <p
            onClick={() => toggleBrand(brand, i)}
            className={includedBrands.includes(brand) ? "removeProductButton" : "addProductButton"}
            >{includedBrands.includes(brand) ? "Remove" : "Add"}</p>

        </div>)
    })
  }

  return(
    <FormContainer onClose={props.onClose}>
      <h2>Add related brands</h2>
      <div style={{maxHeight: '50vh', overflow: 'scroll'}}>
        {renderBrands()}
      </div>
      <div style={{
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: 50
      }}>
        <p
          onClick={props.onNext}
          style={{width: 'auto'}}
          className="button_primary">Next: Organize Catalog</p>
      </div>
    </FormContainer>
  )
}

export default RelatedProducts
