import React from 'react';
import { Page, Text, View, Image, Font } from '@react-pdf/renderer';
import LogoImage from "../../images/logo_white.png";
import moment from 'moment'
import Gotham from '../../utils/fonts/Gotham-Thin.otf'
import GothamBold from '../../utils/fonts/Gotham-Bold.otf'

export default React.memo(CoverPage, (props, nextProps) => {
  return !nextProps.loaded
})


function CoverPage(props)  {

  Font.register({ family: 'Gotham', fonts: [
      {src: Gotham, fontStyle: 'normal', fontWeight: 'normal', letterSpacing: "-2%"},
    ]
   })
   Font.register({ family: 'GothamBold', fonts: [
       {src: GothamBold, fontStyle: 'normal', fontWeight: 'normal', letterSpacing: "-2%"},
     ]
  })
  Font.registerHyphenationCallback(word => {
    // Return entire word as unique part
    return [word];
  });

  const { first_name, last_name, email, phone } = props.user

  return(
      <Page size="A4"
        inputRef="Cover Page"
        style={{
          flexDirection: 'row',
          backgroundColor: '#fff',
          width: '100%',
          orientation: 'portrait',
          fontFamily: 'Gotham',
          backgroundColor: 'white'}}>
          <View style={{
            width: '100%',
            height: '100%',
            backgroundColor: '#14467C',
            display: 'flex',
            justifyContent: 'space-between'
          }}>
            <View style={{display: 'flex', flexDirection: 'row', background: '#D79B81', justifyContent: 'space-between'}}>
              <Image
                style={{
                  objectFit: 'cover',
                  marginTop: 50,
                  width: '50%'}}
                alt="images"
                src={LogoImage} />
                <View
                  style={{
                    width: '50%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                    marginTop: 20
                  }}>
                    <View
                      style={{
                        backgroundColor: '#D79B81',
                        width: 120,
                        height: 24,
                        paddingRight: 10,
                      }} />
                      <Text style={{marginLeft:20, color: 'white', textTransform: 'uppercase', fontSize: 24, fontFamily: 'GothamBold'}}>{`${moment(props.catalog.date).format("MMM YYYY")}`}</Text>
                </View>
            </View>
            <View style={{padding: 30, paddingLeft: 50}}>
              <Text
                style={{
                  color: 'white',
                  fontSize: 70,
                  fontFamily: 'GothamBold',
                  flexWrap: 'wrap',
                }}>{props.catalog.name}</Text>
            </View>
            <View style={{
              display: 'flex',
              flexDirection: 'row',
              alignSelf: 'flex-end',
              justifyContent: 'flex-end'
            }}>
              <View style={{
                backgroundColor: '#D79B81',
                bottom: 0,
                height: 250,
                width: '50%',
                padding: 25
              }}>
                <Text style={{fontSize: 32, color: '#0E0E2C', fontWeight: 700, marginTop: 30}}>Contact</Text>
                <Text style={{fontSize: 18, color: '#0E0E2C', paddingTop: 20}}>{first_name} {last_name != "undefined" ? last_name : ""}</Text>
                <Text style={{fontSize: 18, color: '#0E0E2C', paddingTop: 10}}>{phone != "undefined" ? phone : ""}</Text>
                <Text style={{fontSize: email.length > 25 ? 15 : 18, color: '#0E0E2C', paddingTop: 10}}>{email != "undefined" ? email : ""}</Text>
              </View>
              <View style={{
                backgroundColor: "#FFFFFF",
                bottom: 0,
                height: 150,
                width: '50%',
                alignSelf: 'flex-end'
              }}>
                {props.catalog.customer.image_url != null &&
                  <Image
                    style={{
                      objectFit: 'contain',
                      padding: 10
                    }}
                    alt="images"
                    src={props.catalog.customer.image_url} />
                }
              </View>
            </View>
          </View>
      </Page>
    )
}
