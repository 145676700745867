import React from "react"
import PropTypes from "prop-types"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const DateInput = props => (
  <div style={{marginTop: 20, marginBottom: 20}}>
  <h6 className="inputLabel">{props.label}</h6>
  <DatePicker
    selected={props.date}
    onChange={(date) => props.updateValue(props.attribute, date)} />
  </div>
)

export default DateInput;
