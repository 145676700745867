import React from "react";
import PropTypes from "prop-types";
import CatalogProductsTable from '../../products/CatalogProductsTable'
import productApiHelper from '../../utils/productApiHelper'
import catalogApiHelper from '../../utils/catalogApiHelper'
import LoadingSection from '../../utils/LoadingSection'
import AddRecordButton from '../../forms/AddRecordButton'
import Button from '../../forms/Button'
import SelectedProducts from './SelectedProducts'
import RelatedProducts from './RelatedProducts'
import FormContainer from '../../forms/FormContainer'

class AddProductsSection extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      viewSelectedProducts: false,
      catalog: {},
      totalPages: '',
      catalog_brands: [],
      loaded: false,
      products: [],
      relatedProductsOpen: false,
      noProductsOpen: false,
      suggested_brands: [],
      searchParams: {
        globalFilter: '',
        businessUnit: '',
        category: '',
        brand: '',
        page: 1
      }
    }

    this.loadProducts = this.loadProducts.bind(this)
    this.loadSearchableValues = this.loadSearchableValues.bind(this)
    this.addToCatalog = this.addToCatalog.bind(this)
    this.removeFromCatalog = this.removeFromCatalog.bind(this)
    this.addBrandToCatalog = this.addBrandToCatalog.bind(this)
    this.removeBrandFromCatalog = this.removeBrandFromCatalog.bind(this)
    this.toggleSelectedProducts = this.toggleSelectedProducts.bind(this)
    this.getCatalogProducts = this.getCatalogProducts.bind(this)
    this.checkRelatedProducts = this.checkRelatedProducts.bind(this)
    this.showRealtedProducts = this.showRealtedProducts.bind(this)
    this.updateSearchParams = this.updateSearchParams.bind(this)
    this.clearFilters = this.clearFilters.bind(this)
  }

  async componentDidMount() {
     await this.loadProducts()
     await this.getCatalogProducts()
  }

  clearFilters() {
    this.setState({
      searchParams: {
        globalFilter: '',
        businessUnit: '',
        category: '',
        brand: '',
        archived: 'false',
        page: 1
      }
    }, () => this.loadProducts())
  }

  async getCatalogProducts() {
    this.setState({
      loaded: false
    })
    let data = {
      catalog_id: this.props.catalog.id,
    }

    let response = await catalogApiHelper.getCatalogProducts(data)
    this.setState({
      catalog: JSON.parse(response.data.catalog),
      catalog_brands: JSON.parse(response.data.brands),
      loaded: true
    })
  }

  async checkRelatedProducts() {
    let data = {
      catalog_id: this.props.catalog.id
    }
    let response = await catalogApiHelper.getRealtedProducts(data)
    this.setState({
      numberOfBrands: response.data.numberOfBrands,
      suggested_brands: JSON.parse(response.data.suggested_brands)
    }, () => this.showRealtedProducts())
  }

  async updateSearchParams(updatedParam) {
    const searchParams = {...this.state.searchParams, ...updatedParam}
    await this.setState({
      searchParams: searchParams
    })
    await this.loadProducts()
  }

  showRealtedProducts() {
    if(this.state.numberOfBrands < 1) {
      this.setState({
        noProductsOpen: true
      })
    } else if(this.state.suggested_brands.length > 0) {
      this.setState({
        relatedProductsOpen: true
      })
    } else {
      this.props.changeTab(2)
    }
  }

  addBrandToCatalog(brand_id) {
    let data = {
      catalog_id: this.props.catalog.id,
      brand_id: brand_id
    }
    catalogApiHelper.addToCatalog(data)
  }

  removeBrandFromCatalog(brand_id) {
    let data = {
      catalog_id: this.props.catalog.id,
      brand_id: brand_id
    }
    catalogApiHelper.removeFromCatalog(data)
  }

  addToCatalog(record) {
    let data = {
      catalog_id: this.props.catalog.id,
      product_id: record.id
    }
    catalogApiHelper.addToCatalog(data)
  }

  removeFromCatalog(record) {
    let data = {
      catalog_id: this.props.catalog.id,
      product_id: record.id
    }
    catalogApiHelper.removeFromCatalog(data)
  }

  async loadProducts() {
    this.loadSearchableValues()
    let data = {
      catalog_id: this.props.catalog.id,
      searchParams: this.state.searchParams
    }
    let response = await catalogApiHelper.loadProducts(data)
    this.setState({
      products: JSON.parse(response.data.products),
      totalPages: JSON.parse(response.data.totalPages)
    })
  }

  async loadSearchableValues() {
    let data = {
      catalog_id: this.props.catalog.id,
      searchParams: this.state.searchParams
    }
    let response = await catalogApiHelper.loadSearchableValues(data)
    this.setState({
      businessUnits: JSON.parse(response.data.businessUnits),
      categories: JSON.parse(response.data.categories),
      distributors: JSON.parse(response.data.distributors),
      brands: JSON.parse(response.data.brands),
    })
  }

  toggleSelectedProducts() {
    this.getCatalogProducts()
    this.setState({
      viewSelectedProducts: !this.state.viewSelectedProducts
    })
  }

  render() {
    if(!this.state.loaded) {return <LoadingSection />}
    return(
      <div style={{height: '100vh'}}>
        {!this.state.viewSelectedProducts && <CatalogProductsTable
          products={this.state.products}
          totalPages={this.state.totalPages}
          searchParams={this.state.searchParams}
          updateSearchParams={this.updateSearchParams}
          catalog_products={this.state.catalog.products}
          categories={this.state.categories}
          brands={this.state.brands}
          addToCatalog={this.addToCatalog}
          removeFromCatalog={this.removeFromCatalog}
          catalog={true}
          addBrandToCatalog={this.addBrandToCatalog}
          removeBrandFromCatalog={this.removeBrandFromCatalog}
          clearFilters={this.clearFilters}
        />}
        {this.state.viewSelectedProducts &&
          <SelectedProducts
            catalog_products={this.state.catalog.products}
            catalog_brands={this.state.catalog_brands}
            removeBrandFromCatalog={this.removeBrandFromCatalog}
            removeFromCatalog={this.removeFromCatalog}
            onClose={this.toggleSelectedProducts}
          />
        }
        {this.state.noProductsOpen &&
          <FormContainer onClose={() => this.setState({noProductsOpen: false})}>
            <h3 style={{textAlign: 'center', marginTop: 20, marginBottom: 20}}>You don't have any products selected</h3>
          </FormContainer>
        }
        {this.state.relatedProductsOpen &&
            <RelatedProducts
              suggested_brands={this.state.suggested_brands}
              addBrandToCatalog={this.addBrandToCatalog}
              removeBrandFromCatalog={this.removeBrandFromCatalog}
              catalog_brands={this.state.catalog_brands}
              onClose={() => this.setState({relatedProductsOpen: false})}
              onNext={() => this.props.changeTab(2)}
            />
        }

        <div style={{
          display: 'flex',
          justifyContent: 'flex-end',
          flexDirection: 'row',
          marginTop: 15,
          marginRight: '3%'
        }}>
          <Button
            className="button_secondary"
            text={"View selected products"}
            onClick={this.toggleSelectedProducts}
          />
          <div style={{width: '5%'}}/>
          <Button
            text={"Continue"}
            onClick={() => this.checkRelatedProducts()}
          />
        </div>
      </div>
    )
  }
}

export default AddProductsSection
