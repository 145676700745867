import React from "react"
import PropTypes from "prop-types"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

const AddAnotherButton = props => (
  <button
    onClick={() => props.onClick()}
    style={{
        opacity: props.disabled ? .5 : 1,
        color: '#D79B81',
        cursor: 'pointer',
      }}
    disabled={props.disabled ? props.disabled : false}
  >
    <FontAwesomeIcon
      icon={faPlus}
      style={{marginRight: 10, color: '#D79B81'}}
    />
    {props.text}
  </button>
)

export default AddAnotherButton;
