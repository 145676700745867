import React from "react"
import PropTypes from "prop-types"

import SideNav from '../nav/SideNav'
import TopNav from '../nav/TopNav'
import CatalogContainer from '../catalogs/CatalogContainer'
import CreateCatalog from '../catalogs/CreateCatalog'
import ProductsContainer from '../products/ProductsContainer'
import CustomerContainer from '../customers/CustomerContainer'
import DistributorsContainer from '../distributors/DistributorsContainer'
import UsersContainer from '../admin/UsersContainer'
import EditProfile from '../users/EditProfile'
import axiosHelper from '../utils/axiosHelper'
import LoadingSection from '../utils/LoadingSection'
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

Sentry.init({
  dsn: "https://2f69679807eb451faabc9a9802703af2@o971742.ingest.sentry.io/5996164",
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

class DashboardContainer extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      tab: 1,
      previousTab: 1,
      loaded: false,
      user: [],
      catalogId: false
    }

    this.updateTab = this.updateTab.bind(this)
    this.backTab = this.backTab.bind(this)
    this.renderContents = this.renderContents.bind(this)
    this.goToCatalogView = this.goToCatalogView.bind(this)
  }

  async componentDidMount() {
    let response = await axiosHelper.loadDashboard()
    this.setState({
      user: JSON.parse(response.data.user),
      loaded: true
    })
  }

  goToCatalogView(catalogId) {
    this.setState({
      catalogId: catalogId
    }, () => this.updateTab(1))
  }

  updateTab(tab) {
    this.setState({
      previousTab: this.state.tab,
      tab: tab
    })
  }

  backTab(){
    this.setState({
      tab: this.state.previousTab
    })
  }

  renderContents() {
    switch (this.state.tab) {
      case 0:
        return(
          <ProductsContainer
            user={this.state.user}
            updateTab={this.updateTab}
          />
        )
        break;
      case 1:
        return(
          <CatalogContainer
            updateTab={this.updateTab}
            backTab={this.backTab}
            user={this.state.user}
            catalogId={this.state.catalogId}
          />
        )
        break;
      case 2:
        return(
          <CustomerContainer />
        )
        break;
      case 3:
        return(
          <DistributorsContainer />
        )
        break;
      case 4:
        return(
          <UsersContainer />
        )
        break;
      case 5:
        return(
          <EditProfile />
        )
      case 6:
        return(
          <CreateCatalog
            updateTab={this.updateTab}
            backTab={this.backTab}
            goToCatalogView={this.goToCatalogView}
          />
        )
      default:
        return null

    }
  }

 render() {
   if(!this.state.loaded){
     return (<LoadingSection style={{marginTop: '20%'}}/>)
   }
    return (
      <div style={{
        width: '100vw'
      }}>
        <SideNav
          tab={this.state.tab}
          updateTab={this.updateTab}
          user={this.state.user}
        />
        <TopNav
          user={this.state.user}
          updateTab={this.updateTab}
        />
        <div style={{width: '100vw', height: '100vh', overflowY: 'scroll'}}>
          <div style={{margin: 'auto', marginTop: 100, maxWidth: '100vw'}}>
            {this.renderContents()}
          </div>
        </div>
      </div>
    );
  }
}


export default DashboardContainer
