import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowUp,
  faArrowDown,
  faPen,
  faEllipsisH,
  faArchive,
  faSlidersH,
  faTrash
} from '@fortawesome/free-solid-svg-icons'
import GlobalFilter from '../tables/GlobalFilter'
import ArchivedFilter from '../tables/ArchivedFilter'

import {
  useTable,
  useFilters,
  useGroupBy,
  useSortBy,
  useExpanded,
  usePagination,
  useGlobalFilter
} from 'react-table'

  function CustomersTable(props) {
    const data = React.useMemo(
      () => props.customers
    )

    const [subFilterIsToggled, setSubFilterIsToggled] = useState(false);
    const toggleSubFilter = () => setSubFilterIsToggled(!subFilterIsToggled);

    const [menuOpen, toggleMenuOpen] = useState('');
    const toggleMenu = React.useCallback((customer) => toggleMenuOpen(menuOpen => customer === menuOpen ? '' : customer));


    const elipsisMenu = function(menuOpen, customer) {
      if(menuOpen === customer){
      return (
        <div className="button_dropdown_menu" style={{width: 150}}>
          <div className="button_dropdown_item">
            <FontAwesomeIcon
              icon={faArchive}
              style={{
                color: '#005AC3',
                marginLeft: 5,
                padding: 8,
                borderRadius: 4,
              }}
              />
            <p onClick={() => props.toggleArchiveCustomer(customer)}>{customer.archived ? 'Unarchive' : 'Archive'}</p>
          </div>
          <div className="button_dropdown_item">
            <FontAwesomeIcon
              icon={faTrash}
              style={{
                color: '#005AC3',
                marginLeft: 5,
                padding: 8,
                borderRadius: 4,
              }}
              />
            <p onClick={() => {
              props.toggleDeleteCustomer(customer)
              toggleMenu()
            }}>{'Delete'}</p>
          </div>
        </div>
      )}
      else {return null}
    }

    const columns = React.useMemo(
     () => [
       {
         Header: 'Customer Image',
         Cell: ({ cell }) => (
           <img
            style={{maxWidth: 100, maxHeight: 100}}
            src={`${cell.row.original.image_url}`} />
         )
       },
       {
         Header: 'Customer Name',
         accessor: 'name', // accessor is the "key" in the data
       },
       {
         Header: 'Archive Status',
         accessor: 'archived', // accessor is the "key" in the data
       },
       {
         Header: 'Internal Notes',
         Cell: ({ cell }) => (
           <div className="ql-editor" dangerouslySetInnerHTML={{ __html: `${cell.row.original.notes}` }}/>
         )
        },
       {
         Header: "Actions",
         Cell: ({ cell }) => (
           <div className="table_action_column">
             <FontAwesomeIcon
               icon={faPen}
               onClick={() => props.editRecord(cell.row.original)}
               style={{
                 color: '#005AC3',
                 border: '1px solid #EEEEEE',
                 marginLeft: 5,
                 padding: 8,
                 borderRadius: 4,
                 cursor: 'pointer'
               }}
               />
                <FontAwesomeIcon
                 onClick={() => toggleMenu(cell.row.original)}
                 icon={faEllipsisH}
                 style={{
                   color: '#005AC3',
                   marginLeft: 5,
                   padding: 8,
                   borderRadius: 4,
                   cursor: 'pointer'
                 }}
                 />
               {elipsisMenu(menuOpen, cell.row.original)}
           </div>
          )
        }
     ],
     [menuOpen]
   )

    const tableInstance = useTable(
      {
        columns,
        data,
        initialState: {
          hiddenColumns: ['archived'],
          filters: [{id: "archived", value: "false"}]
        },
      },
      useGlobalFilter,
      useFilters,
      useGroupBy,
      useSortBy,
      useExpanded,
      usePagination,
    )

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
      preGlobalFilteredRows,
      setGlobalFilter,
      state,
      setFilter,
      setHiddenColumns
    } = tableInstance

    const setCustomFilter = function(accessor, value) {
      setFilter(accessor, value)
    }

    const rowStyle = function(row) {
      if(row.original.archived){
        return "trArchived"
      }
      return "trStandard"
    }

    return(
      <div style={{width: '100vw'}}>
        <div className="tableFilterSection" >

          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', margin: 20}}>
            <GlobalFilter
              preGlobalFilteredRows={preGlobalFilteredRows}
              globalFilter={state.globalFilter}
              setGlobalFilter={setGlobalFilter}
            />
            <FontAwesomeIcon
              icon={faSlidersH}
              onClick={toggleSubFilter}
              style={{
                color: subFilterIsToggled ? '#14467C' : 'white' ,
                backgroundColor: subFilterIsToggled ? 'white' : '#14467C',
                marginLeft: 5,
                padding: 10,
                borderRadius: 10,
                cursor: 'pointer',
                border: '#14467C 2px solid' ,
              }}
              />
          </div>
          {subFilterIsToggled && <div style={{display: 'flex', flexDirection: 'row', alignItems: 'flex-start', margin: 20}}>
            <ArchivedFilter
              setCustomFilter={setCustomFilter}
              filters={state.filters}
            />
          </div>}
        </div>
        <table {...getTableProps()} className="tableStandard">
          <thead>
            {// Loop over the header rows
            headerGroups.map(headerGroup => (
              // Apply the header row props
              <tr {...headerGroup.getHeaderGroupProps()}>
                {// Loop over the headers in each row
                headerGroup.headers.map(column => (
                  // Apply the header cell props
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {// Render the header
                    column.render('Header')}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? <FontAwesomeIcon
                            icon={faArrowUp}
                            style={{
                              color: '#D4D4D7',
                              marginLeft: 5,
                            }}
                            />
                          : <FontAwesomeIcon
                            icon={faArrowDown}
                            style={{
                              color: '#D4D4D7',
                              marginLeft: 5,
                            }}
                            />
                        : ''}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          {/* Apply the table body props */}
          <tbody {...getTableBodyProps()}>
            {// Loop over the table rows
            rows.map(row => {
              // Prepare the row for display
              prepareRow(row)
              return (
                // Apply the row props
                <tr {...row.getRowProps()} className={rowStyle(row)}>
                  {// Loop over the rows cells
                  row.cells.map(cell => {
                    // Apply the cell props
                    return (
                      <td {...cell.getCellProps()}>
                        {// Render the cell contents
                        cell.render('Cell')}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    )
  }









export default CustomersTable
