import React from "react"
import PropTypes from "prop-types"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

// className
// button_secondary

const Button = props => (
  <div style={{position: 'relative'}}>
  <button
    className={props.className ? props.className : "button_primary" }
    onClick={() => props.onClick()}
    style={{
      opacity: props.disabled ? .5 : 1,
    }}
    disabled={props.disabled ? props.disabled : false}
  >
    {props.icon && <FontAwesomeIcon
      icon={faPlus}
      style={{marginRight: 10, color: 'white'}}
    />}
    {props.text}
    {props.disabled}
  </button>
  {props.dropdown}
  </div>
)

export default Button;
