import React from 'react';
import { setCustomFilter } from 'react-table';
import DropdownSelect from '../forms/DropdownSelect'


function ArchivedFilter({
  setCustomFilter,
  filters,
  categories
  }) {

  const setFilter = function(attribute, value) {
    let existingFiliter = filters.filter(obj=>obj.value===value.value);
    existingFiliter.length == 0 ?
      setCustomFilter("archived", value.value)
      : setCustomFilter("archived", "")
  }

  const existingFilter = filters.filter(obj=>obj.id==="archived");

  const isSelected = function() {
    return existingFilter[0].value === 'false' ? 'Active' : 'Archived'
  }

  const options = [
    {type: 'Active', value: 'false'},
    {type: 'Archived', value: 'true'},
  ]

  return (
    <span className="brandFilters">
      <DropdownSelect
        attribute="type"
        options={options}
        optionName={"type"}
        label="Status"
        placeholder="Select option"
        selectOption={setFilter}
        selected={isSelected()}
      />
    </span>
  )
}
// {existingFilter.length != 0 &&
//   <p className="clearFilterButton" onClick={() => setFilter("archived", "")}>Clear Filter</p>
// }

export default ArchivedFilter
