import React from "react"
import PropTypes from "prop-types"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'


class DropdownSelect extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      dropdownOpen: false
    }

    this.renderOptions = this.renderOptions.bind(this)
    this.selectOption = this.selectOption.bind(this)
  }

  selectOption(item) {
    this.setState({
      dropdownOpen: false
    })
    this.props.selectOption(this.props.attribute, item)
  }

  renderOptions() {
    return this.props.options.map((item, i) => {
      return (
        <h6 key={i} onClick={() => this.selectOption(item)}>{item[this.props.optionName]}</h6>)
    })
  }

  render() {
    return(
      <div style={{marginTop: 20, marginBottom: 20, position: 'relative'}}>
        <h6 className="inputLabel">{this.props.label}</h6>
        <div
          className="dropdownSelectField"
          style={{backgroundColor: !this.state.dropdownOpen ? 'rgba(238, 238, 238, 0.3)' : 'white', border: !this.state.dropdownOpen ? 'none' : '1px solid #14467C'}}
          onClick={() => this.setState({dropdownOpen: !this.state.dropdownOpen})}>
            <h6 className={this.props.selected === '' ? "halfPurple" : ''}>{this.props.selected != '' ? this.props.selected : this.props.placeholder ? this.props.placeholder : 'Type or select an option'}</h6>
            {this.state.dropdownOpen
              ?
              <FontAwesomeIcon
                icon={faChevronUp}
                style={{color: '#14467C', marginLeft: 20}}
              />
              :
              <FontAwesomeIcon
                icon={faChevronDown}
                style={{color: 'rgba(14, 14, 44, 0.5)', marginLeft: 20}}
              />
            }
        </div>
        {this.state.dropdownOpen &&
          <div className="dropdownSelectContainer">
          {this.renderOptions()}
          </div>
        }
      </div>
    )
  }

}

export default DropdownSelect;
