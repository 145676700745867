import React from "react"
import PropTypes from "prop-types"
import FormContainer from '../../forms/FormContainer'
import MultiFileDropzone from '../../forms/MultiFileDropzone'
import catalogPagesApiHelper from '../../utils/catalogPagesApiHelper'
import TextInput from '../../forms/TextInput'
import AddRecordButton from '../../forms/AddRecordButton'

class CatalogPagesContainer extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loaded: false,
      businessUnitPages: [],
      catalogPages: [],
      newCatalogPage: {
        title: '',
        id: '',
        page_images_url: []
      },
      timeout: null

    }

    this.getPages = this.getPages.bind(this)
    this.businessUnitPages = this.businessUnitPages.bind(this)
    this.updateBusinessUnitPage = this.updateBusinessUnitPage.bind(this)
    this.removeBusinessUnitPage = this.removeBusinessUnitPage.bind(this)
    this.updateCatalogPageImage = this.updateCatalogPageImage.bind(this)
    this.removeCatalogPageImage = this.removeCatalogPageImage.bind(this)
    this.addCatalogPage = this.addCatalogPage.bind(this)
    this.updateCatalogPageName = this.updateCatalogPageName.bind(this)
    this.removeCatalogPage = this.removeCatalogPage.bind(this)
  }

  updateBusinessUnitPage(record, file) {
    const formData = new FormData()
    formData.append("id", record)
    formData.append('cover_pages', file, 'image')

    catalogPagesApiHelper.addBusinessUnitPage(formData)
    .then((success) => {
      this.getPages()
    })
    .catch((error) => {
      console.log(error)
    })
  }

  removeCatalogPage(record) {
    let data = {
      id: record.id,
    }
    catalogPagesApiHelper.removeCatalogPage(data)
    .then((success) => {
      this.getPages()
    })
    .catch((error) => {
      console.log(error)
    })
  }


  removeBusinessUnitPage(record, index) {
    let data = {
      id: record,
      image_index: index
    }
    catalogPagesApiHelper.removeBusinessUnitPage(data)
    .then((success) => {
      this.getPages()
    })
    .catch((error) => {
      console.log(error)
    })
  }

  updateCatalogPageImage(record, file) {
    const formData = new FormData()
    formData.append("id", record)
    formData.append('page_images', file, 'image')

    catalogPagesApiHelper.addCatalogPageImage(formData)
    .then((success) => {
      this.getPages()
    })
    .catch((error) => {
      console.log(error)
    })
  }

  removeCatalogPageImage(record, index) {
    let data = {
      id: record,
      image_index: index
    }
    catalogPagesApiHelper.removeCatalogPageImage(data)
    .then((success) => {
      this.getPages()
    })
    .catch((error) => {
      console.log(error)
    })
  }

  updateCatalogPageName(index, value) {
    const catalogPages = this.state.catalogPages
    catalogPages[index].title = value
    this.setState({
      catalogPages: catalogPages
    }, () => clearTimeout(timeout))

    //set timeout to update db
    const { timeout } = this.state;
    clearTimeout(timeout);
    this.setState({
      timeout: setTimeout(() => {
        catalogPagesApiHelper.updateCatalogPage(this.state.catalogPages[index])
        .then((success) => {
          this.getPages()
        })
        .catch((error) => {
          console.log(error)
        })
      }, 500)
    })
  }

  componentWillUnmount() {
    clearTimeout(this.state.timeout);
  }

  businessUnitPages() {
    return this.state.businessUnitPages.map((business_unit, i) => {
      return(
        <div key={i}>
          <h4>{business_unit.name} Cover Page(s)</h4>
          <MultiFileDropzone
            updateFile={this.updateBusinessUnitPage}
            removeImage={this.removeBusinessUnitPage}
            files={business_unit.cover_pages_url}
            record={business_unit.id}
          />
        </div>
      )
    })
  }

  renderCatalogPages() {
    return this.state.catalogPages.map((catalogPage, i) => {
      return(
        <div key={i}>
          <div style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            cursor: 'pointer'
          }}>
            <TextInput
              label=""
              attribute={i}
              placeholder="Page Name (required)"
              value={catalogPage.title}
              handleChange={this.updateCatalogPageName}
            />
            <p
              style={{
                backgroundColor: '#BD806D',
                color: 'white',
                padding: '5px 10px',
                borderRadius: 30
              }}
              onClick={() => this.removeCatalogPage(catalogPage)}>Delete</p>
          </div>
          {catalogPage.id != '' &&
            <MultiFileDropzone
              updateFile={this.updateCatalogPageImage}
              removeImage={this.removeCatalogPageImage}
              files={catalogPage.page_images_url}
              record={catalogPage.id}
            />
          }

        </div>
      )
    })
  }

  addCatalogPage() {
    this.setState({
      catalogPages: [...this.state.catalogPages, this.state.newCatalogPage]
    })
  }

  async getPages()  {
    let response = await catalogPagesApiHelper.loadAllCatalogPages()
    this.setState({
      businessUnitPages: JSON.parse(response.data.business_unit_pages),
      catalogPages: JSON.parse(response.data.catalog_pages),
      loaded: true
    })
  }

  async componentDidMount() {
    //get static pages from db
    this.getPages()
  }

  render() {
    if(!this.state.loaded) { return null }
    return(
      <FormContainer onClose={this.props.onClose}>
        <h2 style={{marginBottom: 15}}>All Static Catalog Pages</h2>
        {this.businessUnitPages()}
        <h3>Optional Pages</h3>
        {this.renderCatalogPages()}
        <AddRecordButton
          onClick={this.addCatalogPage}
          text={"Add new catalog page"}
        />
      </FormContainer>
    )
  }
}

export default CatalogPagesContainer
