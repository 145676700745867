import React from "react"
import PropTypes from "prop-types"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

const FormContainer = props => (
  <div style={{ margin: 0}}>
    <div style={{
      padding: 40,
      backgroundColor: 'white',
      position: 'absolute',
      zIndex: 100,
      top: '10%',
      left: 0,
      right: 0,
      maxWidth: 600,
      margin: 'auto',
      borderRadius: 20,
      padding: '5%',
      marginBottom: 20
      }}>
        <FontAwesomeIcon
          icon={faTimes}
          style={{
            color: '#D79B81',
            cursor: 'pointer',
            float: 'right'
          }}
          onClick={() => props.onClose()}
          />
        <h2 style={{marginBottom: 40, marginTop: 40}}>{props.title}</h2>
        {props.children}
    </div>
    <div
     onClick={() => props.onClose()}
     style={{
       top: 0,
       left: 0,
       position: 'fixed',
       width: '100%',
       height: '150%',
       display: 'flex',
       justifyContent: 'center',
       zIndex: 99,
       backgroundColor: 'rgba(114, 114, 114, 0.9)'}}>
     </div>
  </div>
)

export default FormContainer;

//<ScrollToPosition />
