import React, {useState, useEffect, useCallback} from "react"
import PropTypes from "prop-types"
import Select from "react-dropdown-select";

const SelectInput = props => {
  const [mySelected, setMySelected] = useState((props.selected != undefined && props.selected.length != 0) ? [props.options[props.options.findIndex(item => props.selected.id === item.id)]] : []);

  useEffect(() => {
    setMySelected(props.selected);
  }, [props.selected]);

  return(
    <div style={{marginTop: 20, marginBottom: 20}}>
      <h6 className="inputLabel">{props.label}</h6>
      <Select
        options={props.options}
        searchBy={props.searchBy}
        labelField={props.labelField}
        valueField={props.valueField}
        placeholder="Select or type..."
        values={mySelected}
        onCreateNew={(value) => props.multi ? props.createNew(props.attribute, value) : props.updateValue(props.attribute, value[0])}
        onChange={(value) => props.multi ? props.updateValue(props.attribute, value) : props.updateValue(props.attribute, value[0])}
        create={props.create}
        multi={props.multi ? props.multi : false}
      />
    </div>
  )
}


export default SelectInput
