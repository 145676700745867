import React from 'react';
import { setCustomFilter } from 'react-table';
import DropdownSelect from '../forms/DropdownSelect'


function CustomerFilter({
  setCustomFilter,
  filters,
  customers
  }) {

  const existingFilter = filters.filter(obj=>obj.id==="customer.name");

  const setFilter = function(attribute, value) {
    let existingFiliter = filters.filter(obj=>obj.value===value.name);
    existingFiliter.length == 0 ?
      setCustomFilter("customer.name", value.name)
      : setCustomFilter("customer.name", "")
  }

  const isSelected = function() {
    let existingFilter = filters.filter(obj=>obj.id==="customer.name");
    return existingFilter.length == 0 ?
      "All customers"
      : existingFilter[0].value
  }


  return (
    <span className="brandFilter" style={{marginLeft: 10}}>
      <DropdownSelect
        attribute="customer"
        options={customers}
        optionName={"name"}
        label="Customer"
        placeholder="Select option"
        selectOption={setFilter}
        selected={isSelected()}
      />
      {existingFilter.length != 0 &&
        <p className="clearFilterButton" onClick={() => setFilter("customer.name", "")}>Clear Filter</p>
      }
    </span>
  )
}

export default CustomerFilter
