import React, { useState, useEffect } from 'react';
import Select from "react-dropdown-select";

function BrandFilter({
  setCustomFilter,
  currentFilter,
  brands
  }) {

  const [filters, setFilters] = useState(brands.filter((brand) => brand.name === currentFilter))

  useEffect(() => {
    let selectedBrands = brands.filter((brand) => brand.name === currentFilter)
    setFilters(selectedBrands);

  }, [currentFilter]);

  const setFilter = function(value) {
    if(value.length > 0) {
      setCustomFilter(value[0].name)
    } else {
      setCustomFilter("")
    }
  }

  return (
    <span className="brandFilters" key={currentFilter}>
      <div style={{marginTop: 20, marginBottom: 20}}>
        <h6 className="inputLabel">Brand</h6>
        <Select
          options={brands}
          searchBy="name"
          labelField="name"
          valueField="id"
          placeholder="Find by brand..."
          values={filters}
          onChange={(value) => setFilter(value)}
          create={false}
        />
      </div>
    </span>
  )
}

export default BrandFilter
