import React from "react"
import PropTypes from "prop-types"
import FormContainer from '../../forms/FormContainer'
import AddRecordButton from '../../forms/AddRecordButton'
import TextInput from '../../forms/TextInput'
import DropdownSelect from '../../forms/DropdownSelect'
import productApiHelper from '../../utils/productApiHelper'
import FileDropzone from '../../forms/FileDropzone'
import Select from "react-dropdown-select";
import RotaryProduct from './RotaryProduct'

class ConfigurationSection extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
        id: '',
        name: '',
        description: '',
        logo: '',
        configuration_type: {}
    }

    this.updateValue = this.updateValue.bind(this)
    this.updateConfiguration = this.updateConfiguration.bind(this)
    this.updateSku = this.updateSku.bind(this)
    this.renderDistributors = this.renderDistributors.bind(this)
    this.updateFile = this.updateFile.bind(this)
    this.removeImage = this.removeImage.bind(this)
    this.additionalDistributor = this.additionalDistributor.bind(this)
    this.addDistributor = this.addDistributor.bind(this)
  }

  async updateConfiguration(attribute, value) {
    const configuration = value
    if(value != undefined) {
      if(typeof value.id == 'string') {
        const data = {
          name: value.name
        }
        const response = await productApiHelper.createConfiguration(data)
        let configuration = JSON.parse(response.data)
        await this.props.updateProduct(this.props.index, 'configuration_type', configuration)
        await this.props.updateProduct(this.props.index, 'configuration_type_id', configuration.id)
      } else {
        await this.props.updateProduct(this.props.index, 'configuration_type', configuration)
        await this.props.updateProduct(this.props.index, 'configuration_type_id', configuration.id)
      }
    } else {
      await this.props.updateProduct(this.props.index, 'configuration_type', [])
      await this.props.updateProduct(this.props.index, 'configuration_type_id', null)
    }
  }


  updateValue(attribute, value) {
    this.props.updateProduct(this.props.index, attribute, value)
  }

  updateFile(file) {
    this.props.updateProduct(this.props.index, 'product_image_url', file)
  }

  removeImage() {
    this.props.updateProduct(this.props.index, 'product_image_url', null)
  }

   render() {
      const { configuration_types, product, business_unit } = this.props
      const isRotary = business_unit.name === "Rotary"
      const selected = this.props.product.configuration_type != undefined ? [this.props.configuration_types[this.props.configuration_types.findIndex(item => this.props.product.configuration_type.id === item.id)]] : []
      return (
        <div key={this.props.index}>
          {!isRotary && <div style={{marginTop: 20, marginBottom: 20}}>
            <h6 className="inputLabel">Configuration</h6>
            <Select
              options={configuration_types}
              searchBy="name"
              labelField="name"
              valueField="id"
              placeholder="Select or type..."
              values={product.configuration_type != undefined ? [configuration_types[configuration_types.findIndex(item => product.configuration_type.id === item.id)]] : []}
              onChange={(value) => this.updateConfiguration('configuration_type', value[0])}
              create={true}
            />
          </div>}
          <FileDropzone
            label="Optional: Product Image"
            prompt="Click or drag to upload your product image"
            updateFile={this.updateFile}
            removeImage={this.removeImage}
            file={this.props.product.product_image_url}
          />
        </div>
      );
    }
}


export default ConfigurationSection
