import React from 'react';
import { Page, Text, View, Image, Font } from '@react-pdf/renderer';
import Gotham from '../../../utils/fonts/Gotham-Thin.otf'
import GothamBold from '../../../utils/fonts/Gotham-Bold.otf'
import ReactHtmlParser from 'react-html-parser'

const RotaryProduct = (props) =>  {

  Font.register({ family: 'Gotham', fonts: [
      {src: Gotham, fontStyle: 'normal', fontWeight: 'normal', letterSpacing: "-2%"},
    ]
   })
   Font.register({ family: 'GothamBold', fonts: [
       {src: GothamBold, fontStyle: 'normal', fontWeight: 'normal', letterSpacing: "-2%"},
     ]
  })

  const titleText = {
    fontSize: 10,
    fontFamily: "GothamBold",
    width: 100,
    flexWrap: 'wrap',
    paddingLeft: 5,
    paddingRight: 15,
    wordWrap: 'wrap'
  }

  const descriptionText = {
    fontSize: 10,
    textAlign: 'left',
    alignSelf: 'flex-start',
    flexWrap: 'wrap',
    width: 100,
    wordWrap: 'break-word'
  }

  const itemRow = {
    display: 'flex',
    paddingBottom: 2,
    flexDirection: 'row',
  }

  const item = function(name, value, key) {
    return(
      <View style={itemRow} key={key}>
        <Text style={titleText}>{name}</Text>
        <Text style={descriptionText}>{value}</Text>
      </View>
    )
  }
  const brand_configuration = props.brand.brand_configurations.filter(bc => bc.configuration_type_id == props.product.configuration_type_id)[0]

  const { isLogicSet } = props
  const kerrSku = props.product.skus.filter(sku => sku.distributor_id == 1)[0]
  return(
    <View
      wrap={false}
      style={{
        display: 'flex',
        flexDirection: isLogicSet ? 'column' : 'row',
        justifyContent: 'space-between',
        marginTop: 15,
        width: isLogicSet ? '100%' : '45%',
        marginLeft: isLogicSet ? 0 : 20}}>
      <View>
       {(props.product.product_image_url != null || (brand_configuration != undefined && brand_configuration.image_url != null)) &&
        <Image
            style={{
              height: isLogicSet ? 200 : 150,
              width: '100%',
              alignSelf: isLogicSet ? 'center' : 'flex-start',
              maxWidth: isLogicSet ? '100%' : 40,
              marginRight: isLogicSet ? 0 : 10,
              objectFit: 'contain',
              marginBottom: isLogicSet ? 20 : 0
            }}
            alt="images"
            src={props.product.product_image_url != null ? props.product.product_image_url : brand_configuration.image_url} />
      }
      </View>
      <View>
          {item("Product name", props.product.name)}
          {props.product.configuration_type != null && item("Type", props.product.configuration_type.name)}
          {props.product.length != "" && item("Length", props.product.length)}
          {props.product.shape != null && item("Head Shape", props.product.shape.name)}
          {props.product.shank != null && item("Shank", props.product.shank.name)}
          {props.product.grit != null && item("Grit", props.product.grit.name)}
          {props.product.diameter != "" && item("Diameter", props.product.diameter)}
          {props.product.head_length != "" && item("Head Length", props.product.head_length)}
          {props.product.flutes != "" && item("Flutes", props.product.flutes)}
          {props.product.quantity != "" && item("Pkg Qty", props.product.quantity)}
          {kerrSku != undefined && item("Kerr", kerrSku.sku)}
          {props.distributors.map((distributor, i) => {
            const sku = props.product.skus.filter(sku => (sku.distributor_id == distributor.id && distributor.id != 1))[0]
            if(sku != undefined && sku.sku != "") { return item(distributor.name, sku.sku, i) }
          })}
      </View>
    </View>
  )
}



export default RotaryProduct
