import React from "react"
import PropTypes from "prop-types"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTooth, faBook, faPeopleArrows, faBars, faTruckLoading, faUsers } from '@fortawesome/free-solid-svg-icons'


class SideNav extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      menuOpen: false
    }

    this.renderNavItem = this.renderNavItem.bind(this)
    this.toggleMenu = this.toggleMenu.bind(this)
    this.navigate = this.navigate.bind(this)
  }

  navigate(id) {
    this.props.updateTab(id)
    this.toggleMenu()
  }

 renderNavItem(title, id, icon) {
   const selected = this.props.tab === id

   return(
     <div
      style={{
        backgroundColor: selected ? '#005AC3' : 'white',
        borderRadius: 10,
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        padding: 15,
        margin: 20,
        paddingLeft: 20,
        cursor: 'pointer',
        width: 200,

      }}
      key={id}
      onClick={() => this.navigate(id)}>
        <FontAwesomeIcon
          icon={icon}
          style={{
            height: 20,
            width: 20,
            color: selected ? 'white' : '#0E0E2C',
            marginRight: 20,
          }}
          />
        <h3 style={{color: selected ? 'white' : '#0E0E2C'}}>{title}</h3>
     </div>
   )
 }

 toggleMenu() {
   this.setState({
     menuOpen: !this.state.menuOpen
   })
 }

 render() {
    if(!this.state.menuOpen) {
      return(
        <div style={{
          width: 50,
          height: 50,
          zIndex: 99,
          position: 'absolute',
          marginTop: 10,
        }}>
          <div
            onClick={this.toggleMenu}
            className="hamburger_menu"
            style={{
              color:  '#D79B81',
              cursor: 'pointer',
              marginLeft: 20,
              marginTop: 20
            }}></div>
        </div>
      )
    }
    return (
      <div>
        <div
          style={{
            width: 300,
            height: '100vh',
            borderRight: '2px solid #F5F5F5',
            zIndex: 100,
            backgroundColor: 'white',
            position: 'absolute'
          }}>
            <div style={{
              display: 'flex',
              zIndex: 1,
              justifyContent: 'space-evenly',
            }}>
              <div className="menu_logo">
              </div>
              <div
                onClick={this.toggleMenu}
                className="hamburger_menu"
                style={{
                  color:  '#D79B81',
                  cursor: 'pointer',
                }}>
              </div>
            </div>
            {this.renderNavItem('Catalogs', 1, faBook)}
            {this.renderNavItem('Products', 0, faTooth)}
            {this.renderNavItem('Customers', 2, faPeopleArrows)}
            {this.props.user.role_id > 2 && this.renderNavItem('Dealers', 3, faTruckLoading)}
            {this.props.user.role_id  > 1 && this.renderNavItem('Users', 4, faUsers)}
        </div>
        <div
          onClick={this.toggleMenu}
          style={{position: 'absolute', zIndex: 10, backgroundColor: 'rgba(0,0,0,.1)', width: '100vw', height: '100vh'}}>
        </div>
      </div>
    );
  }
}


export default SideNav
