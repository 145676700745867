import React, { useState } from "react";
import { usePDF } from '@react-pdf/renderer';
import { Document, Page, Outline } from 'react-pdf/dist/esm/entry.webpack';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons'
import LoadingSection from '../../utils/LoadingSection'
import PdfPreview from '../pdf_sections/PdfPreview'


const ScrollCatalog = (props) => {
  const doc = (<PdfPreview
    catalog={props.catalog}
    brands={props.brands}
    business_units={props.business_units}
    user={props.user}
    sushiMenuIncluded={props.sushiMenuIncluded}
    catalogPages={props.catalogPages}
    catalogPagesIncluded={props.catalogPagesIncluded}
    tocIsLoaded={() => setTocIsLoaded(true)}
  />)

  const [instance, updateInstance] = usePDF({ document: doc })
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [tocIsLoaded, setTocIsLoaded] = useState(false);
  const pageNumberText = function(number) {
    if(number > 2 && number <= numPages) {
      return `Page ${number-2} of ${numPages-2}`
    } else if (number === 2) {
      return 'Inside cover'
    } else if (number === 1) {
      return 'Cover page'
    } else  {
      return ''
    }
  }

  const secondPageNumber = pageNumber

  const handleClick = function(newPageNumber) {
    if(newPageNumber < numPages+2 && newPageNumber > 0) {
      setPageNumber(newPageNumber)
    } else {
      return null
    }
  }

  function onDocumentLoadSuccess({numPages}) {
    setNumPages(numPages);
  }

  if (instance.loading || !tocIsLoaded) return <LoadingSection />;

  if (instance.error) return <div>Something went wrong: {error}</div>;

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: '3%',
        marginTop: '10%'
      }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'row',
          alignItems: 'center',
          width: '100%'
        }}>
        <div
          onClick={() => handleClick(pageNumber-2)}
          className={pageNumber-1 < 1 ? "iconButtonOutline disabled" : "iconButtonOutline"}
        >
          <FontAwesomeIcon icon={faArrowLeft} />
        </div>
        <div>
          <Document
            file={(instance.url)}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            {pageNumber-1 > 0 && <Page pageNumber={pageNumber-1} />}
            {pageNumber <= numPages && <Page pageNumber={pageNumber} />}
          </Document>
          <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
            {pageNumber-1 > 0 && <p style={{color: '#D4D4D7', width: '21vw', textAlign: 'center'}}>{pageNumberText(pageNumber-1)}</p>}
            <p style={{color: '#D4D4D7', float: 'right', width: '21vw', textAlign: 'center'}}>{pageNumberText(pageNumber)}</p>
          </div>
        </div>
        <div
          onClick={() => handleClick(pageNumber+2)}
          className={pageNumber > numPages ? "iconButtonOutline disabled" : "iconButtonOutline"}
          >
          <FontAwesomeIcon icon={faArrowRight} />
        </div>

      </div>
      <div style={{alignSelf: 'flex-end', marginTop: '20%'}}>
        <button
          className="button_primary"
          onClick={() => props.generatePdf(instance.url)}>Publish</button>
      </div>
    </div>
  );
}

export default ScrollCatalog
