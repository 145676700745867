import React from "react"
import PropTypes from "prop-types"
import FormContainer from '../forms/FormContainer'
import productApiHelper from '../utils/productApiHelper'
import AddRecordButton from '../forms/AddRecordButton'
import SecondaryButton from '../forms/SecondaryButton'

class DeleteProductForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loaded: false,
      catalog_products: []
    }

    this.checkCanDelete = this.checkCanDelete.bind(this)
    this.delete = this.delete.bind(this)
    this.archive = this.archive.bind(this)
  }

  componentDidMount() {
    this.checkCanDelete()
  }

  async checkCanDelete() {
    let data = {
      product_id: this.props.product.id
    }
    let response = await productApiHelper.checkCanDelete(data)

    this.setState({
      loaded: true,
      catalog_products: JSON.parse(response.data.product_catalogs)
    })
  }

  async delete() {
    let response = await productApiHelper.deleteProduct({id: this.props.product.id})
    this.props.loadProducts()
    this.props.toggleDeleteProduct([])
  }

  async archive() {
    this.props.toggleArchiveProduct(this.props.product)
  }

  render() {
    if (!this.state.loaded) {
      return null
    }
    return(
      <FormContainer
        onClose={() => this.props.toggleDeleteProduct([])}>
        {this.state.catalog_products.length > 0 &&
          <div className="modalStandard">
            <h2>Can not delete product.</h2>
            <h3>This product is already included in at least one catalog or proposal. {!this.props.product.archived ? `Instead, you can archive it so sales reps can not add it to future catalogs and proposals.` : `This product is already archived, which means sales reps can not add it to new catalogs or proposals.`}</h3>
            <p className="bold">Product</p>
            <p>{this.props.product.name}</p>

            <div className="two_button_section">
              {!this.props.product.archived && <SecondaryButton
                text="Archive Instead"
                onClick={this.archive}
                alert={false}
              />}
            </div>
          </div>
        }
        {this.state.catalog_products.length === 0 &&
          <div className="modalStandard">
            <h2>Are you sure you want to delete this product?</h2>
            <h3>Deleting this product will remove it from the system. Once deleted, to reference it, you will need to re-create it.</h3>
            {this.props.product.archived && <h3 style={{marginTop:10}}>This product is already archived, which means sales reps can not add it to new catalogs or proposals.</h3>}
            <p className="bold">Product</p>
            <p>{this.props.product.name}</p>

            <div className="two_button_section">
              {!this.props.product.archived && <SecondaryButton
                text="Archive Instead"
                onClick={this.archive}
                alert={false}
              />}
              <AddRecordButton
                text="Delete Forever"
                onClick={this.delete}
                alert={true}
              />
            </div>
          </div>
        }
      </FormContainer>
    )
  }
}

export default DeleteProductForm
