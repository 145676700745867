import React from "react"
import PropTypes from "prop-types"

import FormContainer from '../forms/FormContainer'
import AddRecordButton from '../forms/AddRecordButton'
import AddAnotherButton from '../forms/AddAnotherButton'
import TextInput from '../forms/TextInput'
import RichTextArea from '../forms/RichTextArea'
import DropdownSelect from '../forms/DropdownSelect'
import customerApiHelper from '../utils/customerApiHelper'
import FileDropzone from '../forms/FileDropzone'


class CustomerForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
        id: '',
        name: '',
        notes: '',
        image: null,
        distributor_id: '',
        distributor: {
          id: '',
          name: ''
        },
        disabled: true,
        loaded: false
    }

    this.updateValue = this.updateValue.bind(this)
    this.canSubmit = this.canSubmit.bind(this)
    this.submitForm = this.submitForm.bind(this)
    this.updateFile = this.updateFile.bind(this)
    this.removeImage = this.removeImage.bind(this)
  }

  componentDidMount() {
    if("id" in this.props.formRecord) {
      this.setState({
        id: this.props.formRecord.id,
        name: this.props.formRecord.name,
        notes: this.props.formRecord.notes,
        image: this.props.formRecord.image_url,
        distributor: this.props.formRecord.distributor,
        loaded: true
      })
    } else {
      this.setState({
        loaded: true
      })
    }
  }

  updateValue(attribute, value) {
    this.setState({
      [attribute]: value,
    }, () => this.canSubmit())
  }

  canSubmit() {
    this.setState({
      disabled: (this.state.name.length < 2 && this.state.distributor != null && this.state.distributor.id != '')
    })
  }

   async submitForm() {
     const formData = new FormData()
     formData.append("id", this.state.id)
     formData.append("name", this.state.name)
     formData.append("notes", this.state.notes)
     formData.append("distributor_id", this.state.distributor.id)


     if (typeof this.state.image === "object") {
       this.state.image === null ?
         formData.append('image', "null")
         : formData.append('image', this.state.image, 'image')
     }

     if(!this.state.disabled) {
       await customerApiHelper.createOrUpdate(
         formData
       )
       await this.props.loadCustomers()
       if ('setCustomer' in this.props) { await this.props.setCustomer(this.state.name)}
       await this.props.toggleForm()

     }
   }

   updateFile(file) {
     this.setState({
       image: file
     })
   }

   removeImage() {
     this.setState({
       image: null
     })
   }

   render() {
      if(!this.state.loaded) {
        return null
      }
      return (
        <FormContainer
          title="Customer"
          onClose={this.props.toggleForm}
        >
          <TextInput
            label="Customer Name"
            placeholder="Name of customer"
            value={this.state.name}
            attribute={"name"}
            handleChange={this.updateValue}
          />
          <DropdownSelect
            attribute="distributor"
            options={this.props.distributors}
            optionName={"name"}
            label="Dealer"
            placeholder="Select option"
            selectOption={this.updateValue}
            selected={this.state.distributor != null ? this.state.distributor.name : ''}
          />
          <RichTextArea
            label={"Internal Notes"}
            attribute="notes"
            updateValue={this.updateValue}
            value={this.state.notes}
          />
          <FileDropzone
            label="Customer Image"
            prompt="Click or drag to upload customer's logo"
            updateFile={this.updateFile}
            removeImage={this.removeImage}
            file={this.state.image}
          />
          <div style={{display: 'flex', justifyContent: 'flex-end'}}>
            <AddRecordButton
              text={this.state.id === '' ? "Create" : "Update"}
              disabled={this.state.disabled}
              onClick={this.submitForm}
              updateSku={this.updateSku}
            />
          </div>
        </FormContainer>
      );
    }
}


export default CustomerForm
