import React from "react"
import PropTypes from "prop-types"

//TYPE
//date, number

const TextInput = props => (
  <div style={{marginTop: 20, marginBottom: 20}}>
  <h6 className="inputLabel">{props.label}</h6>
  <input
    style={props.style}
    placeholder={props.placeholder}
    className="inputField"
    type={props.type}
    step="any"
    disabled={props.disabled}
    autoFocus={props.autofocus ? "autofocus" : ""}
    value={props.value === null ? "" : props.value}
    onChange={(event) => props.handleChange(props.attribute, event.target.value)}
  ></input>
  </div>
)

export default TextInput;
