import React from "react"
import PropTypes from "prop-types"

import FormContainer from '../forms/FormContainer'
import ProductFormSection from './form_sections/ProductFormSection'
//import ConfigurationSection from './form_sections/ConfigurationSection'
import AddRecordButton from '../forms/AddRecordButton'
import AddAnotherButton from '../forms/AddAnotherButton'
import TextInput from '../forms/TextInput'
import RichTextArea from '../forms/RichTextArea'
import DropdownSelect from '../forms/DropdownSelect'
import productApiHelper from '../utils/productApiHelper'
import FileDropzone from '../forms/FileDropzone'
import MultiSelectInput from '../forms/MultiSelectInput'
import MutliProductImage from './form_sections/MutliProductImage'

class BrandForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
        id: '',
        name: '',
        description: '',
        brand_image_url: null,
        buisness_unit_id: '',
        brand_configurations: [],
        business_unit: {
          id: '',
          name: ''
        },
        category: {
          id: '',
          name: ''
        },
        openConfigurations: [],
        products: [],
        suggested_brands: [],
        newProduct: {
          name: '',
          id: '',
          diameter: '',
          flutes: '',
          length: '',
          head_length: '',
          quantity: '',
          grit: undefined,
          shape: undefined,
          shank: undefined,
          product_image_url: null,
          configuration_type_id: null,
          skus: [],
        },
        allCategories: [],
        allBusinessUnits: [],
        allShapes: [],
        allGrits: [],
        allShanks: [],
        disabled: true,
        loaded: false,
        productsLoaded: false,
        bulkProductImageFormOpen: false,
    }

    this.updateValue = this.updateValue.bind(this)
    this.updateSku = this.updateSku.bind(this)
    this.updateProduct = this.updateProduct.bind(this)
    this.canSubmit = this.canSubmit.bind(this)
    this.submitForm = this.submitForm.bind(this)
    this.renderProductFormSections = this.renderProductFormSections.bind(this)
    this.addProduct = this.addProduct.bind(this)
    this.updateFile = this.updateFile.bind(this)
    this.removeImage = this.removeImage.bind(this)
    this.updateRelatedBrands = this.updateRelatedBrands.bind(this)
    this.createBrand = this.createBrand.bind(this)
    this.updateOpenConfigurations = this.updateOpenConfigurations.bind(this)
    this.updateProductImages = this.updateProductImages.bind(this)
    this.loadProducts = this.loadProducts.bind(this)
  }

  async componentDidMount() {
    if(this.props.individualFormRecordId != null) {
      let response = await productApiHelper.loadBrand(this.props.individualFormRecordId)
      const brand = JSON.parse(response.data.brand)
      this.setState({
        id: brand.id,
        name: brand.name,
        description: brand.description,
        brand_image_url: brand.brand_image_url,
        category: brand.category != undefined ?
          brand.category :
          {
            id: '',
            name: ''
          },
        business_unit: brand.business_unit,
        suggested_brands: brand.suggested_brands,
        brand_configurations: brand.brand_configurations
      })
    }

    let filters = await productApiHelper.loadBrandFormFilters()
    this.setState({
      allCategories: JSON.parse(filters.data.allCategories),
      allBusinessUnits: JSON.parse(filters.data.allBusinessUnits),
      allShapes: JSON.parse(filters.data.allShapes),
      allGrits: JSON.parse(filters.data.allGrits),
      allShanks: JSON.parse(filters.data.allShanks),
    })
    const skus = [...this.state.newProduct.skus]
    const distributors = this.props.distributors.map((distributor, i) => {
      if(distributor.primary) {
        this.setState(prevState => ({
          newProduct: {
            ...this.state.newProduct,
            skus: [...prevState.newProduct.skus, {distributor: distributor, sku: '', product_id: ''}]
          }
        }))
      }
    })

    let response = await productApiHelper.loadBrand(this.props.individualFormRecordId)
    Promise.all(distributors).then(() => {
      if(this.state.products.length === 0) {
        this.addProduct()
      }
      this.setState({
        loaded: true
      })
    })

    if(this.props.individualFormRecordId != null) {
        this.loadProducts()
    }
  }

  async loadProducts() {
    let response = await productApiHelper.loadBrandProducts(this.props.individualFormRecordId)
    const products = JSON.parse(response.data.products)

    this.setState({
      products: products
    })
  }

  updateValue(attribute, value) {
    this.setState({
      [attribute]: value,
    }, () => this.canSubmit())
  }

  async createBrand() {
    const formData = new FormData()
    formData.append("id", this.state.id)
    formData.append("name", this.state.name)
    formData.append("description", this.state.description)
    formData.append("business_unit_id", this.state.business_unit.id)
    this.state.category.id != '' && formData.append("category_id", this.state.category.id)

    if (typeof this.state.brand_image_url === "object") {
      this.state.brand_image_url === null ?
        formData.append('brand_image', "null")
        : formData.append('brand_image', this.state.brand_image_url, 'image')
    }

    if(!this.state.disabled) {
      let response = await productApiHelper.createOrUpdate(formData)
      const brand = JSON.parse(JSON.parse(response.data).brand)

      this.setState({
        id: brand.id,
        name: brand.name,
        description: brand.description,
        brand_image_url: brand.brand_image_url,
        business_unit: brand.business_unit,
      })
    }
  }

  canSubmit() {
    this.setState({
      disabled: (this.state.name.length < 2 || this.state.business_unit.id === '' || (this.state.business_unit.name != "Rotary" && this.state.category.id === ''))
    })
  }

  updateProduct(index, attribute, value) {
    let products = [...this.state.products]
    let product = {...products[index]}
    product[attribute] = value
    products[index] = product

    this.setState({
      products,
    }, () => this.canSubmit())
  }

  updateSku(product_index, distributor_index, sku_value) {

    let products = [...this.state.products]
    let product = {...products[product_index]}

    let skus = [...product.skus]
    let sku = {...skus[distributor_index]}
    sku.sku = sku_value

    skus[distributor_index] = sku
    products[product_index] = {...product, skus}

    this.setState({
      products
    })
    this.canSubmit()
  }

   async submitForm() {
     const formData = new FormData()
     formData.append("id", this.state.id)
     formData.append("name", this.state.name)
     formData.append("description", this.state.description)
     formData.append("business_unit_id", this.state.business_unit.id)
     this.state.category.id != '' && formData.append("category_id", this.state.category.id)
     formData.append('products', JSON.stringify(this.state.products))
     formData.append('related_brands', JSON.stringify(this.state.suggested_brands))

     for ( const key in this.state.products) {
       if(typeof this.state.products[key].product_image_url === "object") {
         this.state.products[key].product_image_url === null ?
           formData.append(`image_${key}`, "null")
           : formData.append(`image_${key}`, this.state.products[key].product_image_url, `product_image_${[key]}`)
        }
     }

     if (typeof this.state.brand_image_url === "object") {
       this.state.brand_image_url === null ?
         formData.append('brand_image', "null")
         : formData.append('brand_image', this.state.brand_image_url, 'image')
     }

     if(!this.state.disabled) {
       await productApiHelper.createOrUpdate(
         formData
       )
       await this.props.loadProducts()
       await this.props.toggleForm()
     }
   }

   updateFile(file) {
     this.setState({
       brand_image_url: file
     })
     this.canSubmit()
   }

   removeImage() {
     this.setState({
       brand_image_url: null
     })
     this.canSubmit()
   }

   addProduct() {
     let products = [...this.state.products]
     this.setState({
       products: [...products, this.state.newProduct]
     })
   }

   updateRelatedBrands(attribute, value) {
      this.setState({
        suggested_brands: value
      }, () => this.canSubmit())
   }

   renderProductFormSections() {
     return this.state.products.map((product, index) => {
       return (<ProductFormSection
         key={index}
         index={index}
         brandId={this.state.id}
         prevProduct={index != 0 ? this.state.products[index-1] : []}
         product={product}
         business_unit={this.state.business_unit}
         distributors={this.props.distributors}
         updateProduct={this.updateProduct}
         updateState={this.props.updateState}
         newProduct={this.state.newProduct}
         updateSku={this.updateSku}
         configuration_types={this.props.configuration_types}
         shapes={this.state.allShapes}
         grits={this.state.allGrits}
         shanks={this.state.allShanks}
         brand_configurations={this.state.brand_configurations}
         updateOpenConfigurations={this.updateOpenConfigurations}
         openConfigurations={this.state.openConfigurations}
       />)
     })
   }

   updateOpenConfigurations(configurationArray) {
     this.setState({
       openConfigurations: configurationArray
     })
   }

   updateProductImages() {
     this.loadProducts()
     this.setState({
       bulkProductImageFormOpen: false
     })

   }

   render() {
      if(!this.state.loaded) {
        return null
      }
      if(this.state.bulkProductImageFormOpen) {
        return (
          <MutliProductImage
            grits={this.state.allGrits}
            shapes={this.state.allShapes}
            products={this.state.products}
            updateProductImages={this.updateProductImages}
            onClose={() => this.setState({
              bulkProductImageFormOpen: false
            })} />)
      }
      return (
        <FormContainer
          title="Brand"
          onClose={this.props.toggleForm}
        >
          <div style={{
              backgroundColor: 'white',
              borderRadius: 30,
              zIndex: 1
            }}>
            <div style={{
                position: 'fixed',
                backgroundColor: 'white',
                borderRadius: 30,
                left: '62%',
                bottom: 40,
                zIndex: 1
              }}>
              <AddRecordButton
                text={this.state.id === '' ? "Next: Add Products" : "Update"}
                disabled={this.state.disabled}
                onClick={this.state.id === '' ? this.createBrand : this.submitForm}
                updateSku={this.updateSku}
              />
            </div>
          </div>
          <TextInput
            label="Brand Name"
            placeholder="Name of brand"
            value={this.state.name}
            attribute={"name"}
            handleChange={this.updateValue}
          />
          <DropdownSelect
            attribute="business_unit"
            options={this.state.allBusinessUnits}
            optionName={"name"}
            label="Business Unit"
            placeholder="Select option"
            selectOption={this.updateValue}
            selected={this.state.business_unit.name}
          />
          <DropdownSelect
            attribute="category"
            options={this.state.allCategories}
            optionName={"name"}
            label="Category"
            placeholder="Select option"
            selectOption={this.updateValue}
            selected={this.state.category.name}
          />
          <MultiSelectInput
            options={this.props.brands}
            searchBy="name"
            labelField="name"
            valueField="id"
            attribute={'suggested_brands'}
            updateValue={this.updateRelatedBrands}
            selected={this.state.suggested_brands}
            label="Related Brands"
            create={false}
            multi={true}
          />
          <RichTextArea
            label={"Brand Description"}
            attribute="description"
            updateValue={this.updateValue}
            value={this.state.description}
          />
          <FileDropzone
            label="Brand Image"
            prompt="Click or drag to upload your brand image"
            updateFile={this.updateFile}
            removeImage={this.removeImage}
            file={this.state.brand_image_url}
          />
          {this.state.id != "" &&
            <div>
              {this.state.business_unit.name === "Rotary" &&
                <div style={{
                  marginBottom: 15,
                }}>
                <AddAnotherButton
                  text={"add product image to many products"}
                  onClick={() => this.setState({bulkProductImageFormOpen: !this.state.bulkProductImageFormOpen})}
                />
                </div>}
              <h2>Add Products</h2>
              {this.renderProductFormSections()}
              <AddAnotherButton
                text={"add another product"}
                onClick={this.addProduct}
              />
            </div>
          }
        </FormContainer>
      );
    }
}


export default BrandForm
