import axios from 'axios'

let token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
axios.defaults.headers.common['X-CSRF-Token'] = token
axios.defaults.headers.common['Accept'] = 'application/json'

const axiosHelper = {
  loadDashboard: () => {
    return axios.get('/load_dashboard')
    .then(response => {
        return response
    })
    .catch(error => console.log(error))
  }
}

export default axiosHelper
