import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPen,
  faEllipsisH,
  faTrash,
  faPlus
} from '@fortawesome/free-solid-svg-icons'

function EditButtons(props) {
  return (
    <div className="table_action_column">
      {props.user.role_id > 2 && <FontAwesomeIcon
        icon={faPen}
        onClick={() => props.editRecord(props.product)}
        style={{
          color: '#005AC3',
          border: '1px solid #EEEEEE',
          marginLeft: 5,
          padding: 8,
          borderRadius: 4,
          cursor: 'pointer'
        }}
        />}
        {props.user.role_id > 2 && <FontAwesomeIcon
          onClick={() => props.toggleMenu(props.product)}
          icon={faEllipsisH}
          style={{
            color: '#005AC3',
            marginLeft: 5,
            padding: 8,
            borderRadius: 4,
            cursor: 'pointer'
          }}
          />}
        {props.user.role_id > 2 && props.elipsisMenu(props.menuOpen, props.product)}
    </div>
  )
}

export default EditButtons
