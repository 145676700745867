import React from "react"
import PropTypes from "prop-types"

import AddRecordButton from '../forms/AddRecordButton'
import CustomerForm from '../customers/CustomerForm'
import CustomersTable from '../customers/CustomersTable'
import customerApiHelper from '../utils/customerApiHelper'
import DeleteCustomerForm from './DeleteCustomerForm'
import LoadingSection from '../utils/LoadingSection'

class CustomerContainer extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      formOpen: false,
      customers: [],
      formRecord: [],
      distributors: [],
      loaded: false,
      deleteCustomer: [],
      deleteCustomerFormOpen: false

    }

    this.loadCustomers = this.loadCustomers.bind(this)
    this.toggleForm = this.toggleForm.bind(this)
    this.editRecord = this.editRecord.bind(this)
    this.toggleArchiveCustomer = this.toggleArchiveCustomer.bind(this)
    this.toggleDeleteCustomer = this.toggleDeleteCustomer.bind(this)
  }

  componentDidMount() {
    this.loadCustomers()
  }

  async loadCustomers() {
    let response = await customerApiHelper.loadCustomers()
    this.setState({
      customers: JSON.parse(response.data.customers),
      distributors: JSON.parse(response.data.distributors),
      loaded: true,
      deleteCustomerFormOpen: false
    })
  }

  toggleForm() {
    this.setState({
      formOpen: !this.state.formOpen,
      formRecord: []
    })
  }

  toggleDeleteCustomer(customer) {
    this.setState({
      deleteCustomer: customer,
      deleteCustomerFormOpen: !this.state.deleteCustomerFormOpen,
    })
  }

  async editRecord(record) {
    let response = await customerApiHelper.loadCustomer(record.id)
    const customer = JSON.parse(response.data.customer)

    this.setState({
      formOpen: !this.state.formOpen,
      formRecord: customer
    })
  }

  async toggleArchiveCustomer(customer) {
    let data = {
      id: customer.id,
      archived: !customer.archived
    }

    let response = await customerApiHelper.quickCustomerUpdate(data)
    await this.loadCustomers()
  }

 render() {
    if(!this.state.loaded) {
      return (<LoadingSection style={{marginTop: '20%'}}/>)
    }
    return (
      <div>
        {this.state.deleteCustomerFormOpen &&
          <DeleteCustomerForm
            customer={this.state.deleteCustomer}
            toggleDeleteCustomer={this.toggleDeleteCustomer}
            toggleArchiveCustomer={this.toggleArchiveCustomer}
            loadCustomers={this.loadCustomers}
          />
        }
        {this.state.formOpen &&
          <CustomerForm
            loadCustomers={this.loadCustomers}
            toggleForm={this.toggleForm}
            formRecord={this.state.formRecord}
            distributors={this.state.distributors}
          />}
        <div className="pageHeader">
          <h2>Customers</h2>
          <AddRecordButton
            icon={true}
            text="Add Customer"
            onClick={this.toggleForm}
          />
        </div>
        {this.state.customers.length > 0 &&
          <CustomersTable
            customers={this.state.customers}
            editRecord={this.editRecord}
            toggleArchiveCustomer={this.toggleArchiveCustomer}
            toggleDeleteCustomer={this.toggleDeleteCustomer}
          />
        }
      </div>
    );
  }
}




export default CustomerContainer
