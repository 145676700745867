import React from "react"
import PropTypes from "prop-types"
import FormContainer from '../forms/FormContainer'
import AddRecordButton from '../forms/AddRecordButton'
import PhoneInputField from '../forms/PhoneInputField'
import TextInput from '../forms/TextInput'
import userApiHelper from '../utils/userApiHelper'
import FileDropzone from '../forms/FileDropzone'
import DropdownSelect from '../forms/DropdownSelect'

class UserForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
        id: '',
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        role: {
          id: 1,
          name: "User"
        }
    }

    this.updateValue = this.updateValue.bind(this)
    this.submitForm = this.submitForm.bind(this)
    this.updateFile = this.updateFile.bind(this)
    this.removeImage = this.removeImage.bind(this)
    this.isDisabled = this.isDisabled.bind(this)
  }

  componentDidMount() {
    if("id" in this.props.formRecord) {
      const user = this.props.formRecord
      this.setState({
        id: this.props.formRecord.id,
        first_name: user.first_name != null ? user.first_name : '' ,
        last_name: user.last_name != null ? user.last_name : '' ,
        email: user.email != null ? user.email : '' ,
        phone: user.phone != null ? user.phone : '',
        role: user.role
      })
    }
  }

  isDisabled() {
    this.setState({
      disabled: this.state.email.length < 3
    })
  }

  updateValue(attribute, value) {
    this.setState({
      [attribute]: value,
    }, () => this.isDisabled())
  }

  updateFile(file) {
    this.setState({
      logo_url: file
    }, () => this.isDisabled())
  }

  removeImage() {
    this.setState({
      logo_url: null
    }, () => this.isDisabled())
  }

   async submitForm() {
     let data = {
       id: this.state.id,
       user_params: {
         first_name: this.state.first_name,
         last_name: this.state.last_name,
         email: this.state.email,
         phone: this.state.phone,
         role_id: this.state.role.id
       }
     }
     if(!this.state.disabled) {
       await userApiHelper.adminUpdateUser(data)
       await this.props.loadUsers()
       await this.props.toggleForm()
     }
   }

   render() {
      return (
        <FormContainer
          title="User"
          onClose={this.props.toggleForm}
        >
          <TextInput
            label="First name"
            placeholder="First name"
            value={this.state.first_name}
            attribute={"first_name"}
            handleChange={this.updateValue}
          />
          <TextInput
            label="Last name"
            placeholder="Last name"
            value={this.state.last_name}
            attribute={"last_name"}
            handleChange={this.updateValue}
          />
          <DropdownSelect
            attribute="role"
            options={this.props.roles}
            optionName={"name"}
            label="Role"
            placeholder="Select option"
            selectOption={this.updateValue}
            selected={this.state.role.name}
          />
          <TextInput
            label="Email"
            placeholder="Email"
            value={this.state.email}
            attribute={"email"}
            handleChange={this.updateValue}
          />
          <PhoneInputField
            label="Phone"
            placeholder="Phone"
            value={this.state.phone}
            attribute={"phone"}
            handleChange={this.updateValue}
          />
          <div style={{display: 'flex', justifyContent: 'flex-end'}}>
            <AddRecordButton
              text={this.state.id === '' ? "Create" : "Update"}
              disabled={this.state.disabled}
              onClick={this.submitForm}
            />
          </div>
        </FormContainer>
      );
    }
}


export default UserForm
