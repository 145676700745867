import React from "react";
import PropTypes from "prop-types"
import catalogApiHelper from '../../utils/catalogApiHelper'
import CatalogContentOrganizer from './CatalogContentOrganizer'

class OrganizeCatalog extends React.Component {

  constructor(props) {
    super(props)

    this.updateContents = this.updateContents.bind(this)
  }

  updateContents(business_units, sushiMenuIncluded, catalogPagesIncluded) {
    this.props.updateBusinessUnitOrder(business_units, sushiMenuIncluded, catalogPagesIncluded)
    this.props.changeTab(3)
  }

  render() {
    return(
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          margin: '3%',
        }}>
        <CatalogContentOrganizer
          business_units={this.props.business_units}
          updateContents={this.updateContents}
          sushiMenu={this.props.sushiMenuIncluded}
          catalogPages={this.props.catalogPages}
          catalogPagesIncluded={this.props.catalogPagesIncluded}
        />
      </div>
    )
  }
}

export default OrganizeCatalog
