import React from "react"
import PropTypes from "prop-types"

import AddRecordButton from '../forms/AddRecordButton'
import DistributorForm from '../distributors/DistributorForm'
import DistributorsTable from '../distributors/DistributorsTable'
import distributorApiHelper from '../utils/distributorApiHelper'
import LoadingSection from '../utils/LoadingSection'

class DistributorsContainer extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      formOpen: false,
      distributors: [],
      formRecord: [],
      loaded: false
    }

    this.loadDistributors = this.loadDistributors.bind(this)
    this.toggleForm = this.toggleForm.bind(this)
    this.editRecord = this.editRecord.bind(this)
    this.toggleArchiveDistributor = this.toggleArchiveDistributor.bind(this)
  }

  componentDidMount() {
    this.loadDistributors()
  }

  async loadDistributors() {
    let response = await distributorApiHelper.loadDistributors()
    this.setState({
      distributors: JSON.parse(response.data.distributors),
      loaded: true
    })
  }

  async toggleArchiveDistributor(distributor) {
    let data = {
      id: distributor.id,
      archived: !distributor.archived
    }

    let response = await distributorApiHelper.quickDistributorUpdate(data)
    await this.loadDistributors()
  }

  toggleForm() {
    this.setState({
      formOpen: !this.state.formOpen,
      formRecord: []
    })
  }

  editRecord(record) {
    this.setState({
      formOpen: !this.state.formOpen,
      formRecord: record
    })
  }

 render() {
   if(!this.state.loaded) {
     return (<LoadingSection style={{marginTop: '20%'}}/>)
   }
    return (
      <div>
        {this.state.formOpen &&
          <DistributorForm
            loadDistributors={this.loadDistributors}
            toggleForm={this.toggleForm}
            formRecord={this.state.formRecord}
          />}
        <div className="pageHeader">
          <h2>Dealers</h2>
          <AddRecordButton
            icon={true}
            text="Add Dealer"
            onClick={this.toggleForm}
          />
        </div>
        <DistributorsTable
          distributors={this.state.distributors}
          editRecord={this.editRecord}
          toggleArchiveDistributor={this.toggleArchiveDistributor}
        />

      </div>
    );
  }
}


export default DistributorsContainer
