import React from "react"
import PropTypes from "prop-types"
import FormContainer from '../forms/FormContainer'
import FileDropzone from '../forms/FileDropzone'
import { OutTable, ExcelRenderer } from 'react-excel-renderer';
import AddRecordButton from '../forms/AddRecordButton'
import productApiHelper from '../utils/productApiHelper'

class BulkUpload extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      file: '',
      rows: [],
      cols: [],
      formError: '',
      submittingForm: false,
      typeOfUploadIsProduct: true
    }

    this.updateFile = this.updateFile.bind(this)
    this.submitData = this.submitData.bind(this)
  }

  updateFile(file) {
    ExcelRenderer(file, (err, resp) => {
      if(err){
        console.log(err);
      }
      else{

        const businessUnit = resp.rows[1][1]
        const filteredRows = resp.rows.filter((el) => {
          encodeURIComponent(el)
          if(this.state.typeOfUploadIsProduct && businessUnit != "Rotary") {
            // if(el[6] && el[6] != "Brand Image") {
            //   const image_url = el[6]
            //   el[6] = <img src={image_url}  style={{width: 50, height: 50}}/>
            // } else if(el[7] && el[7] != "Configuration Image") {
            //   const image_url = el[7]
            //   el[7] = <img src={image_url} style={{width: 50, height: 50}} />
            // } else if(el[8] && el[8] != "Product Image") {
            //   const image_url = el[8]
            //   el[8] = <img src={image_url} style={{width: 50, height: 50}} />
            // }
          }
          return el.length != 0;
        });

        this.setState({
          file: file,
          cols: resp.cols,
          rows: filteredRows
        });
      }
    });
  }

  async submitData() {
    this.setState({
      formError: '',
      submittingForm: true
    })
    const data = JSON.stringify(this.state.rows, function (key, value) {return (value === undefined) ? "" : value})

    try {
      if(this.state.typeOfUploadIsProduct) {
        await productApiHelper.bulkUpload(data)
          this.props.loadProducts()
          this.props.closeBulkUpload()
      } else {
        console.log('bulk upload')
        await productApiHelper.bulkSkuUpload(data)
          this.props.loadProducts()
          this.props.closeBulkUpload()
      }


    } catch (error) {
      this.setState({
        submittingForm: false,
        formError: 'Something went wrong. Please recheck the data, and confirm everything is ready to be uploaded.'
      })
    }
  }

  render() {
    const title = this.state.typeOfUploadIsProduct ? 'Import New Products' : 'Add Dealer Numbers'
    return(
      <FormContainer onClose={this.props.closeBulkUpload}>
        <div style={{
          margin: 'auto',
          display: 'flex',
          flexDirection: 'column',
          }}>
          <h1
            style={{
              fontSize: 28,
              textAlign: 'center',
              fontFamily: 'GothamBold',
              marginBottom: 30,
            }}>{title}</h1>
          <p
            style={{
              textAlign: 'center',
              color: 'rgb(0, 90, 195)',
              cursor: 'pointer'
            }}
            onClick={() => {
            this.setState({
              typeOfUploadIsProduct: !this.state.typeOfUploadIsProduct
            })
          }}>Change upload type</p>

          {this.state.formError != '' && <h5 className="errorAlert">{this.state.formError}</h5>}
          {this.state.file === '' && <div style={{margin: 'auto'}}><FileDropzone
            label=""
            prompt="Click or drag to upload your Excel file."
            updateFile={this.updateFile}
            removeImage={this.removeImage}
            file={null}
          /></div>}
          {this.state.file != '' &&
            <div style={{display: 'flex', flexDirection: 'column'}}>
              <div style={{marginBottom: 20, alignSelf: 'flex-end'}}>
                <AddRecordButton
                  onClick={this.submitData}
                  text={this.state.typeOfUploadIsProduct ? "Upload Products" : "Add Dealer Numbers"}
                  disabled={this.state.submittingForm}
                />
              </div>
              <div className="BulkUploadTableContainer">
                <OutTable
                  data={this.state.rows}
                  columns={this.state.cols}
                  tableClassName="BulkUploadTable"
                  tableHeaderRowClass="heading"
                  withoutRowNum={true}
                  withZeroColumn={true}
                  blankRows={false}
                  />
              </div>
            </div>
          }
        </div>
      </FormContainer>
    )
  }
}

export default BulkUpload
