import React from "react"
import PropTypes from "prop-types"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faEllipsisH, faTimes } from '@fortawesome/free-solid-svg-icons'

const TopNav = props => (
  <div style={{
    padding: 20,
    borderBottom: "2px solid rgb(245, 245, 245)",
    display: 'flex',
    justifyContent: 'space-between'}}>
    <div>
      <FontAwesomeIcon
        icon={faArrowLeft}
        style={{
          color: '#D79B81',
          cursor: 'pointer'
        }}
        onClick={() => props.backTab()}
        />
    </div>
    <div>
      <h5 className="bold">View Published Catalog</h5>
    </div>
    <div>

      <FontAwesomeIcon
        icon={faTimes}
        style={{
          color: '#D79B81',
          cursor: 'pointer'
        }}
        onClick={() => props.backTab()}
        />
    </div>
  </div>
)

// <FontAwesomeIcon
//   icon={faEllipsisH}
//   style={{
//     color: '#D79B81',
//     marginRight: 10,
//     cursor: 'pointer'
//   }}
//   />

export default TopNav
