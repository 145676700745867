import React from "react"
import PropTypes from "prop-types"
import FormContainer from '../forms/FormContainer'
import customerApiHelper from '../utils/customerApiHelper'
import AddRecordButton from '../forms/AddRecordButton'
import SecondaryButton from '../forms/SecondaryButton'

class DeleteCustomerForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loaded: false,
      catalog_customers: []
    }

    this.delete = this.delete.bind(this)
    this.archive = this.archive.bind(this)
  }

  async delete() {
    let response = await customerApiHelper.deleteCustomer({id: this.props.customer.id})
    this.props.loadCustomers()
    this.props.toggleDeleteCustomer([])
  }

  async archive() {
    this.props.toggleArchiveCustomer(this.props.customer)
  }

  render() {
    return(
      <FormContainer
        onClose={() => this.props.toggleDeleteCustomer([])}>
          <div className="modalStandard">
            <h2>Are you sure you want to delete this customer?</h2>
            <h3>Deleting this customer will remove it from the system, and remove all catalogs and proposals for this customer. This action can not be undone.</h3>
            <p className="bold">Customer</p>
            <p>{this.props.customer.name}</p>
            <div className="two_button_section">
              {!this.props.customer.archived && <SecondaryButton
                text="Archive Instead"
                onClick={this.archive}
                alert={false}
              />}
              <AddRecordButton
                text="Delete Forever"
                onClick={this.delete}
                alert={true}
              />
            </div>
          </div>
      </FormContainer>
    )
  }
}

export default DeleteCustomerForm
