import axios from 'axios'

let token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
axios.defaults.headers.common['X-CSRF-Token'] = token
axios.defaults.headers.common['Accept'] = 'application/json'

const userApiHelper = {
  loadCurrentUser: () => {
    return axios.get('/get_current_user')
    .then(response => {
        return response
    })
    .catch(error => console.log(error))
  },
  updateUser: (data) => {
    const options = {
      method: 'post',
      url: '/update_user',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: data,
      transformResponse: [(data) => {
        // transform the response
        return data;
      }]
    };
    return axios(options)
    .then(response => {
        return response
    })
    .catch(error => console.log(error))
  },
  loadUsers: () => {
      return axios.get('/get_all_users')
      .then(response => {
          return response
      })
      .catch(error => console.log(error))
  },
  adminUpdateUser: (data) => {
    const options = {
      method: 'post',
      url: '/admin_update_user',
      data: data,
    };
    return axios(options)
    .then(response => {
        return response
    })
    .catch(error => console.log(error))
  },
  approveUser: (data) => {
    const options = {
      method: 'post',
      url: '/approve_user',
      data: data,
    };
    return axios(options)
    .then(response => {
        return response
    })
    .catch(error => console.log(error))
  },
  bulkUploadUsers: (data) => {
    const options = {
      method: 'post',
      url: '/bulk_upload_users',
      data: data,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      validateStatus: function (status) {
        return status >= 200 && status < 400
      }
    };
    return axios(options)
    .then(response => {
        return response
    })
    .catch(error => {
      console.log(error)
      return error
    })
  },

}

export default userApiHelper;
