import React from "react"
import PropTypes from "prop-types"
import FormContainer from '../forms/FormContainer'
import catalogApiHelper from '../utils/catalogApiHelper'
import AddRecordButton from '../forms/AddRecordButton'
import SecondaryButton from '../forms/SecondaryButton'

class DeleteCatalogForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loaded: false,
      catalog_catalogs: []
    }

    this.delete = this.delete.bind(this)
    this.archive = this.archive.bind(this)
  }

  async delete() {
    let response = await catalogApiHelper.deleteCatalog({id: this.props.catalog.id})
    this.props.loadCatalogs()
    this.props.toggleDeleteCatalog([])
  }

  async archive() {
    this.props.toggleArchiveCatalog(this.props.catalog)
  }

  render() {
    return(
      <FormContainer
        onClose={() => this.props.toggleDeleteCatalog([])}>
          <div className="modalStandard">
            <h2>Are you sure you want to delete this catalog?</h2>
            <h3>Deleting this catalog will remove it from the system. This action can not be undone.</h3>
            <p className="bold">Catalog</p>
            <p>{this.props.catalog.name}</p>
            <div className="two_button_section">
              {!this.props.catalog.archived && <SecondaryButton
                text="Archive Instead"
                onClick={this.archive}
                alert={false}
              />}
              <AddRecordButton
                text="Delete Forever"
                onClick={this.delete}
                alert={true}
              />
            </div>
          </div>
      </FormContainer>
    )
  }
}

export default DeleteCatalogForm
