import React from "react"
import PropTypes from "prop-types"
import TopNav from './create_sections/TopNav'
import ProgressIndicator from './create_sections/ProgressIndicator'
import CatalogForm from './create_sections/CatalogForm'
import customerApiHelper from '../utils/customerApiHelper'
import catalogApiHelper from '../utils/catalogApiHelper'
import AddProductsSection from './create_sections/AddProductsSection'
import CatalogPreview from './create_sections/CatalogPreview'
import CatalogContentOrganizer from './create_sections/CatalogContentOrganizer'
import PublishCatalog from './create_sections/PublishCatalog'
import OrganizeCatalog from './create_sections/OrganizeCatalog'
import FormContainer from '../forms/FormContainer'

class CreateCatalog extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      tab: 0,
      id: '',
      catalog: {},
      catalogPages: [],
      business_units: [],
      user: {},
      customer: {},
      brands: [],
      allCustomers: [],
      allDistributors: [],
      date: '',
      catalog_name: '',
      selected_products: [],
      available_products: [],
      loaded: false,
      catalog_loaded: false,
      sushiMenuIncluded: false,
      catalogPagesIncluded: [],
    }

    this.loadCustomers = this.loadCustomers.bind(this)
    this.createOrUpdate = this.createOrUpdate.bind(this)
    this.changeTab = this.changeTab.bind(this)
    this.getCatalog = this.getCatalog.bind(this)
    this.updateBusinessUnitOrder = this.updateBusinessUnitOrder.bind(this)
  }

  async loadCustomers() {
    let response = await customerApiHelper.loadCustomers()
    this.setState({
      allCustomers: JSON.parse(response.data.customers),
      allDistributors: JSON.parse(response.data.distributors),
      loaded: true
    })
  }

  componentDidMount() {
    //get available customers and dealers
    this.loadCustomers()
  }

  createOrUpdate(data) {
    catalogApiHelper.createOrUpdate(data)
    .then((response) => {
      this.setState({
        catalog: JSON.parse(response.data.catalog),
        tab: 1,
        catalog_loaded: true
      })
    })
  }

  changeTab(tab) {
    if(tab === 2) {
      this.setState({
        catalog_loaded: false
      }, () => this.getCatalog())
    }
    this.setState({
      tab: tab
    })
  }



  async getCatalog() {
    let data = {
      catalog_id: this.state.catalog.id
    }

    let response = await catalogApiHelper.loadOranizerPage(data)

    this.setState({
      catalogPages: JSON.parse(response.data.catalog_pages),
      business_units: JSON.parse(response.data.business_units),
      loaded: true,
      catalog_loaded: true,
    })
  }

  updateBusinessUnitOrder(business_units, sushiMenuIncluded, catalogPagesIncluded) {
    this.setState({
      catalog_loaded: false,
      business_units: business_units,
      sushiMenuIncluded: sushiMenuIncluded,
      catalogPagesIncluded: catalogPagesIncluded,
    }, () => this.setState({
      catalog_loaded: true
    }))
  }

 render() {
    if(!this.state.loaded) {
      return null
    }
    return (
      <div style={{
        zIndex: 100,
        backgroundColor: 'white',
        position: 'absolute',
        top: 0,
        width: '100vw',
        height: '100vh',
      }}>
        <TopNav
          backTab={this.props.backTab}
        />
        <div className="create_catalog_container">
          <div>
            <ProgressIndicator
              tab={this.state.tab}
              changeTab={this.changeTab}
            />
          </div>

          <div className="create_catalog_contents">
              {this.state.tab === 0 &&
                <CatalogForm
                  formRecord={this.props.formRecord}
                  changeTab={this.changeTab}
                  allCustomers={this.state.allCustomers}
                  allDistributors={this.state.allDistributors}
                  loadCustomers={this.loadCustomers}
                  createOrUpdate={this.createOrUpdate}
                />
              }
              {(this.state.tab === 1 && this.state.catalog_loaded) &&
                <AddProductsSection
                  catalog={this.state.catalog}
                  changeTab={this.changeTab}
                />
              }
              {(this.state.tab === 2 && this.state.catalog_loaded) &&
                <OrganizeCatalog
                  business_units={this.state.business_units}
                  changeTab={this.changeTab}
                  updateBusinessUnitOrder={this.updateBusinessUnitOrder}
                  sushiMenuIncluded={this.state.sushiMenuIncluded}
                  catalogPages={this.state.catalogPages}
                  catalogPagesIncluded={this.state.catalogPagesIncluded}
                  goToCatalogView={this.props.goToCatalogView}
                />
              }
              {(this.state.tab === 3 && this.state.catalog_loaded) &&
                <PublishCatalog
                  catalog={this.state.catalog}
                  business_units={this.state.business_units}
                  changeTab={this.changeTab}
                  updateBusinessUnitOrder={this.updateBusinessUnitOrder}
                  sushiMenuIncluded={this.state.sushiMenuIncluded}
                  catalogPagesIncluded={this.state.catalogPagesIncluded}
                  goToCatalogView={this.props.goToCatalogView}
                  viewCatalog={this.props.viewCatalog}
                />
              }
          </div>
        </div>
      </div>
    );
  }
}

//
// {(this.state.tab === 2 && this.state.catalog_loaded) &&
//   <CatalogPreview
//     key={this.state.business_units}
//     catalog={this.state.catalog}
//     user={this.state.user}
//     brands={this.state.brands}
//     changeTab={this.changeTab}
//     business_units={this.state.business_units}
//     updateBusinessUnitOrder={this.updateBusinessUnitOrder}
//     sushiMenuIncluded={this.state.sushiMenuIncluded}
//     catalogPages={this.state.catalogPages}
//     catalogPagesIncluded={this.state.catalogPagesIncluded}
//     goToCatalogView={this.props.goToCatalogView}
//   />
// }


export default CreateCatalog
