import React from "react";
import { render } from "react-dom";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";

/*
 * Custom toolbar component including the custom heart button and dropdowns
 */
const CustomToolbar = () => (
  <div id="toolbar">
    <button className="ql-bold" />
    <button className="ql-italic" />
    <button className="ql-underline" style={{marginRight: 30}}/>
    <button className="ql-list" value="bullet" />
    <button className="ql-list" value="ordered" />
  </div>
);


/*
 * RichTextArea component with custom toolbar and content containers
 */
class RichTextArea extends React.Component {
  state = { editorHtml: this.props.value };

  handleChange = html => {
    this.setState({ editorHtml: html });
    this.props.updateValue(this.props.attribute, html)
  };

  static modules = {
    toolbar: {
      container: "#toolbar",
    }
  };

  static formats = [
    "bold",
    "italic",
    "underline",
    "list",
    "bullet",
  ];

  render() {
    return (
      <div style={{marginTop: 20, marginBottom: 20}}>
        <h6 className="inputLabel">{this.props.label}</h6>

        <div className="text-editor">
          <CustomToolbar />
          <ReactQuill
            theme="snow"
            value={this.state.editorHtml}
            onChange={this.handleChange}
            placeholder={this.props.placeholder}
            modules={RichTextArea.modules}
            formats={RichTextArea.formats}
          />
        </div>
      </div>
    );
  }
}

export default RichTextArea
