import axios from 'axios'

let token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
axios.defaults.headers.common['X-CSRF-Token'] = token
axios.defaults.headers.common['Accept'] = 'application/json'

const catalogApiHelper = {
  loadCatalogs: () => {
    return axios.get('/load_catalogs')
    .then(response => {
        return response
    })
    .catch(error => console.log(error))
  },
  loadProducts: (data) => {
    const options = {
      method: 'post',
      url: '/load_catalog_products',
      data: data,
    };
    return axios(options)
    .then(response => {
        return response
    })
    .catch(error => console.log(error))
  },
  loadSearchableValues: (data) => {
    const options = {
      method: 'post',
      url: '/loadSearchableValues',
      data: data,
    };
    return axios(options)
    .then(response => {
        return response
    })
    .catch(error => console.log(error))
  },
  getCatalogProducts: (data) => {
    const options = {
      method: 'post',
      url: '/getCatalogProducts',
      data: data,
    };
    return axios(options)
    .then(response => {
        return response
    })
    .catch(error => console.log(error))
  },
  loadCatalog: (data) => {
    const options = {
      method: 'post',
      url: '/load_catalog',
      data: data,
    };
    return axios(options)
    .then(response => {
        return response
    })
    .catch(error => console.log(error))
  },
  loadOranizerPage: (data) => {
    const options = {
      method: 'post',
      url: '/loadOranizerPage',
      data: data,
    };
    return axios(options)
    .then(response => {
        return response
    })
    .catch(error => console.log(error))
  },
  getCatalog: (data) => {
    const options = {
      method: 'post',
      url: '/getCatalog',
      data: data,
    };
    return axios(options)
    .then(response => {
        return response
    })
    .catch(error => console.log(error))
  },
  createOrUpdate: (data) => {
    const options = {
      method: 'post',
      url: '/create_or_update_catalog',
      data: data,
    };
    return axios(options)
    .then(response => {
        return response
    })
    .catch(error => console.log(error))
  },
  quickCatalogUpdate: (data) => {
    const options = {
      method: 'post',
      url: '/quickCatalogUpdate',
      data: data,
      transformResponse: [(data) => {
        return data;
      }]
    };
    return axios(options)
    .then(response => {
        return response
    })
    .catch(error => console.log(error))
  },
  getRealtedProducts:(data) => {
    const options = {
      method: 'post',
      url: '/load_related_products',
      data: data,
    };
    return axios(options)
    .then(response => {
        return response
    })
    .catch(error => console.log(error))
  },
  createPdfOnServer: (data) => {
    const options = {
      method: 'post',
      url: '/generatedPdf',
      data: data,
    };
    return axios(options)
    .then(response => {
        return response
    })
    .catch(error => console.log(error))
  },
  publishPDF: (data) => {
    const options = {
      method: 'post',
      url: '/publishPDF',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: data,
      transformResponse: [(data) => {
        return data;
      }]
    };
    return axios(options)
    .then(response => {
        return response
    })
    .catch(error => console.log(error))
  },
  unpublishPDF: (data) => {
    const options = {
      method: 'post',
      url: '/unpublishPDF',
      data: data,
    };
    return axios(options)
    .then(response => {
        return response
    })
    .catch(error => console.log(error))
  },
  generateCatalog: (data) => {
    const options = {
      method: 'post',
      url: '/generateCatalog',
      data: data,
    };
    return axios(options)
    .then(response => {
        return response
    })
    .catch(error => console.log(error))
  },
  deleteCatalog: (data) => {
    const options = {
      method: 'post',
      url: '/deleteCatalog',
      data: data,
      transformResponse: [(data) => {
        return data;
      }]
    };
    return axios(options)
    .then(response => {
        return response
    })
    .catch(error => console.log(error))
  },
  addToCatalog: (data) => {
    const options = {
      method: 'post',
      url: '/addToCatalog',
      data: data,
      transformResponse: [(data) => {
        return data;
      }]
    };
    return axios(options)
    .then(response => {
        return response
    })
    .catch(error => console.log(error))
  },
  removeFromCatalog: (data) => {
    const options = {
      method: 'post',
      url: '/removeFromCatalog',
      data: data,
      transformResponse: [(data) => {
        return data;
      }]
    };
    return axios(options)
    .then(response => {
        return response
    })
    .catch(error => console.log(error))
  },
}

export default catalogApiHelper
