import React from 'react';
import { setCustomFilter } from 'react-table';



function BusinessUnitFilter({
  setCustomFilter,
  filters
  }) {
    
  const setFilter = function(value) {
    let existingFiliter = filters.filter(obj=>obj.value===value);
    existingFiliter.length == 0 ?
      setCustomFilter("brand.business_unit.name", value)
      : setCustomFilter("brand.business_unit.name", "")
  }

  const isSelected = function(value) {
    let existingFiliter = filters.filter(obj=>obj.value===value);
    return existingFiliter.length == 0 ?
      "rgba(238, 238, 238, 0.3)"
      : "rgba(0, 90, 195, .2)"
  }


  return (
    <span className="businessUnitFilters">
      <div onClick={(e) => setFilter("Endo")} style={{backgroundColor: isSelected("Endo")}}>
        <p>Endo</p>
      </div>
      <div onClick={(e) => setFilter("Restoratives")} style={{backgroundColor: isSelected("Restoratives")}}>
        <p>Restoratives</p>
      </div>
      <div onClick={(e) => setFilter("Rotary")} style={{backgroundColor: isSelected("Rotary")}}>
        <p>Rotary</p>
      </div>
      <div onClick={(e) => setFilter("TotalCare")} style={{backgroundColor: isSelected("TotalCare")}}>
        <p>TotalCare</p>
      </div>
    </span>
  )
}

export default BusinessUnitFilter
