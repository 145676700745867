import React from 'react';
import { Page, Text, View, Font } from '@react-pdf/renderer';
import Gotham from '../../utils/fonts/Gotham-Thin.otf'
import GothamBold from '../../utils/fonts/Gotham-Bold.otf'

const TableOfContents = (props) =>  {

  Font.register({ family: 'Gotham', fonts: [
      {src: Gotham, fontStyle: 'normal', fontWeight: 'normal', letterSpacing: "-2%"},
    ]
   })
   Font.register({ family: 'GothamBold', fonts: [
       {src: GothamBold, fontStyle: 'normal', fontWeight: 'normal', letterSpacing: "-2%"},
     ]
  })

  const fontStyle = (type) => {
    switch (type) {
      case 'unit':
        return {
          fontSize: 16,
          fontFamily: 'GothamBold',
          marginTop: 30,
          marginBottom: 5,
          color: '#0E0E2C'
        }
        break;
      case 'brand':
        return {
          fontSize: 14,
          fontFamily: 'Gotham',
          color: '#0E0E2C'
        }
        break;
      default:
      return {}
    }
  }



  const renderTableOfContents = () => {
    const bizLength = props.tableOfContents.filter(item => item.type === 'unit').length
    const brandLength = props.tableOfContents.filter(item => item.type === 'brand').length
    const pageLength = (bizLength*(16+35)) + (brandLength*(14))
    const additionalPages = (Math.ceil(pageLength/650))-1

    return props.tableOfContents.map((item, i) => {
        return (
          <View
            key={i}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'row'
          }}>
            <Text
              style={fontStyle(item.type)}>
              {`${item.name}`}
            </Text>
            <Text
              key={i}
              style={fontStyle(item.type)}>
              {`${item.pageNumber+additionalPages}`}
            </Text>
          </View>
        )
    })
  }

  return(
      <Page
        break
        style={{
          flexDirection: 'column',
          backgroundColor: '#fff',
          width: '100%',
          fontFamily: "Gotham",
          backgroundColor: 'white',
          padding: 40,
          paddingBottom: 25,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}>
          <View>
            <Text
              fixed
              style={{
                color: '#0E0E2C',
                fontFamily: 'GothamBold',
                fontSize: 24
              }}>TABLE OF CONTENTS</Text>
            {renderTableOfContents()}
          </View>
      </Page>
    )
}

export default TableOfContents
