import React from 'react';
import { Page, Text, View, Image } from '@react-pdf/renderer';
import moment from 'moment'


export default React.memo(TitlePage, (props, nextProps) => {
  return !nextProps.loaded
})


function TitlePage(props)  {
    return(
      <Page size="A4"
        style={{
          flexDirection: 'column',
          backgroundColor: '#fff',
          width: '100%',
          orientation: 'portrait',
          backgroundColor: 'white'}}>
          <View
              style={{
                width: '95%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                alignItems: 'flex-end',
                marginTop: 30,

                marginRight: 20
              }}>
                <View
                  style={{
                    backgroundColor: '#D79B81',
                    width: 120,
                    height: 24,
                    marginRight: 20
                  }} >
                 </View>
                  <Text style={{color: '#727272', textTransform: 'uppercase', fontSize: 24}}>{`${moment(props.catalog.date).format("MMM YYYY")}`}</Text>
          </View>
          <View style={{marginLeft: 50, marginTop: 300}}>
            <Text style={{color: '#312F2F', fontSize: 70, textWrap: 'wrap', whiteSpace: 'nowrap'}}>{props.catalog.name}</Text>
          </View>
      </Page>
    )
}
