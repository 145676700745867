import React from "react"
import PropTypes from "prop-types"

import AddRecordButton from '../forms/AddRecordButton'
import UserForm from './UserForm'
import UsersTable from './UsersTable'
import userApiHelper from '../utils/userApiHelper'
import LoadingSection from '../utils/LoadingSection'
import BulkUpload from './BulkUpload'

class UsersContainer extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      formOpen: false,
      users: [],
      roles: [],
      formRecord: [],
      loaded: false,
      catalog_count: '',
      dropdownOpen: false,
      bulkUploadOpen: false
    }

    this.loadUsers = this.loadUsers.bind(this)
    this.toggleForm = this.toggleForm.bind(this)
    this.editRecord = this.editRecord.bind(this)
    this.toggleArchiveUser = this.toggleArchiveUser.bind(this)
    this.adminUpdateUser = this.adminUpdateUser.bind(this)
    this.approveUser = this.approveUser.bind(this)
    this.declineUser = this.declineUser.bind(this)
    this.toggleDropdownMenu = this.toggleDropdownMenu.bind(this)
    this.dropdownMenu = this.dropdownMenu.bind(this)
    this.toggleBulkUpload = this.toggleBulkUpload.bind(this)
  }

  componentDidMount() {
    this.loadUsers()
  }

  async loadUsers() {
    let response = await userApiHelper.loadUsers()
    this.setState({
      users: JSON.parse(response.data.users),
      roles: JSON.parse(response.data.roles),
      catalog_count: JSON.parse(response.data.catalog_count),
      loaded: true
    })
  }

  async toggleArchiveUser(user) {
    let data = {
      id: user.id,
      user_params: {
        archived: !user.archived
      }
    }

    let response = await userApiHelper.adminUpdateUser(data)
    await this.loadUsers()
  }

  async adminUpdateUser(user) {
    let data = {
      user
    }

    let response = await userApiHelper.adminUpdateUser(user)
    await this.loadUsers()
  }

  toggleForm() {
    this.setState({
      formOpen: !this.state.formOpen,
      formRecord: [],
      dropdownOpen: false
    })
  }

  editRecord(record) {
    this.setState({
      formOpen: !this.state.formOpen,
      formRecord: record
    })
  }

  async approveUser(user) {
    const data = {
      id: user.id
    }

    let response = await userApiHelper.approveUser(data)
    await this.loadUsers()
  }

  async declineUser(user) {
    let data = {
      id: user.id,
      user_params: {
        approved: false,
        archived: true
      }
    }
    let response = await userApiHelper.adminUpdateUser(data)
    await this.loadUsers()
  }

  dropdownMenu() {
    if(!this.state.dropdownOpen) { return null }
    return(
      <div className="button_dropdown_menu">
        <p onClick={() => this.toggleForm()}>Add One User</p>
        <p onClick={() => this.toggleBulkUpload()}>Upload CSV</p>
      </div>
    )
  }

  toggleDropdownMenu() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    })
  }

  toggleBulkUpload() {
    this.setState({
      bulkUploadOpen: !this.state.bulkUploadOpen,
      dropdownOpen: false
    })
  }

 render() {
   if(!this.state.loaded) {
     return (<LoadingSection style={{marginTop: '20%'}}/>)
   }
    return (
      <div>

        <div className="pageHeader">
          <h2>Users</h2>
          <h5>Total Catalogs Created: {this.state.catalog_count}</h5>
          <AddRecordButton
            icon={true}
            text="Add User"
            onClick={this.toggleDropdownMenu}
            dropdown={this.dropdownMenu()}
          />
        </div>

        <UsersTable
          users={this.state.users}
          editRecord={this.editRecord}
          toggleArchiveUser={this.toggleArchiveUser}
          updateUser={this.adminUpdateUser}
          declineUser={this.declineUser}
          approveUser={this.approveUser}
        />
        {this.state.formOpen &&
          <UserForm
            loadUsers={this.loadUsers}
            toggleForm={this.toggleForm}
            formRecord={this.state.formRecord}
            roles={this.state.roles}
          />}
        {this.state.bulkUploadOpen &&
          <BulkUpload
            loadUsers={this.loadUsers}
            closeBulkUpload={this.toggleBulkUpload}
          />}
      </div>
    );
  }
}


export default UsersContainer
