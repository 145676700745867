import React, {useCallback} from 'react'
import {useDropzone} from 'react-dropzone'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faImage, faTimesCircle } from '@fortawesome/free-solid-svg-icons'


function FileDropzone(props) {
  const onDrop = useCallback(acceptedFiles => {
    // Do something with the files
    props.updateFile(acceptedFiles[0])
  }, [])
  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})
  const width = props.size === 'small' ? 100 : 200


  const previewImage = function() {
    return(
      <div style={{
        width: width,
        height: width,
        textAlign: 'center'
      }}>
        <FontAwesomeIcon
          icon={faTimesCircle}
          onClick={() => props.removeImage()}
          style={{
            color: '#A2A3A6',
            fontSize: 12,
            display: 'flex',
            marginBottom: -7,
            marginRight: -5,
            float: 'right',
            cursor: 'pointer',
            position: 'relative'
          }}
        />
        <img
          alt="Preview"
          src={(typeof props.file === "string") ? props.file : URL.createObjectURL(props.file) }
          style={{
            display: 'inline',
            maxWidth: width,
            maxHeight: width
          }}
        />
      </div>
    )
  }

  const renderUploadImage = function() {
    return(
      <div {...getRootProps()}
        style={{
          width: width,
          height: width,
          border: '3px dashed #EEEEEE',
          boxSizing: 'border-box',
          borderRadius: 10,
          cursor: 'pointer'
        }}>
        <input {...getInputProps()} />
        {
          isDragActive ?
            <div style={{textAlign: 'center', marginTop: 50}}><p>Drop the files here...</p></div>
            :
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: '#A2A3A6',
                flexDirection: 'column',
                textAlign: 'center',
                padding: 15,
                marginTop: props.size === 'small' ? 0 : 40
              }}>
              <FontAwesomeIcon
                icon={faImage}
                style={{color: '#A2A3A6', marginBottom: props.size === 'small' ? 5 : 20, fontSize: 24}}
              />
              <p>{props.prompt ? `${props.prompt}` : "Drag 'n' drop some files here, or click to select files"}</p>
            </div>
        }
      </div>
    )
  }

  return (
    <div style={{marginBottom: 40}}>
    <h6 className="inputLabel">{props.label}</h6>
    {(props.file != null) ?
      previewImage() : renderUploadImage()
    }
    </div>
  )
}

export default FileDropzone
