import React, { useEffect, useState, useRef } from "react"
import PropTypes from "prop-types"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowUp,
  faArrowDown,
  faArchive,
  faSlidersH,
  faTrash,
} from '@fortawesome/free-solid-svg-icons'
import SearchFilter from '../tables/SearchFilter'
import BusinessUnitFilter from '../tables/BusinessUnitFilter'
import CategoryFilter from '../tables/CategoryFilter'
import BrandFilter from '../tables/BrandFilter'
import SkuFilter from '../tables/SkuFilter'
import ArchivedFilter from '../tables/ArchivedFilter'
import ToggleProductButtons from './table_sections/ToggleProductButtons'
import EditButtons from './table_sections/EditButtons'
import ToggleBrand from './table_sections/ToggleBrand'
import TableGridToggle from '../tables/TableGridToggle'
import scrollbarWidth from '../utils/scrollbarWidth'
import { FixedSizeList } from 'react-window'

const kerrLogo = require('../images/logo_blue.png')

function SearchableProductsTable(props) {

    const addProductToCatalog = function(product) {
      props.addToCatalog(product)
      addProduct(product)
    }

    const canPreviousPage = props.searchParams.page != 1
    const canNextPage = props.products.length%25 === 0

    const removeProductToCatalog = function(product) {
      props.removeFromCatalog(product)
      removeProduct(product)
    }

    const addBrandToCatalog = function (brandName) {
      const brandProducts = props.products.filter(obj=>obj.brand.name===brandName)
      const products = productIncluded.concat(brandProducts)
      addBulkProducts(products)
      props.addBrandToCatalog(brandProducts[0].brand_id)
    }

    const removeBrandFromCatalog = function(brandName) {
      const brandProducts = props.products.filter(obj=>obj.brand.name===brandName)
      const removedBrandProducts = productIncluded.filter(obj=>obj.brand_id != brandProducts[0].brand_id)
      removeBulkProducts(removedBrandProducts)
      props.removeBrandFromCatalog(brandProducts[0].brand_id)
    }

    const [tableIsGrid, toggleTableGrid] = useState(window.innerWidth > 900 ? false : true)

    const [subFilterIsToggled, setSubFilterIsToggled] = useState(true);
    const toggleSubFilter = () => setSubFilterIsToggled(!subFilterIsToggled);

    const [menuOpen, toggleMenuOpen] = useState('');
    const toggleMenu = React.useCallback((product) => toggleMenuOpen(menuOpen => product === menuOpen ? '' : product));

    const [productIncluded, toggleProductIncluded] = useState(props.catalog_products);

    const addBulkProducts = React.useCallback((products) => toggleProductIncluded(products));
    const removeBulkProducts = React.useCallback((products) => toggleProductIncluded(products));

    const addProduct = React.useCallback((product) => toggleProductIncluded([...productIncluded, product]));
    const removeProduct = React.useCallback((product) => toggleProductIncluded(productIncluded.filter(item => item.id != product.id)));

    const renderProducts = () => {
      if(props.products.length < 1) {
        return (
          <tr key={1}>
            <td style={{
              textAlign: 'right',
              marginTop: 20
            }}>No products found</td>
          </tr>
        )
      }
      return props.products.map((product, i) => {
        const kerrSku = product.skus.filter(sku => sku.distributor_id === 1).length > 0 ?
          product.skus.filter(sku => sku.distributor_id === 1)[0].sku :
          ''
        return (
          <tr key={i}>
            <td style={{textAlign: 'center'}}>
              <img
               src={`${
                 product.product_image_url != undefined ?
                   product.product_image_url
                   : product.configuration_type != undefined && product.configuration_image != null ?
                       product.configuration_image
                          : product.brand.brand_image_url != undefined ?
                          product.brand.brand_image_url
                            : kerrLogo
                     }`
               } />
            </td>
            <td>{product.name}</td>
            <td>{kerrSku}</td>
            <td>{product.brand.name}</td>
            <ToggleProductButtons
              product={product}
              toggleRecord={props.toggleRecord}
              addProduct={addProductToCatalog}
              removeProduct={removeProductToCatalog}
              productIncluded={productIncluded}
            />
          </tr>
        )
      })
    }

    return(
      <div style={{width: '100%'}}>
        <div className="tableFilterSection" >
          <TableGridToggle
            tableIsGrid={tableIsGrid}
            toggleTableGrid={toggleTableGrid}
          />
          <div className="mainFilterContainer" style={{marginBottom: 0}}>
            <BusinessUnitFilter
              setCustomFilter={(key, value) => props.updateSearchParams({'businessUnit': value})}
              filters={[{id:'businessUnit', value: props.searchParams.businessUnit}]}
            />
          </div>
          {(subFilterIsToggled) && <div className="secondaryFilterContainer">
            <CategoryFilter
              setCustomFilter={(value) => props.updateSearchParams({'category': value})}
              currentFilter={props.searchParams.category}
              categories={props.categories}
            />
            <BrandFilter
              setCustomFilter={(value) => props.updateSearchParams({'brand': value})}
              currentFilter={props.searchParams.brand}
              brands={props.brands}
            />
            <SearchFilter
              preGlobalFilteredRows={null}
              globalFilter={props.searchParams.globalFilter}
              setGlobalFilter={(value) => props.updateSearchParams({'globalFilter': value})}
              placeholder="Type by part number"
            />
          </div>}
          {(props.searchParams.brand != "" && props.searchParams.brand != undefined && props.products.length > 0) &&
            <ToggleBrand
              key={props.searchParams.brand}
              filter={[{id:'brand.name', value: props.searchParams.brand}]}
              addBrandToCatalog={addBrandToCatalog}
              removeBrandFromCatalog={removeBrandFromCatalog}
            />}
          <p
            style={{marginBottom: 10}}
            className="clearFilterButton"
            onClick={() => props.clearFilters()}>Clear Filters</p>

          <div style={{
            display: 'flex',
            flexDirection: 'row',
            fontSize: 10,
            alignSelf: 'flex-end',
            margin: 5,
            alignItems: 'center',
            justifyContent: 'space-between'}}>

          </div>
          <div style={{
            display: 'flex',
            flexDirection: 'row',
            fontSize: 10,
            width: 200,
            alignSelf: 'flex-end',
            margin: 5,
            alignItems: 'center',
            justifyContent: 'space-between'}}>
            <button
              style={{
                color: !canPreviousPage ? 'rgb(0, 90, 195, .5)' : 'rgb(0, 90, 195)',
                cursor: 'pointer'
              }}
              onClick={() => props.updateSearchParams({'page': props.searchParams.page-1})}
              disabled={!canPreviousPage}>
              {`< Previous`}
            </button>
            <div style={{marginRight: 10, marginLeft: 10}}>
              {`Page ${props.searchParams.page} of ${props.totalPages}`}
            </div>
            <button
              style={{
                color: !canNextPage ? 'rgb(0, 90, 195, .5)' : 'rgb(0, 90, 195)',
                cursor: 'pointer'
              }}
              onClick={() => props.updateSearchParams({'page': props.searchParams.page+1})}
              disabled={!canNextPage}>
              {`Next >`}
            </button>
          </div>
        </div>

        <div className="tableContainer">
          <table className={tableIsGrid ? "tableGrid" : "tableStandard"}>
            <thead>
              <tr>
                <td></td>
                <td>Product</td>
                <td>Part #</td>
                <td>Brand</td>
                <td></td>
              </tr>
            </thead>
            <tbody>
              {renderProducts()}
            </tbody>
          </table>
        </div>
      </div>
    )
  }



export default SearchableProductsTable
