import React from "react";
import PropTypes from "prop-types";
import LoadingSection from '../../utils/LoadingSection'
import { PDFDownloadLink, usePDF } from '@react-pdf/renderer';
import ScrollCatalog from './ScrollCatalog'
import FileSaver from 'file-saver';
import catalogApiHelper from '../../utils/catalogApiHelper'
import LogoImage from "../../images/create_document.gif";

class PublishCatalog extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      timeout: null
    }

    this.checkIfPdfIsGenerated = this.checkIfPdfIsGenerated.bind(this)
    this.checkPdf = this.checkPdf.bind(this)
  }

  checkIfPdfIsGenerated() {
    this.setState({
      timeout: setTimeout(() => {
        this.checkPdf()
      }, 10000)
    })
  }

  componentWillUnmount() {
    const { timeout } = this.state;
    clearTimeout(timeout);
  }

  checkPdf() {
    let data = {
      id: this.props.catalog.id,
    }

    catalogApiHelper.loadCatalog(data)
    .then((response) => {
      let catalog = JSON.parse(response.data.catalog)
      if(catalog.pdf_url === null) {
        this.checkIfPdfIsGenerated()
      } else {
        this.props.viewCatalog(catalog.id)
      }

    })
    .catch((error) => {
      this.checkIfPdfIsGenerated()
    })
  }

  async componentDidMount() {
    //publish catalog

    let data = {
      id: this.props.catalog.id,
      business_units: this.props.business_units,
      sushiMenuIncluded: this.props.sushiMenuIncluded,
      catalogPagesIncluded: this.props.catalogPagesIncluded
    }

    //catalogApiHelper.createPdfOnServer(data)
    await catalogApiHelper.generateCatalog(data)
    .then((response) => {
      //start timer to check if PDF is done.
      this.checkIfPdfIsGenerated()
    })
    .catch((error) => {
      console.log('error')
    })
  }

  render() {
    return(
      <div style={{textAlign: 'center', marginTop: "5%"}}>
        <h1 className="bold">Your catalog is being created.</h1>
        <h1 style={{marginTop: 10}}>You can wait here, or go on with your day! It will be available soon on the main page.</h1>
        <img
          style={{
            objectFit: 'cover',
            marginTop: 50,
            width: '50%',
            maxWidth: 400
          }}
          alt="images"
          src={LogoImage} />
      </div>
    )
  }

}

export default PublishCatalog;
