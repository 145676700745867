import React from 'react';
import { Page, Text, View, Image } from '@react-pdf/renderer';

export default React.memo(AdditionalPage, (props, nextProps) => {
  return !nextProps.loaded
})

function AdditionalPage(props)  {
  return props.page.page_images_url.map((url, i) => {
    return(
      <Page key={url}>
        <Image
          style={{
            objectFit: 'cover',
            width: '100vw',
            height: '100vh',
          }}
          alt="images"
          src={url} />
        </Page>
      )
  })
}
