import React, { useState, useCallback, useEffect } from 'react';
import { render } from 'react-dom';

import { Page, Document } from '@react-pdf/renderer';

import CoverPage from './CoverPage'
import DistributorLogos from './DistributorLogos'
import TitlePage from './TitlePage'
import BusinessUnits from './BusinessUnits'
import SushiMenu from './SushiMenu'
import AdditionalPage from './AdditionalPage'
import TableOfContents from './TableOfContents'

class PdfPreview extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      tableOfContents: [],
      isLoaded: false,
      timeout: null
    }

    this.renderBusinessUnitBrands = this.renderBusinessUnitBrands.bind(this)
    this.renderAdditionlPages = this.renderAdditionlPages.bind(this)
    this.generateTOC = this.generateTOC.bind(this)
    this.isLoaded = this.isLoaded.bind(this)
  }

  renderBusinessUnitBrands() {
    return this.props.business_units.map((business_unit, i) => {
      return (
        <BusinessUnits
          business_unit={business_unit}
          brands={this.props.brands}
          catalog={this.props.catalog}
          generateTOC={this.generateTOC}
          loaded={this.state.loaded}
          key={business_unit.id}/>
      )
    })
  }

  renderAdditionlPages() {
    return this.props.catalogPages.map((page, i) => {
      if(this.props.catalogPagesIncluded.includes(page.id)) {
        return <AdditionalPage loaded={this.state.loaded} page={page} key={i}/>
      }
    })
  }

  isLoaded() {
    this.setState({
      isLoaded: true
    })
  }

  generateTOC(type, name, pageNumber) {
    clearTimeout(this.state.timeout);

    const index = this.state.tableOfContents.map(function(item) { return item.name; }).indexOf(name);
    if(index != -1) {
      this.state.tableOfContents[index] = {type, name, pageNumber}
    } else {
      this.state.tableOfContents = [...this.state.tableOfContents, {type, name, pageNumber}]
    }

    this.setState({
      timeout: setTimeout(() => {
        this.isLoaded()
        this.props.tocIsLoaded()
      }, 1000)
    })
  }

  shouldComponentUpdate(nextProps, nextState) {
      return this.state.isLoaded != nextState.isLoaded && nextState.isLoaded
  }

// Create Document Component
  render() {
    return(
        <Document className="document">
          <CoverPage
            catalog={this.props.catalog}
            user={this.props.user}
            loaded={this.state.loaded}
            />

          {this.state.isLoaded &&
            <TableOfContents
              tableOfContents={this.state.tableOfContents}
              businessUnitTOC={this.state.businessUnitTOC}
            />
          }
          {this.props.business_units.length > 0
            && this.renderBusinessUnitBrands(this.props)}
          {this.props.sushiMenuIncluded &&
            <SushiMenu
              business_units={this.props.business_units}
              catalog={this.props.catalog}
              brands={this.props.brands}
              loaded={this.state.loaded}
            />}
          {this.renderAdditionlPages(this.props)}
        </Document>
    )
  }
}
//          <TitlePage
          //   catalog={this.props.catalog}
          //   loaded={this.state.loaded}
          // />
          // <DistributorLogos
          //   catalog={this.props.catalog}
          //   user={this.props.user}
          //   loaded={this.state.loaded}
          // />

export default PdfPreview
