import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

function ToggleProductButtons(props) {

  const addProduct = function(product) {
    props.addProduct(product)
  }

  const removeProduct = function(product) {
    props.removeProduct(product)
  }

  const isIncluded = props.productIncluded.some(function(item) {
    return item.id === props.product.id
  })

  return (
    <td className="table_action_column" key={props.product}>
        {!isIncluded &&
          <p
            className="addProductButton"
            onClick={() => addProduct(props.product)}
            >
          <FontAwesomeIcon
            icon={faPlus}
            style={{
              color: '#005AC3',
              marginRight: 5,
            }}
          />
          Add</p>
        }
        {isIncluded &&
          <p
            onClick={() => removeProduct(props.product)}
            className="removeProductButton">Remove</p>
        }
    </td>
  )
}

export default ToggleProductButtons
