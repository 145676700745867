import React from 'react';
import { Page, Text, View, Image } from '@react-pdf/renderer';

const BusinessUnitCoverPage = (props) =>  {
  return(
      <Image
        wrap={false}
        style={{
          position: 'absolute',
          objectFit: 'fill',
          height: "99.8vh",
          zIndex: 1,
        }}
        render={({ pageNumber, totalPages }) => {
          props.generateTOC("unit", props.name, pageNumber-1)
        }}
        alt="images"
        src={props.url} />
    )
}

export default BusinessUnitCoverPage
