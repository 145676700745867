import React from "react"
import PropTypes from "prop-types"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserCircle, faCog, faSignOutAlt } from '@fortawesome/free-solid-svg-icons'

class TopNav extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      dropdownOpen: false
    }

    this.toggleSettings = this.toggleSettings.bind(this)
    this.logout = this.logout.bind(this)
    this.toggleDropdown = this.toggleDropdown.bind(this)
  }

  toggleDropdown() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    })
  }

  toggleSettings() {
    this.props.updateTab(5)
    this.toggleDropdown()
  }

  logout() {
    window.location.replace('/logout')
  }

  render() {
    return (
      <div>
        <div style={{
          borderBottom: '2px solid #F5F5F5',
          backgroundColor: 'white',
          display: 'flex',
          justifyContent: 'flex-end',
          position: 'fixed',
          width: '100vw',
          zIndex: 50
        }}>
          <div
            onClick={this.toggleDropdown}
            style={{
              borderRadius: 100,
              width: 50,
              padding: 10,
            }}>
            {this.props.user.image_url != null &&
              <img
                src={this.props.user.image_url}
                style={{
                  height: 50,
                  width: 50,
                  color:  '#14467C',
                  cursor: 'pointer',
                  borderRadius: 100,
                  objectFit: 'cover',
                }}
              />
            }
            {this.props.user.image_url === null &&
              <FontAwesomeIcon
                icon={faUserCircle}
                style={{
                  height: 50,
                  width: 50,
                  color:  '#14467C',
                  cursor: 'pointer'
                }}
              />
            }
          </div>
          {this.state.dropdownOpen &&
            <div className="dropdown_menu">
              <div onClick={this.toggleSettings}>
                <FontAwesomeIcon
                  icon={faCog}
                  style={{
                    height: 20,
                    width: 20,
                    marginRight: 20
                  }}
                />
                <p>Settings</p>
              </div>
              <div onClick={this.logout}>
                <FontAwesomeIcon
                  icon={faSignOutAlt}
                  style={{
                    height: 20,
                    width: 20,
                    marginRight: 20
                  }}
                />
                <p>Log out</p>
              </div>
            </div>
          }
        </div>
        {this.state.dropdownOpen === true &&
        <div
          onClick={this.toggleDropdown}
          style={{position: 'absolute', zIndex: 20, backgroundColor: 'rgba(0,0,0,.1)', width: '100vw', height: '100vh'}}>
        </div>}
      </div>
    );
  }
}


export default TopNav
