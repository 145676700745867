import React from 'react';
import { useTable, useGlobalFilter } from 'react-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

function SearchFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  placeholder
  }) {

  return (
    <span className="brandFilters">
      <div style={{marginTop: 20, marginBottom: 20}}>
        <h6 className="inputLabel">Part Number</h6>
        <div className="searchField">
          <FontAwesomeIcon
            icon={faSearch}
            style={{
              color: '#A2A3A6',
              marginRight: 5,
            }}
            />{' '}
          <input
            value={globalFilter || ''}
            onChange={e => {
              setGlobalFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
            }}
            placeholder={placeholder}
            style={{
              fontSize: '1.1rem',
              border: '0',
              width: '90%'
            }}
          />
        </div>
      </div>
    </span>
  )
}

export default SearchFilter
