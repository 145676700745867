import React, { useState, useCallback } from "react"
import PropTypes from "prop-types"
import { HTML5Backend } from 'react-dnd-html5-backend'
// import { DndProvider } from 'react-dnd'
import { TouchBackend } from 'react-dnd-touch-backend';
import { DndProvider } from 'react-dnd-multi-backend';
import HTML5toTouch from 'react-dnd-multi-backend/dist/esm/HTML5toTouch'; // or any other pipeline
import { usePreview } from 'react-dnd-preview';

import update from 'immutability-helper';
import SortableCard from './SortableCard'


const CatalogContentOrganizer = ({
  business_units,
  updateContents,
  sushiMenu,
  catalogPages,
  catalogPagesIncluded
}) => {
  const isMobile = window.innerWidth < 680
  const [cards, setCards] = useState(business_units);

  const moveCard = useCallback((dragIndex, hoverIndex) => {
      const dragCard = cards[dragIndex];
      setCards(update(cards, {
          $splice: [
              [dragIndex, 1],
              [hoverIndex, 0, dragCard],
          ],
      }))
  }, [cards])

  const [sushiMenuIncluded, setSushiMenuIncluded] = useState(sushiMenu);

  const [pages, setPages] = useState(catalogPagesIncluded);

  const updatePages = useCallback((pageId) => {
    pages.includes(pageId) ?
      setPages(pages.filter(x => x!=pageId))
      : setPages([...pages, pageId])
  })

  const MyPreview = () => {
    const {display, itemType, item, style} = usePreview();


    if (!display) {
      return null;
    }

    return <div className="item-list__item" style={
      {...style,
        border: '1px solid rgba(238, 238, 238, 1)',
        padding: '1rem',
        backgroundColor: 'white',
        color: '#000000',
        fontSize: 14,
        borderRadius: 4,
        cursor: 'move',
        display: 'flex',
        width: 250,
        justifyContent: 'space-between',
        letterSpacing: '-0.02em',
      }}>{cards[item.index].name}</div>;
  };


  const renderCard = (card, index) => {
      return (
        <SortableCard
          key={card.id}
          index={index}
          id={card.id}
          name={card.name}
          moveCard={moveCard}/>);
  };

  const optionalPage = (title, selected, onClick) => {
    return(
      <div
        key={title}
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          border: '1px solid #EEEEEE',
          borderRadius: 4,
          alignItems: 'center',
          padding: 10,
          marginTop: 10
        }}>
        <p>{title}</p>
        <div
          onClick={onClick}
          style={{
            backgroundColor: selected ? "#005AC3" : "#fff",
            border: '2px solid #005AC3',
            width: 18,
            height: 18,
            borderRadius: 20,
            cursor: 'pointer'
          }}></div>
      </div>
    )
  }

  return (
    <div>
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <div>
          <h4 className="bold" style={{marginBottom: 15}}>Content</h4>
          <p>Drag and drop to rearrange business units</p>
        </div>
      </div>
      <DndProvider backend={isMobile ? TouchBackend : HTML5Backend} >
        <div>{cards.map((card, i) => renderCard(card, i))}</div>
        <MyPreview />
      </DndProvider>
      <h6 style={{marginTop: 20}}>OPTIONAL PAGES</h6>
      {optionalPage(`Customer order form`, sushiMenuIncluded, () => setSushiMenuIncluded(!sushiMenuIncluded))}
      {catalogPages.map((page, i) => {
        return optionalPage(page.title, pages.includes(page.id), () => updatePages(page.id))
      })}

      <div style={{alignSelf: 'flex-end', marginTop: '5%'}}>
        <button
          className="button_primary"
          onClick={() => updateContents(cards, sushiMenuIncluded, pages)}>Publish Catalog</button>
      </div>
    </div>
  )

}

export default CatalogContentOrganizer
