import React from 'react';
import { Page, Text, View, Image, Font } from '@react-pdf/renderer';
import Gotham from '../../utils/fonts/Gotham-Thin.otf'
import GothamBold from '../../utils/fonts/Gotham-Bold.otf'

export default React.memo(BrandPage, (props, nextProps) => {
  return !nextProps.loaded
})


function BrandPage(props)  {

  Font.register({ family: 'Gotham', fonts: [
      {src: Gotham, fontStyle: 'normal', fontWeight: 'normal', letterSpacing: "-2%"},
    ]
   })
   Font.register({ family: 'GothamBold', fonts: [
       {src: GothamBold, fontStyle: 'normal', fontWeight: 'normal', letterSpacing: "-2%"},
     ]
  })

  const styles = {
    textHeader: {
      color: '#0E0E2C',
      fontSize: 8,
      width: '15%',
      fontSize: 8,
      fontFamily: 'GothamBold',
      textAlign: 'left'
    },
    lineItem: {
      color: '#0E0E2C',
      fontSize: 8,
      width: '15%',
      textAlign: 'left',
    }
  }

  const renderSkus = (skus) => {
    return props.catalog.distributors.filter(distributor => distributor.id != 1).map((distributor, i) => {
        const sku = skus.filter((sku) => (sku.distributor_id === distributor.id && distributor.id != 1))
        const skuName = sku.length > 0 ? sku[0].sku : ''

        return(
          <Text style={styles.lineItem} key={i}>{skuName}</Text>
        )
    })
  }

  const renderProducts = (brand) => {
    return brand.products.map((product, i) => {
      const kerrSku = product.skus.filter((sku) => sku.distributor_id == 1)[0]
      return(
        <View key={i} style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
          <Text style={[styles.lineItem, {width: '30%'}]}>{product.name}</Text>
          <Text style={styles.lineItem}>{kerrSku != undefined ? kerrSku.sku : ''}</Text>
          {renderSkus(product.skus)}
          <Text style={styles.lineItem}>___________</Text>
        </View>
      )
    })
  }

  const renderBrands = (props) => {
    return props.business_units.map((unit, i) => {
      return props.brands.filter(brand => brand.business_unit_id === unit.id).map((brand, i) => {
          return(
            <View key={i} style={{marginTop: 5, marginBottom: 15}}>
              <Text fixed={i != 0} style={{fontFamily: 'GothamBold', marginBottom: 10, color: '#0E0E2C', fontSize: 10}}>{brand.name}</Text>
              <View fixed={i != 0} style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 5}}>
                <Text style={[styles.textHeader, {width: '30%'}]}>Product Description</Text>
                <Text key={i} style={styles.textHeader}>Kerr</Text>
                {renderDistrbutors(props.catalog.distributors)}
                <Text style={styles.textHeader}>QTY</Text>
              </View>
              {renderProducts(brand)}
            </View>
          )
      })
    })
  }

  const renderDistrbutors = (distributors) => {
    return distributors.filter(d => d.id != 1).map((distributor, i) =>{
        return(<Text key={i} style={styles.textHeader}>{distributor.name}</Text>)
    })
  }

  return(
      <Page
        style={{
          flexDirection: 'column',
          backgroundColor: '#fff',
          width: '100%',
          fontFamily: "Gotham",
          backgroundColor: 'white',
          padding: 40,
          paddingTop: 30,
          paddingBottom: 60,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}>
        <View>
          <Text fixed style={{fontSize: 12, fontFamily: 'GothamBold', color: '#14467C', marginBottom: 20}}>Order Page</Text>
          {renderBrands(props)}
        </View>

      </Page>
    )
}
