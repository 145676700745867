import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faList,
  faTh
} from '@fortawesome/free-solid-svg-icons'

function TableGridToggle({
  toggleTableGrid,
  tableIsGrid
  }) {

  return (
    <span style={{alignSelf: 'flex-end'}}>
      <FontAwesomeIcon
        icon={faList}
        onClick={() => toggleTableGrid(false)}
        style={{
          color: !tableIsGrid ? '#14467C' : '#D4D4D7' ,
          marginLeft: 5,
          padding: 5,
          cursor: 'pointer',
        }}
        />
      <FontAwesomeIcon
        icon={faTh}
        onClick={() => toggleTableGrid(true)}
        style={{
          color: tableIsGrid ? '#14467C' : '#D4D4D7' ,
          marginLeft: 5,
          padding: 5,
          cursor: 'pointer',
        }}
        />
    </span>
  )
}

export default TableGridToggle
