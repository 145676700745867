import React, { useState, useCallback } from 'react';
import PropTypes from "prop-types"
import FormContainer from '../../forms/FormContainer'

const SelectedProducts = (props) =>  {

  const [tab, setTab] = useState(0)
  const [brands, setBrands] = useState(props.catalog_brands)
  const [products, setProducts] = useState(props.catalog_products)

  const styleTab = function(n) {
    return  n === tab ?
      {
        color: '#0E0E2C',
        padding: 10,
        margin: 10,
        borderBottom: '2px solid #0E0E2C',
        cursor: 'pointer'
      }
      : {
        color: 'rgba(14, 14, 44, 0.5)',
        padding: 10,
        margin: 10,
        cursor: 'pointer'
      }
  }


  const removeBrand = function(brand_id, key) {
    let newBrands = brands.filter(obj=>obj.id!=brand_id)
    let newProducts = products.filter(obj=>obj.brand_id!=brand_id)
    setBrands(newBrands)
    setProducts(newProducts)
    props.removeBrandFromCatalog(brand_id)
  }

  const removeProduct = function(product, key) {
    props.removeFromCatalog(product)
    let newProducts = products.filter(obj=>obj.id!=product.id)
    setProducts(newProducts)

    let brandProducts = newProducts.filter(obj=>obj.brand_id===product.brand_id)
    if(brandProducts.length === 0) {
      let newBrands = brands.filter(obj=>obj.id!=product.brand_id)
      setBrands(newBrands)
    }
  }

  const renderBrands = function() {
    if(brands.length === 0) { return (<p>No brands selected</p>) }
    return brands.map((brand, i) => {
      return(
        <div key={i} className="selectedProductListItem">
          <img src={brand.brand_image_url} />
          <p>{brand.name}</p>
          <p
            onClick={() => removeBrand(brand.id, i)}
            className="removeProductButton"
            style={{height: 14}}
            >Remove</p>
        </div>)
    })
  }

  const renderProducts = function() {
    return products.map((product, i) => {
      return(
        <div key={i} className="selectedProductListItem">
          <p>{product.name}</p>
          <p
            onClick={() => removeProduct(product, i)}
            className="removeProductButton"
            style={{height: 14}}
            >Remove</p>
        </div>)
    })
  }

  return(
    <FormContainer onClose={props.onClose}>
      <h2>Selected products</h2>
      <div style={{display: 'flex', flexDirection: 'row', marginTop: 30}}>
        <h3
          onClick={() => setTab(0)}
          style={styleTab(0)}>Brands</h3>
        <h3
          onClick={() => setTab(1)}
          style={styleTab(1)}>Products</h3>
      </div>
      <div style={{maxHeight: '50vh', overflow: 'scroll'}}>
        {tab === 0 && renderBrands()}
        {tab === 1 && renderProducts()}
      </div>
    </FormContainer>
  )
}

export default SelectedProducts
