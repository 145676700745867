import React from 'react';
import { Page, Text, View, Image, Font } from '@react-pdf/renderer';
import Gotham from '../../utils/fonts/Gotham-Thin.otf'
import GothamBold from '../../utils/fonts/Gotham-Bold.otf'
import ReactHtmlParser from 'react-html-parser'
import RotaryProduct from './brand_page_sections/RotaryProduct'

const BrandPage = (props) =>  {

  Font.register({ family: 'Gotham', fonts: [
      {src: Gotham, fontStyle: 'normal', fontWeight: 'normal', letterSpacing: "-2%"},
    ]
   })
   Font.register({ family: 'GothamBold', fonts: [
       {src: GothamBold, fontStyle: 'normal', fontWeight: 'normal', letterSpacing: "-2%"},
     ]
  })

  const isRotary = props.business_unit.name === "Rotary"


  const tableRow = {
    fontSize: 12,
    margin: 5,
    marginBottom: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  }

  const endRow  = {
    display: 'flex',
    flexDirection: 'row',
  }

  const tableRowText = {
    width: 80,
    paddingLeft: 10,
    float: 'right'
  }

  const renderConfigurations = function() {
    return props.brand.configurations_for_brand.map((configuration, i) => {
      //make sure there are products with this configuration
      const configurationProducts = props.brand.products.filter(product => product.configuration_type_id == configuration.id)
      if(configurationProducts.length === 0) {
        return null
      }
      const brand_configuration = props.brand.brand_configurations.filter(bc => bc.configuration_type_id == configuration.id)[0]
      return(
        <View
          key={i}
          wrap={configurationProducts.length > 10}
          style={{
            display: 'flex',
            flexDirection: isRotary ? "column" : "row",
            justifyContent: 'space-between',
            marginTop: 20,
          }}>
          <View
            fixed
            style={{
              height: 150,
              width: "35%",
            }}>
              {brand_configuration != undefined && brand_configuration.image_url != null &&
                <Image
                    style={{
                      marginRight: 20,
                      objectFit: 'contain',
                      maxHeight: 400
                    }}
                    alt="images"
                    src={brand_configuration.image_url+ '?' + new Date()} />
             }
           </View>
           <View
              style={{
                width: isRotary ? "100%" : "60%",
                width: "60%",
                marginTop: 15,
              }}>
              <View fixed style={{borderTop: '3px solid #D79B81', marginTop: 15, width: '100%'}}>
                <Text
                  style={{
                    marginTop: 20,
                    marginBottom: 15,
                    fontSize: 10,
                    fontFamily: "GothamBold"
                  }}>{configuration.name}</Text>
                {renderTableHeader(isRotary)}
              </View>
              {props.brand.products.map((product, i) => {
                if(product.configuration_type_id === configuration.id) {
                  return renderTableRow(product, i)
                }
              })}
           </View>
        </View>
      )
    })
  }

  const renderProductsWithoutConfiguration = function(products) {
    return products.map((product, i) => {
        return renderTableRow(product, i)
    })
      // return (<View style={{width: isRotary ? "100%" : "60%", alignSelf: 'flex-end'}} key={i}>{renderTableRow(product, i)}</View>)
  }

  const renderTableHeader = function(isRotary, i) {
    return(
      <View style={[tableRow, {lineHeight: 1}]}>
        <Text style={{fontSize: 8, fontFamily: "GothamBold", width: '50%'}}>Product Description</Text>
        {isRotary && <Text style={{fontSize: 8, fontFamily: "GothamBold", width: '25%'}}>Diameter / Length / Flutes</Text>}
        {isRotary && <Text style={{fontSize: 8, fontFamily: "GothamBold", width: '25%'}}>Shape</Text>}
        {isRotary && <Text style={{fontSize: 8, fontFamily: "GothamBold", width: '25%'}}>Grit</Text>}
        <View style={endRow}>
          <Text style={{fontSize: 8, fontFamily: "GothamBold", width: '30%'}}>Kerr</Text>
          {props.catalog.distributors.map((distributor, i) => {
            if(distributor.id != 1) {
              return <Text style={{fontSize: 8, fontFamily: "GothamBold", width: '30%', flexWrap: 'wrap'}} key={i}>{distributor.name}</Text>
            }
          })}
        </View>
      </View>
    )
  }

  const renderTableRow = function(product, i) {
    const isRotary = props.business_unit.name === "Rotary"
    const kerrSku = product.skus.filter(sku => (sku.distributor_id == 1))[0]
    return(
      <View style={tableRow} key={i} wrap={false}>
        <Text style={{fontSize: 8, width: '50%'}}>{product.name}</Text>
        <View style={endRow}>
          {isRotary && <Text style={{fontSize: 8, width: '25%'}}>{product.diameter} / {product.length} / {product.flutes}</Text>}
          {isRotary && <Text style={{fontSize: 8, width: '25%'}}>{product.shape != undefined && product.shape.name}</Text>}
          {isRotary && <Text style={{fontSize: 8, width: '25%'}}>{product.grit != undefined && product.grit.name}</Text>}
          <Text key={i} style={{fontSize: 8, width: '30%'}} key={i}>{kerrSku != undefined ? kerrSku.sku : ''}</Text>
          {props.catalog.distributors.map((distributor, i) => {
            if(distributor.id != 1) {
              const sku = product.skus.filter(sku => sku.distributor_id == distributor.id)[0]
              return <Text key={i} style={{fontSize: 8, width: '30%'}} key={i}>{sku != undefined ? sku.sku : ''}</Text>
            }
          })}
        </View>
      </View>
    )
  }

  const renderProducts = function() {
    const productsWithoutConfiguration = props.products.filter((item) => (item.configuration_type_id === null || item.configuration_type_id === 0))
    if(isRotary) {
      return(
        <View style={{marginRight: -40, marginLeft: -40}}>
          <View style={{display: 'flex', flexWrap: 'wrap', alignSelf: 'center',  flexDirection: 'row', borderTop: '3px solid #D79B81', width: '90%', marginTop: 10, paddingTop: 10}}>
            {props.brand.products.map((product, i) => {
              return(
                <RotaryProduct
                  isLogicSet={props.brand.category.name === "Logic Set" || props.brand.category.name === "Endo Logic Set"}
                  product={product}
                  brand={props.brand}
                  distributors={props.catalog.distributors}
                  key={i}
                />
              )
            })}
          </View>
        </View>
      )
    }
    return(
      <View>
        {props.brand.brand_configurations != null && renderConfigurations()}
        {productsWithoutConfiguration.length > 0 &&
          <View
            wrap={productsWithoutConfiguration.length > 10}
            style={{
              width: isRotary ? "100%" : "60%",
              alignSelf: 'flex-end',
            }}>
            <View
              fixed
              style={{
                marginTop: isRotary ? 10 : 30,
                borderTop: '3px solid #D79B81',
                width: "100%",
              }}>
              {renderTableHeader(isRotary)}
            </View>
            {renderProductsWithoutConfiguration(productsWithoutConfiguration)}
         </View>}
      </View>
    )
  }

  const brand_configurations = props.brand.brand_configurations.map(item => item.configuration_type_id)

  return(
      <View
        style={{
          flexDirection: 'column',
          backgroundColor: '#fff',
          width: '100%',
          fontFamily: "Gotham",
          paddingLeft: 40,
          paddingRight: 40,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}>
          <View fixed style={{minHeight: 30}}></View>
            {props.brand.category != null &&
              <Text fixed style={{color: '#14467C', textTransform: 'capitalize', fontSize: 12, fontFamily: 'GothamBold', marginBottom: 20}}>{props.brand.category.name}</Text>}
              <Text
                fixed
                style={{
                  fontFamily: 'GothamBold',
                  fontSize: 36,
                }}>{props.brand.name}</Text>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginTop: isRotary ? 5 : 20,
                width: '100%',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}>
                <Text
                  render={({ pageNumber, totalPages }) => {
                    props.generateTOC("brand", props.brand.name, pageNumber-1)
                  }}
                />
                <Text style={{
                  fontSize: 12,
                  marginBottom: isRotary ? 0 : 20,
                  marginTop: isRotary ? 0 : 20,
                  width: isRotary ? '74%' : '50%',
                  lineHeight: 1.2
                }}>{ReactHtmlParser(props.brand.description)}</Text>
                {props.brand.brand_image_url != null &&
                    <Image
                      style={{
                        width: isRotary ? '25%' : '50%',
                        maxHeight: isRotary ? 100 : 300,
                        objectFit: 'contain',
                      }}
                      alt="images"
                      src={`${props.brand.brand_image_url}`+ '?' + new Date()} />
              }
          </View>
          {renderProducts()}
      </View>
    )
}



export default BrandPage
