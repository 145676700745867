import React, { useState, useRef } from "react"
import PropTypes from "prop-types"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowUp,
  faArrowDown,
  faArchive,
  faSlidersH,
  faTrash,
  faSquare
} from '@fortawesome/free-solid-svg-icons'
import SearchFilter from '../tables/SearchFilter'
import BusinessUnitFilter from '../tables/BusinessUnitFilter'
import CategoryFilter from '../tables/CategoryFilter'
import BrandFilter from '../tables/BrandFilter'
import SkuFilter from '../tables/SkuFilter'
import ArchivedFilter from '../tables/ArchivedFilter'
import ToggleProductButtons from './table_sections/ToggleProductButtons'
import EditButtons from './table_sections/EditButtons'
import ToggleBrand from './table_sections/ToggleBrand'
import TableGridToggle from '../tables/TableGridToggle'
import scrollbarWidth from '../utils/scrollbarWidth'
const kerrLogo = require('../images/logo_blue.png')

import { FixedSizeList } from 'react-window'

function ProductsTable(props) {


    const scrollBarSize = React.useMemo(() => scrollbarWidth(), [])

    const canPreviousPage = props.searchParams.page != 1
    const canNextPage = props.products.length%25 === 0

    const [tableIsGrid, toggleTableGrid] = useState(window.innerWidth > 900 ? false : true)

    const [subFilterIsToggled, setSubFilterIsToggled] = useState(true);
    const toggleSubFilter = () => setSubFilterIsToggled(!subFilterIsToggled);

    const [menuOpen, toggleMenuOpen] = useState('');
    const toggleMenu = React.useCallback((product) => toggleMenuOpen(menuOpen => product === menuOpen ? '' : product));

    const elipsisMenu = function(menuOpen, product) {
      if(menuOpen === product){
      return (
        <div className="button_dropdown_menu" style={{width: 150}}>
          <div className="button_dropdown_item">
            <FontAwesomeIcon
              icon={faArchive}
              style={{
                color: '#005AC3',
                marginLeft: 5,
                padding: 8,
                borderRadius: 4,
              }}
              />
            <p onClick={() => props.toggleArchiveProduct(product)}>{product.archived ? 'Unarchive' : 'Archive'}</p>
          </div>
          <div className="button_dropdown_item">
            <FontAwesomeIcon
              icon={faTrash}
              style={{
                color: '#005AC3',
                marginLeft: 5,
                padding: 8,
                borderRadius: 4,
              }}
              />
            <p onClick={() => {
              props.toggleDeleteProduct(product)
              toggleMenu()
            }}>{'Delete'}</p>
          </div>
        </div>
      )}
      else {return null}
    }

    const renderProducts = () => {
      if(props.products.length < 1) {
        return (
          <tr key={1}>
            <td></td>
            <td style={{width: '25%'}}></td>
            <td style={{
              textAlign: 'center',
              marginTop: 20
            }}>No products found</td>
          </tr>
        )
      }
      return props.products.map((product, i) => {
        const kerrSku = product.skus.filter(sku => sku.distributor_id === 1).length > 0 ?
          product.skus.filter(sku => sku.distributor_id === 1)[0].sku :
          ''
        return (
          <tr key={i}>
            {props.user.role_id === 3 &&
              <td>
                <FontAwesomeIcon
                  onClick={() => props.toggleProductSelect(product.id)}
                  icon={faSquare}
                  style={{
                    color: props.productsSelected.includes(product.id) ? '#005AC3' : 'white',
                    border: 'solid 1px #005AC3',
                    marginLeft: 5,
                    borderRadius: 4,
                    height: 20,
                    width: 20
                  }}
                  />
              </td>}
            <td style={{textAlign: 'center'}}>
              <img
              style={{maxHeight: 50}}
               src={`${
                 product.product_image_url != undefined ?
                   product.product_image_url
                   : product.configuration_type != undefined && product.configuration_image != null ?
                       product.configuration_image
                       :product.brand.brand_image_url != undefined ?
                          product.brand.brand_image_url
                            : kerrLogo}`
               } />
            </td>
            <td>{product.name}</td>
            <td>{kerrSku}</td>
            <td>{product.brand.name}</td>
            {props.user.role_id === 3 && <td>
              <EditButtons
                  product={product}
                  toggleRecord={props.toggleRecord}
                  user={props.user}
                  editRecord={props.editRecord}
                  toggleMenu={toggleMenu}
                  elipsisMenu={elipsisMenu}
                  menuOpen={menuOpen}
                />
            </td>}
          </tr>
        )
      })
    }

    const rowStyle = function(row) {
      if(row.original.archived){
        return "trArchived"
      }
      return "trStandard"
    }

    return(
      <div style={{width: '100%'}}>
        <div className="tableFilterSection" >
          <TableGridToggle
            tableIsGrid={tableIsGrid}
            toggleTableGrid={toggleTableGrid}
          />
            <div className="mainFilterContainer" style={{marginBottom: 0}}>
              <BusinessUnitFilter
                setCustomFilter={(key, value) => props.updateSearchParams({'businessUnit': value})}
                filters={[{id:'businessUnit', value: props.searchParams.businessUnit}]}
              />
            </div>
            {(subFilterIsToggled) && <div className="secondaryFilterContainer">
              <div>
                <CategoryFilter
                  setCustomFilter={(value) => props.updateSearchParams({'category': value})}
                  currentFilter={props.searchParams.category}
                  categories={props.categories}
                />
                {props.user.role_id === 3 &&
                  <p className="clearFilterButton" onClick={() => props.toggleManageCategories()}>Manage Categories</p>}
              </div>
              <div>
                <BrandFilter
                  setCustomFilter={(value) => props.updateSearchParams({'brand': value})}
                  currentFilter={props.searchParams.brand}
                  brands={props.brands}
                />
                {props.user.role_id === 3 &&
                  <p className="clearFilterButton" onClick={() => props.toggleManageBrands()}>Manage Brands w/o Products</p>}
              </div>
              <SearchFilter
                preGlobalFilteredRows={null}
                globalFilter={props.searchParams.globalFilter}
                setGlobalFilter={(value) => props.updateSearchParams({'globalFilter': value})}
                placeholder="Find by part number"
              />
              {props.user.role_id === 3 &&<ArchivedFilter
                setCustomFilter={(key, value) => props.updateSearchParams({'archived': value})}
                filters={[{id:'archived', value: props.searchParams.archived}]}
              />}
            </div>}
            <p className="clearFilterButton" onClick={() => props.clearFilters()}>Clear Filters</p>
            <div style={{
              display: 'flex',
              flexDirection: 'row',
              fontSize: 10,
              width: 200,
              alignSelf: 'flex-end',
              margin: 5,
              alignItems: 'center',
              justifyContent: 'space-between'}}>
              <button
                style={{
                  color: !canPreviousPage ? 'rgb(0, 90, 195, .5)' : 'rgb(0, 90, 195)',
                  cursor: 'pointer'
                }}
                onClick={() => props.updateSearchParams({'page': props.searchParams.page-1})}
                disabled={!canPreviousPage}>
                {`< Previous`}
              </button>
              <div style={{marginRight: 10, marginLeft: 10}}>
                {`Page ${props.searchParams.page} of ${props.totalPages}`}
              </div>
              <button
                style={{
                  color: !canNextPage ? 'rgb(0, 90, 195, .5)' : 'rgb(0, 90, 195)',
                  cursor: 'pointer'
                }}
                onClick={() => props.updateSearchParams({'page': props.searchParams.page+1})}
                disabled={!canNextPage}>
                {`Next >`}
              </button>
            </div>

        </div>


        <div className="tableContainer">
          <table className={tableIsGrid ? "tableGrid" : "tableStandard"}>
            <thead>
              <tr>
                {props.user.role_id === 3 && <td
                    style={{
                      color: '#14467C',
                      cursor: 'pointer'
                    }}
                    onClick={() => props.toggleChangeBrand()}>{props.productsSelected.length > 0 ? 'Change Brand' : ''}</td>}
                <td></td>
                <td>Product</td>
                <td>Part #</td>
                <td>Brand</td>
                {props.user.role_id === 3 && <td></td>}
              </tr>
            </thead>
            <tbody>
              {renderProducts()}
            </tbody>
          </table>

        </div>
      </div>
    )
  }


  // <FontAwesomeIcon
  //   icon={faSlidersH}
  //   onClick={toggleSubFilter}
  //   style={{
  //     color: subFilterIsToggled ? '#14467C' : 'white' ,
  //     backgroundColor: subFilterIsToggled ? 'white' : '#14467C',
  //     marginLeft: 5,
  //     padding: 10,
  //     borderRadius: 10,
  //     cursor: 'pointer',
  //     border: '#14467C 2px solid' ,
  //   }}
  //   />

export default ProductsTable
