import React from "react"
import PropTypes from "prop-types"
import FormContainer from '../../forms/FormContainer'
import TextInput from '../../forms/TextInput'
import AddRecordButton from '../../forms/AddRecordButton'
import productApiHelper from '../../utils/productApiHelper'

class ManageBrandsWithoutProducts extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      brands: [],
      deleteBrand: [],
      deleteBrandOpen: false
    }

    this.confirmDeleteBrand = this.confirmDeleteBrand.bind(this)
    this.deleteBrand = this.deleteBrand.bind(this)
  }

  async componentDidMount() {
    let response = await productApiHelper.brandsWithoutProducts()
    this.setState({
      brands: JSON.parse(response.data.brands)
    })
  }

  confirmDeleteBrand(brand) {
    const { id, name } = brand
    this.setState({
      deleteBrandOpen: true,
      deleteBrand: brand
    })
  }

  async deleteBrand() {
    let response = await productApiHelper.deleteBrand(this.state.deleteBrand)
    this.setState({
      brands: JSON.parse(response.data.brands),
      deleteBrandOpen: false,
      deleteBrand: []
    })
  }


  renderBrands() {
    if(this.state.brands.length === 0) {
      return(
        <h5 style={{fontFamily: 'GothamBold', textAlign: 'center'}}>No brands found.</h5>
      )
    }
    return this.state.brands.map((brand, i) => {
      return(
        <div
          key={i}
          style={{
            display: 'flex',
            flexDirection: 'row',
            borderTop: '1px solid rgba(238, 238, 238, 0.3)',
            paddingTop: 20,
            paddingBottom: 20,
            justifyContent: 'space-between'
          }}>
          <h5 style={{fontFamily: 'GothamBold'}}>{brand.name}</h5>
          <h5
            onClick={() => this.confirmDeleteBrand(brand)}
            style={{color: '#BD806D', cursor: 'pointer'}}>Delete brand</h5>
        </div>
      )
    })
  }

  render() {
    if(this.state.deleteBrandOpen) {
      return(
        <FormContainer
          onClose={() => this.setState({deleteBrandOpen: false})}
        >
          <h1 style={{marginBottom: 15}}>Are you sure you want to permanently delete the brand <span style={{fontFamily: 'GothamBold'}}>{this.state.deleteBrand.name}</span>?</h1>
          <AddRecordButton
            text="Delete Forever"
            onClick={this.deleteBrand}
          />
        </FormContainer>
      )
    }

    return (
      <FormContainer
        onClose={this.props.toggleManageBrands}
      >
        <h1 style={{marginBottom: 15}}>Here are the brands without products. Deleting brands is PERMANENT.</h1>
        {this.renderBrands()}
      </FormContainer>
    )
  }
}


export default ManageBrandsWithoutProducts
