import React from "react"
import PropTypes from "prop-types"
import AddRecordButton from '../forms/AddRecordButton'
import catalogApiHelper from '../utils/catalogApiHelper'
import CatalogTable from './CatalogTable'
import DeleteCatalogForm from './DeleteCatalogForm'
import CreateCatalog from './CreateCatalog'
import LoadingSection from '../utils/LoadingSection'
import CatalogPagesContainer from './catalog_pages/CatalogPagesContainer'
import ViewCatalog from './published_pdf/ViewCatalog'

class CatalogContainer extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      catalogs: [],
      customers: [],
      loaded: false,
      deleteCatalog: [],
      deleteCatalogFormOpen: false,
      editFormOpen: false,
      pageManagementOpen: false,
      viewCatalogId: ''
    }
    this.unpublishCatalog = this.unpublishCatalog.bind(this)
    this.toggleArchiveCatalog = this.toggleArchiveCatalog.bind(this)
    this.toggleDeleteCatalog = this.toggleDeleteCatalog.bind(this)
    this.loadCatalogs = this.loadCatalogs.bind(this)
    this.editRecord = this.editRecord.bind(this)
    this.toggleEditForm = this.toggleEditForm.bind(this)
    this.togglePageManagement = this.togglePageManagement.bind(this)
    this.goToCatalogView = this.goToCatalogView.bind(this)
    this.viewCatalog = this.viewCatalog.bind(this)
    this.generateCatalog = this.generateCatalog.bind(this)
  }

  loadCatalogs() {
    catalogApiHelper.loadCatalogs()
    .then((response) => {
      this.setState({
        catalogs: JSON.parse(response.data.catalogs),
        customers: JSON.parse(response.data.customers),
        loaded: true,
        deleteCatalogFormOpen: false
      })
    })
  }

  async generateCatalog(id) {
    let data = {id: id}
    await catalogApiHelper.generateCatalog(data)
  }

  componentDidMount() {
    if(this.props.catalogId) {
      this.setState({
        viewCatalogId: this.props.catalogId,
        loaded: true
      })
    } else {
      this.loadCatalogs()
    }

  }

  viewCatalog(catalog) {
    this.setState({
      viewCatalogId: catalog.id,
      viewCatalog: catalog
    })
  }

  goToCatalogView(catalogId) {
    this.setState({
      viewCatalogId: catalogId
    })
  }

  toggleDeleteCatalog(catalog) {
    this.setState({
      deleteCatalog: catalog,
      deleteCatalogFormOpen: !this.state.deleteCatalogFormOpen,
    })
  }

  editRecord(record) {
    this.setState({
      formRecord: record,
      editFormOpen: true
    })
  }

  async unpublishCatalog(catalog) {
    let data = {
      id: catalog.id,
    }

    let response = await catalogApiHelper.unpublishPDF(data)
  }

  async toggleArchiveCatalog(catalog) {
    let data = {
      id: catalog.id,
      archived: !catalog.archived
    }

    let response = await catalogApiHelper.quickCatalogUpdate(data)
    await this.loadCatalogs()
  }

  async toggleEditForm() {
    await this.loadCatalogs()
    this.setState({
      formRecord: [],
      editFormOpen: false
    })
  }

  togglePageManagement() {
    this.setState({
      pageManagementOpen: !this.state.pageManagementOpen
    })
  }

 render() {
    if(!this.state.loaded) {
      return (<LoadingSection style={{marginTop: '20%'}}/>)
    } else if (this.state.viewCatalogId != '') {
      return(
        <div style={{height: '100vh'}}>
            <ViewCatalog
              catalogId={this.state.viewCatalogId}
              generatePdf={this.generatePdf}
              closeCatalog={() => this.setState({viewCatalogId: ''})}
            />
        </div>
      )
    } else if (this.state.editFormOpen) {
      return(
        <CreateCatalog
          formRecord={this.state.formRecord}
          updateTab={this.toggleEditForm}
          backTab={this.toggleEditForm}
          allCustomers={this.state.customers}
          goToCatalogView={this.goToCatalogView}
          viewCatalog={this.goToCatalogView}
        />
      )
    }
    return (
      <div>
        {this.state.deleteCatalogFormOpen &&
          <DeleteCatalogForm
            catalog={this.state.deleteCatalog}
            toggleDeleteCatalog={this.toggleDeleteCatalog}
            toggleArchiveCatalog={this.toggleArchiveCatalog}
            loadCatalogs={this.loadCatalogs}
          />
        }
        {this.state.pageManagementOpen &&
          <CatalogPagesContainer
            onClose={this.togglePageManagement}
          />
        }
        <div className="pageHeader">
          <h2>Catalogs</h2>
          <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <AddRecordButton
              icon={true}
              text="Add Catalog"
              onClick={() => this.props.updateTab(6)}
            />
            {this.props.user.role_id > 2 &&
              <p
                onClick={this.togglePageManagement}
                style={{
                  marginTop: 10
                }}
                className="button_link"
                >Manage Catalog Pages</p>}
          </div>
        </div>
        <CatalogTable
          generateCatalog={this.generateCatalog}
          catalogs={this.state.catalogs}
          customers={this.state.customers}
          editRecord={this.editRecord}
          toggleArchiveCatalog={this.toggleArchiveCatalog}
          toggleDeleteCatalog={this.toggleDeleteCatalog}
          viewCatalog={this.viewCatalog}
          unpublishCatalog={this.unpublishCatalog}
          user={this.props.user}
        />
      </div>
    );
  }
}


export default CatalogContainer
