import React from 'react';
import { Page, Text, View, Image } from '@react-pdf/renderer';
import moment from 'moment'


export default React.memo(DistributorLogos, (props, nextProps) => {
  return !nextProps.loaded
})


function DistributorLogos(props)  {

  const renderLogos = () => {
    return props.catalog.distributors.map((distributor, i) => {
      if(distributor.logo_url != null) {
        return (
          <Image
            key={i}
            style={{
              maxHeight: 300,
              maxWidth: 300,
              objectFit: 'cover',
              padding: 10,
              marginBottom: 30
            }}
            alt="images"
            src={distributor.logo_url} />
        )
      }
      return null
    })
  }

  const { first_name, last_name, email, phone } = props.user
  return(
      <Page size="A4"
        style={{
          flexDirection: 'row',
          backgroundColor: '#fff',
          width: '100%',
          orientation: 'portrait',
          backgroundColor: 'white'
        }}>
          <View style={{
            width: '100%',
            height: '100%',
            backgroundColor: '#FFFFFF',
            display: 'flex',
            paddingLeft: 30,
          }}>
          {renderLogos()}
          </View>
      </Page>
    )
}
