import React, { useState } from "react";
import { usePDF } from '@react-pdf/renderer';
import { Document, Page, Outline } from 'react-pdf/dist/esm/entry.webpack';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons'
import LoadingSection from '../../utils/LoadingSection'


const ScrollPublishedCatalog = (props) => {
  const [instance, updateInstance] = useState(props.doc)
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const pageNumberText = function(number) {
    if(number > 2 && number <= numPages) {
      return `Page ${number-2} of ${numPages-2}`
    } else if (number === 2) {
      return 'Inside cover'
    } else if (number === 1) {
      return 'Cover page'
    } else  {
      return ''
    }
  }

  const secondPageNumber = pageNumber

  const handleClick = function(newPageNumber) {
    if(newPageNumber < numPages+2 && newPageNumber > 0) {
      setPageNumber(newPageNumber)
    } else {
      return null
    }
  }

  function onDocumentLoadSuccess({numPages}) {
    setNumPages(numPages);
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: '3%',
        marginTop: '10%',
        width: '60%'
      }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'row',
            alignItems: 'center',
            width: '100%'
          }}>
          <div
            onClick={() => handleClick(pageNumber-2)}
            className={pageNumber-1 < 1 ? "iconButtonOutline disabled" : "iconButtonOutline"}
          >
            <FontAwesomeIcon icon={faArrowLeft} />
          </div>

          <div>
            <Document
              file={instance}
              onLoadSuccess={onDocumentLoadSuccess}
            >
              {pageNumber-1 > 0 && <Page pageNumber={pageNumber-1} />}
              {pageNumber <= numPages && <Page pageNumber={pageNumber} />}
            </Document>
            <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
              {pageNumber-1 > 0 && <p style={{color: '#D4D4D7', width: '21vw', textAlign: 'center'}}>{pageNumberText(pageNumber-1)}</p>}
              <p style={{color: '#D4D4D7', float: 'right', width: '21vw', textAlign: 'center'}}>{pageNumberText(pageNumber)}</p>
            </div>
          </div>

          <div
            onClick={() => handleClick(pageNumber+2)}
            className={pageNumber > numPages ? "iconButtonOutline disabled" : "iconButtonOutline"}
            >
            <FontAwesomeIcon icon={faArrowRight} />
          </div>
        </div>
    </div>
  );
}


export default ScrollPublishedCatalog
