import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'

function ToggleBrand(props) {

  const [isToggled, setIsToggled] = useState(false);
  const toggle = () => setIsToggled(!isToggled);

  const toggleProduct = function(product) {
    const brandName = props.filter[0].value
    toggle()
    isToggled ? props.removeBrandFromCatalog(brandName) : props.addBrandToCatalog(brandName)
  }

  return (
    <div
      key={props.filter[0].value}
      style={{
        alignItems: 'center',
        border: '1px solid rgb(0, 90, 195)',
        padding: '5px 10px',
        borderRadius: 30,
        marginBottom: 20,
      }}
      className="table_action_column"
      onClick={toggleProduct}>
        <p>{isToggled ? `Entire ${props.filter[0].value} brand added` : `Add entire ${props.filter[0].value} brand`}</p>
        <FontAwesomeIcon
          icon={faCheck}
          style={{
            color: isToggled ? '#FFFFFF' : '#005AC3',
            backgroundColor: !isToggled ? '#FFFFFF' : '#005AC3',
            fontSize: 12,
            padding: 5,
            borderRadius: 30,
            border: "1px solid #005AC3",
            marginLeft: 10,
          }}
        />
    </div>
  )
}

export default ToggleBrand
