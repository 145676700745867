import React from 'react';
import { setCustomFilter } from 'react-table';
import TextInput from '../forms/TextInput'

function SkuFilter({
  setCustomFilter,
  filters,
  brands
  }) {

  const setFilter = function(attribute, value) {
      setCustomFilter("skus", value)
  }

  const value = function() {
    let existingFilter = filters.filter(obj=>obj.id==="skus");
    return existingFilter.length == 0 ?
      ""
      : existingFilter[0].value
  }

  return (
    <span className="brandFilters">
      <TextInput
        attribute="skus"
        value={value()}
        label="Part Number"
        optionName={"name"}
        label="Sku"
        handleChange={setFilter}
        placeholder="Select option"
      />
    </span>
  )
}

export default SkuFilter
