import React from "react"
import PropTypes from "prop-types"
import FormContainer from '../../forms/FormContainer'
import DropdownSelect from '../../forms/DropdownSelect'
import SingleSelectInput from '../../forms/SingleSelectInput'

import AddRecordButton from '../../forms/AddRecordButton'
import FileDropzone from '../../forms/FileDropzone'
import productApiHelper from '../../utils/productApiHelper'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'


class MutliProductImage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      allProducts: [],
      selectedProducts: [],
      grit: [],
      shape: [],
      file: null,
      formSubmitting: false,
      brandGrits: [],
      selectBrandGrits: [],
      brandShapes: [],
    }

    this.submitForm = this.submitForm.bind(this)
    this.setSearchValue = this.setSearchValue.bind(this)
    this.updateProducts = this.updateProducts.bind(this)
    this.previewProducts = this.previewProducts.bind(this)
    this.updateFile = this.updateFile.bind(this)
    this.removeImage = this.removeImage.bind(this)
    this.removeProduct = this.removeProduct.bind(this)
  }

  async componentDidMount() {
    let data = {
      brand_id: this.props.products[0].brand_id
    }
    let response = await productApiHelper.getBrandShapeAndGrits(data)

    this.setState({
      brandGrits: JSON.parse(response.data.grits),
      selectBrandGrits: JSON.parse(response.data.grits),
      brandShapes: JSON.parse(response.data.shapes),
      allProducts: this.props.products,
      allShapes: this.props.shapes,
      allGrits: this.props.grits
    })
  }

  setSearchValue(attribute, value) {
    if(value === undefined) {
      this.setState({
        [attribute]: []
      }, () => this.updateProducts())
    } else {
      this.setState({
        [attribute]: value
      }, () => this.updateProducts())
    }

  }

  updateFile(file) {
    this.setState({
      file: file
    })
  }

  removeImage() {
    this.setState({
      file: null
    })
  }

  async removeProduct(index) {
    const selectedProducts = this.state.selectedProducts
    selectedProducts.splice(index, 1)
    this.setState({
      selectedProducts: selectedProducts
    })
  }

  async submitForm() {
    this.setState({
      formSubmitting: true
    })
    const formData = new FormData()
    formData.append('products', JSON.stringify(this.state.selectedProducts))

    formData.append("product_image_url", this.state.file)
    await productApiHelper.updateProductImage(formData)
    this.props.updateProductImages()
  }

  async updateProducts() {
    if ('id' in this.state.shape) {
      const selectedProducts =
       ('id' in this.state.grit) ?
        await this.state.allProducts.filter(product => (product.grit_id === this.state.grit.id) && (product.shape_id === this.state.shape.id))
        : await this.state.allProducts.filter(product => product.shape_id === this.state.shape.id)
      this.setState({
        selectedProducts: selectedProducts
      }, () => this.filterGrits())
    } else {
      this.setState({
        selectBrandGrits: this.state.brandGrits
      })
    }
  }

  async filterGrits() {
    const grits = await this.state.selectedProducts.map(product => {
        return product['grit']
    })
    const unique = [];
    grits.filter(function(item){
      const i = unique.findIndex(x => (x.name == item.name));
      if(i <= -1){
        unique.push(item);
      }
    });

    this.setState({
      selectBrandGrits: unique
    })
  }

  previewProducts() {
    if(this.state.selectedProducts.length === 0) {
      return (
        <tr>
          <td></td>
          <td>No products selected</td>
          <td></td>
        </tr>)
    }
    return this.state.selectedProducts.map((product, index) => {
      return (
        <tr key={index}>
          <td><img src={product.product_image_url} style={{maxHeight: 50}} /></td>
          <td>{product.name}</td>
          <td onClick={() => this.removeProduct(index)}><FontAwesomeIcon
            icon={faTimes}
            style={{
              color: '#D79B81',
              cursor: 'pointer',
              float: 'right'
            }}
            /></td>
        </tr>)
    })
  }

  render() {
    return (
      <FormContainer
        onClose={this.props.onClose}
      >
        <h1>Upload 1 image for multiple products</h1>
        <SingleSelectInput
          label="Head Shape"
          options={this.state.brandShapes}
          selected={this.state.shape.name}
          attribute="shape"
          create={false}
          optionName={"name"}
          placeholder="Select option"
          updateValue={this.setSearchValue}
        />
        {this.state.selectBrandGrits.length > 0 && <SingleSelectInput
          label="Grit"
          options={this.state.selectBrandGrits}
          selected={this.state.grit.name}
          attribute="grit"
          create={false}
          optionName={"name"}
          placeholder="Select option"
          updateValue={this.setSearchValue}
        />}
        <FileDropzone
          label="Product Image"
          prompt="Click or drag to upload your product image"
          updateFile={this.updateFile}
          removeImage={this.removeImage}
          file={this.state.file}
        />
        <table className="tableStandard">
        <tbody>
          {this.previewProducts()}
        </tbody>
        </table>
        <div style={{marginTop: 15}}>
        <AddRecordButton
          text={this.state.formSubmitting ? "Saving Images..." : "Add Image to Products"}
          disabled={this.state.formSubmitting === true ? true : (this.state.selectedProducts.length === 0 || this.state.file === null) }
          onClick={this.submitForm}
        />
        </div>
      </FormContainer>
    )
  }
}


export default MutliProductImage
