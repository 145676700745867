import React from "react"
import PropTypes from "prop-types"

import AddRecordButton from '../forms/AddRecordButton'
import BrandForm from '../products/BrandForm'
import ProductsTable from '../products/ProductsTable'
import productApiHelper from '../utils/productApiHelper'
import BulkUpload from './BulkUpload';
import DeleteProductForm from './DeleteProductForm'
import LoadingSection from '../utils/LoadingSection'
import ManageCategories from './form_sections/ManageCategories'
import ManageBrandsWithoutProducts from './form_sections/ManageBrandsWithoutProducts'
import UpdateBrand from './table_sections/UpdateBrand'

class ProductsContainer extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      individualFormOpen: false,
      brands: [],
      allBrands: [],
      products: [],
      totalPages: '',
      loadProductsOffset: 0,
      shapes: [],
      grits: [],
      shanks: [],
      individualFormRecordId: null,
      businessUnits: [],
      categories: [],
      distributors: [],
      configuration_types: [],
      loaded: false,
      buttonMenuOpen: false,
      bulkUploadOpen: false,
      deleteProduct: [],
      deleteProductFormOpen: false,
      manageCategoriesOpen: false,
      manageBrandsWithoutProductsOpen: false,
      productsSelected: [],
      updateBrandOpen: false,
      searchParams: {
        globalFilter: '',
        businessUnit: '',
        category: '',
        brand: '',
        archived: 'false',
        page: 1
      }
    }

    this.loadProducts = this.loadProducts.bind(this)
    this.toggleIndividualForm = this.toggleIndividualForm.bind(this)
    this.toggleMenu = this.toggleMenu.bind(this)
    this.editRecord = this.editRecord.bind(this)
    this.createDropdownMenu = this.createDropdownMenu.bind(this)
    this.toggleArchiveProduct = this.toggleArchiveProduct.bind(this)
    this.toggleDeleteProduct = this.toggleDeleteProduct.bind(this)
    this.toggleBulkUpload = this.toggleBulkUpload.bind(this)
    this.updateState = this.updateState.bind(this)
    this.loadFilters = this.loadFilters.bind(this)
    this.updateSearchParams = this.updateSearchParams.bind(this)
    this.clearFilters = this.clearFilters.bind(this)
    this.toggleManageCategories = this.toggleManageCategories.bind(this)
    this.toggleManageBrands = this.toggleManageBrands.bind(this)
    this.toggleProductSelect = this.toggleProductSelect.bind(this)
    this.toggleChangeBrand = this.toggleChangeBrand.bind(this)
  }

  componentDidMount() {
    this.loadProducts()
  }

  componentWillUnmount() {
    this.setState({
      loaded: false
    })
  }

  clearFilters() {
    this.setState({
      searchParams: {
        globalFilter: '',
        businessUnit: '',
        category: '',
        brand: '',
        archived: 'false',
        page: 1
      }
    }, () => this.loadProducts())
  }

  toggleManageCategories() {
    this.loadFilters()
    this.setState({
      manageCategoriesOpen: !this.state.manageCategoriesOpen
    })
  }

  toggleManageBrands() {
    this.setState({
      manageBrandsWithoutProductsOpen: !this.state.manageBrandsWithoutProductsOpen
    })
  }

  updateState(attribute, value) {
    this.setState({
      [attribute]: value
    })
  }

  async loadProducts() {
    this.loadFilters()
    let data = {
      offset: this.state.loadProductsOffset,
      searchParams: this.state.searchParams
    }
    let response = await productApiHelper.loadProducts(data)
    this.setState({
      products: JSON.parse(response.data.products),
      totalPages: JSON.parse(response.data.totalPages),
      loaded: true,
      deleteProductFormOpen: false
    })

  }

  async loadFilters() {
    //send serach filters to re-load filters
    let data = {
      searchParams: this.state.searchParams
    }
    let response = await productApiHelper.loadFilters(data)
    this.setState({
      allBrands: JSON.parse(response.data.allBrands),
      businessUnits: JSON.parse(response.data.businessUnits),
      categories: JSON.parse(response.data.categories),
      distributors: JSON.parse(response.data.distributors),
      brands: JSON.parse(response.data.brands),
      shapes: JSON.parse(response.data.shapes),
      grits: JSON.parse(response.data.grits),
      shanks: JSON.parse(response.data.shanks),
      configuration_types: JSON.parse(response.data.configuration_types),
    })
  }

  async toggleArchiveProduct(product) {
    let data = {
      id: product.id,
      archived: !product.archived
    }

    let response = await productApiHelper.quickProductUpdate(data)
    await this.loadProducts()
  }

  toggleDeleteProduct(product) {
    this.setState({
      deleteProduct: product,
      deleteProductFormOpen: !this.state.deleteProductFormOpen,
    })
  }

  toggleIndividualForm() {
    this.setState({
      individualFormOpen: !this.state.individualFormOpen,
      individualFormRecordId: null,
    }, () => this.loadProducts())
  }

  async updateSearchParams(updatedParam) {
    const searchParams = 'page' in updatedParam ?
      {...this.state.searchParams, ...updatedParam}
      : {...this.state.searchParams, ...updatedParam, page: 1}
    await this.setState({
      searchParams: searchParams
    })
    await this.loadProducts()
  }

  async editRecord(record) {
    this.setState({
      individualFormOpen: !this.state.individualFormOpen,
      individualFormRecordId: record.brand_id,
      buttonMenuOpen: false
    })
  }

  toggleMenu() {
    this.setState({
      buttonMenuOpen: !this.state.buttonMenuOpen
    })
  }

  toggleBulkUpload() {
    this.setState({
      bulkUploadOpen: !this.state.bulkUploadOpen,
      buttonMenuOpen: false
    })
  }

  toggleProductSelect(productId) {
    const productsSelected = this.state.productsSelected.includes(productId) ?
      this.state.productsSelected.filter((item) => item != productId) :
      [...this.state.productsSelected, productId]

    this.setState({
      productsSelected: productsSelected
    })
  }

  toggleChangeBrand() {
    this.setState({
      updateBrandOpen: !this.state.updateBrandOpen
    })
  }

  createDropdownMenu() {
    if(!this.state.buttonMenuOpen) { return null }
    return(
      <div className="button_dropdown_menu">
        <p onClick={() => this.props.updateTab(6)}>Create Catalog</p>
        {this.props.user.role_id > 2 &&
          <p onClick={this.toggleIndividualForm}>Add Individual Product</p>}
        {this.props.user.role_id > 2 &&
          <p onClick={() => this.toggleBulkUpload()}>Upload CSV</p>}
      </div>
    )
  }

 render() {
    if(!this.state.loaded) {
      return (<LoadingSection style={{marginTop: '20%'}}/>)
    }
    return (
      <div>
        {this.state.deleteProductFormOpen &&
          <DeleteProductForm
            product={this.state.deleteProduct}
            toggleDeleteProduct={this.toggleDeleteProduct}
            toggleArchiveProduct={this.toggleArchiveProduct}
            loadProducts={this.loadProducts}
          />
        }

        {this.state.individualFormOpen &&
          <BrandForm
            loadProducts={this.loadProducts}
            toggleForm={this.toggleIndividualForm}
            individualFormRecordId={this.state.individualFormRecordId}
            businessUnits={this.state.businessUnits}
            categories={this.state.categories}
            distributors={this.state.distributors}
            configuration_types={this.state.configuration_types}
            brands={this.state.allBrands}
            shapes={this.state.shapes}
            grits={this.state.grits}
            shanks={this.state.shanks}
            updateState={this.updateState}
          />}
        {this.state.bulkUploadOpen &&
          <BulkUpload
            closeBulkUpload={this.toggleBulkUpload}
            loadProducts={this.loadProducts}
          />}
        {this.state.manageCategoriesOpen &&
          <ManageCategories
            categories={this.state.categories}
            toggleManageCategories={this.toggleManageCategories}
            updateCategories={(categories) => this.setState({categories: categories})}
          />
        }
        {this.state.manageBrandsWithoutProductsOpen &&
          <ManageBrandsWithoutProducts
            toggleManageBrands={this.toggleManageBrands}
          />
        }
        {this.state.updateBrandOpen &&
          <UpdateBrand
            brands={this.state.allBrands}
            toggleChangeBrand={this.toggleChangeBrand}
            productIds={this.state.productsSelected}
            loadProducts={this.loadProducts}
          />
        }
        <div className="pageHeader">
          <h2>Products</h2>
          <AddRecordButton
            icon={true}
            text="Create"
            onClick={this.toggleMenu}
            dropdown={this.createDropdownMenu()}
          />
        </div>
          <ProductsTable
            products={this.state.products}
            totalPages={this.state.totalPages}
            searchParams={this.state.searchParams}
            updateSearchParams={this.updateSearchParams}
            editRecord={this.editRecord}
            categories={this.state.categories}
            brands={this.state.brands}
            user={this.props.user}
            toggleArchiveProduct={this.toggleArchiveProduct}
            toggleDeleteProduct={this.toggleDeleteProduct}
            clearFilters={this.clearFilters}
            toggleManageCategories={this.toggleManageCategories}
            toggleManageBrands={this.toggleManageBrands}
            toggleProductSelect={this.toggleProductSelect}
            productsSelected={this.state.productsSelected}
            toggleChangeBrand={this.toggleChangeBrand}
          />
      </div>
    );
  }
}


export default ProductsContainer
