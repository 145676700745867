import React from "react"
import PropTypes from "prop-types"

const getDigitOnlyPhone = value =>
  value.toString().replace(/\D/g, '').substring(0, 10)


const updateValue = function(attribute, value, props) {
   if (!value) return ''

   const phone = getDigitOnlyPhone(value)
   const areaCode = phone.substring(0, 3)
   const middle = phone.substring(3, 6)
   const last = phone.substring(6, 10)

   let formattedPhone = ''
   if (phone.length > 6) {
     formattedPhone = `(${areaCode}) ${middle} - ${last}`
   } else if (phone.length > 3) {
     formattedPhone = `(${areaCode}) ${middle}`
   } else if (phone.length > 0) {
     formattedPhone = `(${areaCode}`
   }

    props.handleChange(attribute, formattedPhone)

}

const PhoneInputField = props => (
  <div style={{marginTop: 20, marginBottom: 20}}>
  <h6 className="inputLabel">{props.label}</h6>
  <input
    style={props.style}
    placeholder={props.placeholder}
    className="inputField"
    disabled={props.disabled}
    autoFocus={props.autofocus ? "autofocus" : ""}
    value={props.value}
    onChange={(event) => updateValue(props.attribute, event.target.value, props)}
  ></input>
  </div>
)


export default PhoneInputField;
