import React from "react";
import PropTypes from "prop-types";
import AddRecordButton from '../../forms/AddRecordButton';
import TextInput from '../../forms/TextInput';
import DateInput from '../../forms/DateInput';
import SelectInput from '../../forms/SelectInput';
import SingleSelectInput from '../../forms/SingleSelectInput';
import CustomerForm from '../../customers/CustomerForm'
import moment from 'moment'

class CatalogForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      customerFormOpen: false,
      id: '',
      name: '',
      distributors: [],
      customer: undefined,
      formComplete: false,
      date: new Date(),
      customerForm: {
        id: '',
        name: '',
        notes: '',
        image: null,
        distributor_id: '',
        distributor: {
          id: '',
          name: ''
        },
      }
    }

    this.updateValue = this.updateValue.bind(this)
    this.setCustomer = this.setCustomer.bind(this)
    this.toggleCustomerForm = this.toggleCustomerForm.bind(this)
    this.submitForm = this.submitForm.bind(this)
    this.createNew = this.createNew.bind(this)
  }

  componentDidMount() {
    if(this.props.formRecord && "id" in this.props.formRecord) {
      this.setState({
        id: this.props.formRecord.id,
        name: this.props.formRecord.name,
        date: new Date(this.props.formRecord.date),
        distributors: this.props.formRecord.distributors,
        customer: this.props.formRecord.customer,
      })
    }
  }

  checkFormComplete() {
    this.setState({
      formComplete: (this.state.name != '' && this.state.distributors.length > 0 && this.state.customer != undefined && this.state.date != '')
    })
  }

  toggleCustomerForm() {
    this.setState({
      customerFormOpen: false
    })
  }

  async setCustomer(name) {
    await this.props.loadCustomers()
    this.props.allCustomers.map((customer, i) => {
      if(customer.name == name) {
        this.setState({
          customer: customer,
        }, () => this.checkFormComplete())
      }
    })
  }

  createNew(attribute, value) {
        this.setState({
          customerFormOpen: true,
          customer: undefined,
          customerForm: {...this.state.customerForm, name: value.name}
        }, () => this.checkFormComplete())

  }

  updateValue(attribute, value) {
    if(attribute === 'customer') {
      if(value === undefined || value.length === 0) {
        this.setState({
          customer: undefined,
        }, () => this.checkFormComplete())
      } else if(typeof value.id === 'string' || value.id === 0) {
        this.setState({
          customerFormOpen: true,
          customer: undefined,
          customerForm: {...this.state.customerForm, name: (value.id === 0 ? '' : value.name)}
        }, () => this.checkFormComplete())
      } else {
        this.setState({
          customer: value,
        }, () => this.checkFormComplete())
      }
    } else if(attribute === 'distributors') {
      this.setState({
        distributors: value
      }, () => this.checkFormComplete())
    } else {
      this.setState({
        [attribute]: value
      }, () => this.checkFormComplete())
    }

  }

  submitForm() {
    let data = {
      id: this.state.id,
      name: this.state.name,
      distributors: this.state.distributors,
      customer: this.state.customer,
      date: this.state.date
    }

    this.props.createOrUpdate(data)
  }

 render() {
    const { allCustomers } = this.props

    if(allCustomers.length === 0 || allCustomers[0].id != 0) {
      allCustomers.unshift({name: 'Create new customer', id: 0})
    }
    return (

      <div className="create_catalog_form">
          {this.state.customerFormOpen &&
            <CustomerForm
              loadCustomers={this.props.loadCustomers}
              toggleForm={this.toggleCustomerForm}
              formRecord={this.state.customerForm}
              distributors={this.props.allDistributors}
              setCustomer={this.setCustomer}
            />}
          <TextInput
            label="Catalog Name"
            placeholder="Name of catalog"
            autofocus={true}
            value={this.state.name}
            attribute="name"
            handleChange={this.updateValue}
          />
          <SingleSelectInput
            options={allCustomers}
            searchBy="name"
            labelField="name"
            valueField="id"
            attribute={'customer'}
            updateValue={this.updateValue}
            createNew={this.createNew}
            selected={this.state.customer}
            label="Customer"
            create={true}
          />
          <SelectInput
            options={this.props.allDistributors}
            searchBy="name"
            labelField="name"
            valueField="id"
            attribute={'distributors'}
            updateValue={this.updateValue}
            selected={this.state.distributors}
            label="Dealer"
            create={false}
            multi={true}
          />
          <DateInput
            label="Date"
            date={this.state.date}
            attribute="date"
            updateValue={this.updateValue}
          />
          <AddRecordButton
            icon={false}
            text="Continue"
            disabled={!this.state.formComplete}
            onClick={this.submitForm}
          />
      </div>
    );
  }
}


export default CatalogForm
