import React from "react"
import PropTypes from "prop-types"

import FormContainer from '../forms/FormContainer'
import AddRecordButton from '../forms/AddRecordButton'
import TextInput from '../forms/TextInput'
import distributorApiHelper from '../utils/distributorApiHelper'
import FileDropzone from '../forms/FileDropzone'

class DistributorForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
        id: '',
        name: '',
        logo_url: '',
        disabled: true,
    }

    this.updateValue = this.updateValue.bind(this)
    this.submitForm = this.submitForm.bind(this)
    this.updateFile = this.updateFile.bind(this)
    this.removeImage = this.removeImage.bind(this)
    this.isDisabled = this.isDisabled.bind(this)
  }

  componentDidMount() {
    if("id" in this.props.formRecord) {
      this.setState({
        id: this.props.formRecord.id,
        name: this.props.formRecord.name,
        logo_url: this.props.formRecord.logo_url
      })
    }
  }

  isDisabled() {
    this.setState({
      disabled: this.state.name.length < 3
    })
  }

  updateValue(attribute, value) {
    this.setState({
      [attribute]: value,
    }, () => this.isDisabled())
  }

  updateFile(file) {
    this.setState({
      logo_url: file
    }, () => this.isDisabled())
  }

  removeImage() {
    this.setState({
      logo_url: null
    }, () => this.isDisabled())
  }

   async submitForm() {
     const formData = new FormData()
     formData.append("id", this.state.id)
     formData.append("name", this.state.name)

     if (typeof this.state.logo_url === "object") {
       this.state.logo_url === null ?
         formData.append('logo', "null")
         : formData.append('logo', this.state.logo_url, 'image')
     }

     if(!this.state.disabled) {
       await distributorApiHelper.createOrUpdate(formData)
       await this.props.loadDistributors()
       await this.props.toggleForm()
     }
   }

   render() {
      return (
        <FormContainer
          title="Dealer"
          onClose={this.props.toggleForm}
        >
          <TextInput
            label="Dealer Name"
            placeholder="Name of dealer"
            value={this.state.name}
            attribute={"name"}
            handleChange={this.updateValue}
          />
          <FileDropzone
            label="Dealer Logo"
            prompt="Click or drag to upload the dealer logo"
            updateFile={this.updateFile}
            removeImage={this.removeImage}
            file={this.state.logo_url}
          />
          <div style={{display: 'flex', justifyContent: 'flex-end'}}>
            <AddRecordButton
              text={this.state.id === '' ? "Create" : "Update"}
              disabled={this.state.disabled}
              onClick={this.submitForm}
            />
          </div>
        </FormContainer>
      );
    }
}


export default DistributorForm
