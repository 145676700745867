import React from "react"
import PropTypes from "prop-types"

const LoadingSection = props => (
  <div
    style={{
      width: '100%',
      height: '100%',
      margin: 'auto',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      ...props.style
    }}>
        <div className="loader" style={{width: 200, height: 200, margin: 'auto'}}>
        </div>
  </div>
)

export default LoadingSection;
