import React, {useState} from "react"
import PropTypes from "prop-types"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowUp,
  faArrowDown,
  faPen,
  faEllipsisH,
  faArchive,
  faSlidersH,
  faTrash,
  faCheck,
  faTimes
} from '@fortawesome/free-solid-svg-icons'
import GlobalFilter from '../tables/GlobalFilter'
import ArchivedFilter from '../tables/ArchivedFilter'


import {
  useTable,
  useFilters,
  useGroupBy,
  useSortBy,
  useExpanded,
  usePagination,
  useGlobalFilter
} from 'react-table'

  function UsersTable(props) {
    const data = React.useMemo(
      () => props.users
    )

    const [subFilterIsToggled, setSubFilterIsToggled] = useState(false);
    const toggleSubFilter = () => setSubFilterIsToggled(!subFilterIsToggled);

    const [menuOpen, toggleMenuOpen] = useState('');
    const toggleMenu = React.useCallback((user) => toggleMenuOpen(menuOpen => user === menuOpen ? '' : user));

    const elipsisMenu = function(menuOpen, user) {
      if(menuOpen === user){
      return (
        <div className="button_dropdown_menu" style={{width: 150}}>
          <div className="button_dropdown_item">
            <FontAwesomeIcon
              icon={faArchive}
              style={{
                color: '#005AC3',
                marginLeft: 5,
                padding: 8,
                borderRadius: 4,
              }}
              />
            <p onClick={() => props.toggleArchiveUser(user)}>{user.archived ? 'Unarchive' : 'Archive'}</p>
          </div>
        </div>
      )}
      else {return null}
    }

    const columns = React.useMemo(
     () => [
       {
         Header: 'Name',
         id: 'name',
         accessor: row => `${row.first_name} ${row.last_name}`,
       },
       {
         Header: 'Email',
         accessor: 'email', // accessor is the "key" in the data
       },
       {
         Header: 'Catalog Count',
         accessor: 'catalog_count', // accessor is the "key" in the data
       },
       {
         Header: 'Role',
         accessor: 'role.name', // accessor is the "key" in the data
       },
       {
         Header: 'Archived',
         accessor: 'archived', // accessor is the "key" in the data
       },
       {
          Header: "Actions",
          Cell: ({ cell }) => (
            <div>
            {!cell.row.original.approved &&
              <div className="table_action_column">
                <FontAwesomeIcon
                  icon={faCheck}
                  onClick={() => props.approveUser(cell.row.original)}
                  style={{
                    color: '#10C300',
                    border: '1px solid #EEEEEE',
                    marginLeft: 5,
                    padding: 8,
                    borderRadius: 4,
                  }}
                  />
                <FontAwesomeIcon
                  icon={faTimes}
                  onClick={() => props.declineUser(cell.row.original)}
                  style={{
                    color: '#DD3500',
                    marginLeft: 5,
                    padding: 8,
                    borderRadius: 4,
                  }}
                  />
              </div>
            }
            {cell.row.original.approved &&
              <div className="table_action_column">
                <FontAwesomeIcon
                  icon={faPen}
                  onClick={() => props.editRecord(cell.row.original)}
                  style={{
                    color: '#005AC3',
                    border: '1px solid #EEEEEE',
                    marginLeft: 5,
                    padding: 8,
                    borderRadius: 4,
                  }}
                  />
                  <FontAwesomeIcon
                    icon={faEllipsisH}
                    onClick={() => toggleMenu(cell.row.original)}
                    style={{
                      color: '#005AC3',
                      marginLeft: 5,
                      padding: 8,
                      borderRadius: 4,
                    }}
                    />
                  {elipsisMenu(menuOpen, cell.row.original)}
              </div>}
            </div>

          )
        }
     ],
     [menuOpen]
   )

    const tableInstance = useTable(
      {
        columns,
        data,
        initialState: {
          hiddenColumns: ['archived'],
          filters: [{id: "archived", value: "false"}]
        },
      },
      useGlobalFilter,
      useFilters,
      useGroupBy,
      useSortBy,
      useExpanded,
      usePagination,
    )

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
      preGlobalFilteredRows,
      setGlobalFilter,
      state,
      setFilter,
      setHiddenColumns
    } = tableInstance

    const setCustomFilter = function(accessor, value) {
      setFilter(accessor, value)
    }

    const rowStyle = function(row) {
      if(row.original.archived){
        return "trArchived"
      }
      return "trStandard"
    }

    return(
      <div style={{width: '100%'}}>
        <div className="tableFilterSection" >

          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', margin: 20}}>
            <GlobalFilter
              preGlobalFilteredRows={preGlobalFilteredRows}
              globalFilter={state.globalFilter}
              setGlobalFilter={setGlobalFilter}
              placeholder="Find a user"
            />
            <FontAwesomeIcon
              icon={faSlidersH}
              onClick={toggleSubFilter}
              style={{
                color: subFilterIsToggled ? '#14467C' : 'white' ,
                backgroundColor: subFilterIsToggled ? 'white' : '#14467C',
                marginLeft: 5,
                padding: 10,
                borderRadius: 10,
                cursor: 'pointer',
                border: '#14467C 2px solid' ,
              }}
              />
          </div>
          {subFilterIsToggled && <div style={{display: 'flex', flexDirection: 'row', alignItems: 'flex-start', margin: 20}}>
            <ArchivedFilter
              setCustomFilter={setCustomFilter}
              filters={state.filters}
            />
          </div>}
        </div>
        <table {...getTableProps()} className="tableStandard">
          <thead>
            {// Loop over the header rows
            headerGroups.map(headerGroup => (
              // Apply the header row props
              <tr {...headerGroup.getHeaderGroupProps()}>
                {// Loop over the headers in each row
                headerGroup.headers.map(column => (
                  // Apply the header cell props
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {// Render the header
                    column.render('Header')}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? <FontAwesomeIcon
                            icon={faArrowUp}
                            style={{
                              color: '#D4D4D7',
                              marginLeft: 5,
                            }}
                            />
                          : <FontAwesomeIcon
                            icon={faArrowDown}
                            style={{
                              color: '#D4D4D7',
                              marginLeft: 5,
                            }}
                            />
                        : ''}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          {/* Apply the table body props */}
          <tbody {...getTableBodyProps()}>
            {// Loop over the table rows
            rows.map(row => {
              // Prepare the row for display
              prepareRow(row)
              return (
                // Apply the row props
                <tr {...row.getRowProps()} className={rowStyle(row)}>
                  {// Loop over the rows cells
                  row.cells.map(cell => {
                    // Apply the cell props
                    return (
                      <td {...cell.getCellProps()}>
                        {// Render the cell contents
                        cell.render('Cell')}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    )
  }









export default UsersTable
