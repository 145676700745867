import React from "react"
import PropTypes from "prop-types"
import FormContainer from '../../forms/FormContainer'
import TextInput from '../../forms/TextInput'
import AddRecordButton from '../../forms/AddRecordButton'
import productApiHelper from '../../utils/productApiHelper'
import SingleSelectInput from '../../forms/SingleSelectInput'

class UpdateBrand extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedBrand: undefined
    }

    this.selectBrand = this.selectBrand.bind(this)
    this.updateBrand = this.updateBrand.bind(this)
  }

  selectBrand(attribute, value) {
    this.setState({
      selectedBrand: value
    })
  }

  async updateBrand() {
    let data = {
      productIds: this.props.productIds,
      brand: this.state.selectedBrand
    }
    let response = await productApiHelper.updateProductBrand(data)

    this.props.loadProducts()
    this.props.toggleChangeBrand()
  }

  render() {
    return (
      <FormContainer
        onClose={this.props.toggleChangeBrand}
      >
        <h1>Change brand</h1>

        <p style={{marginTop: 10}}>This can not be undone!</p>
        <SingleSelectInput
          options={this.props.brands}
          selected={this.state.selectedBrand}
          updateValue={this.selectBrand}
          atribute="brand"
          create={false}
        />
        <AddRecordButton
          text={"Update brands"}
          disabled={this.state.selectedBrand === undefined}
          onClick={this.updateBrand}
        />
      </FormContainer>
    )
  }
}


export default UpdateBrand
