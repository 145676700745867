import React, {useState, useEffect, useCallback} from "react"
import PropTypes from "prop-types"
import Select from "react-dropdown-select";

const SingleSelectInput = props => {
  return(
    <div style={{marginTop: 20, marginBottom: 20}}>
      <h6 className="inputLabel">{props.label}</h6>
      <Select
        options={props.options}
        searchBy="name"
        labelField="name"
        valueField="id"
        placeholder="Select or type..."
        values={props.selected != undefined ? [props.options[props.options.findIndex(item => props.selected.id === item.id)]] : []}
        onChange={(value) => props.updateValue(props.attribute, value[0])}
        create={props.create != undefined ? props.create : true}
      />
    </div>
  )
}

export default SingleSelectInput
