import React, {useCallback} from 'react'
import {useDropzone} from 'react-dropzone'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faImage, faTimesCircle } from '@fortawesome/free-solid-svg-icons'


function MultiFileDropzone(props) {
  const onDrop = useCallback(acceptedFiles => {
    // Do something with the files
    acceptedFiles.map((file, i) => {
      props.updateFile(props.record, file)
    })
  }, [])
  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})

  const previewImages = function() {
    return props.files.map((file, i) => {
      return(
        <div
          key={i}
          style={{
            height:200,
            textAlign: 'center',
            paddingRight: 20,
            marginTop: 10,
          }}>
          <FontAwesomeIcon
            icon={faTimesCircle}
            onClick={() => props.removeImage(props.record, i)}
            style={{
              color: '#A2A3A6',
              fontSize: 12,
              display: 'flex',
              marginTop: -7,
              marginLeft: -5,
              float: 'right',
              cursor: 'pointer',
              position: 'relative'
            }}
          />
          <img
            alt="Preview"
            src={(typeof file === "string") ? file : URL.createObjectURL(file) }
            className="catalogPageImagePreview"
          />
        </div>
      )
    })
  }

  const renderUploadImage = function() {
    return(
      <div {...getRootProps()}
        style={{
          width: 200,
          height: 200,
          border: '3px dashed #EEEEEE',
          boxSizing: 'border-box',
          borderRadius: 10,
          marginTop: 10,
          cursor: 'pointer'
        }}>
        <input {...getInputProps()} />
        {
          isDragActive ?
            <div style={{textAlign: 'center', marginTop: 50}}><p>Drop the files here...</p></div>
            :
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: '#A2A3A6',
                flexDirection: 'column',
                textAlign: 'center',
                padding: 15,
                marginTop: 40
              }}>
              <FontAwesomeIcon
                icon={faImage}
                style={{color: '#A2A3A6', marginBottom: 20, fontSize: 24}}
              />
              <p>{props.prompt ? `${props.prompt}` : "Drag 'n' drop an image here, or click to select images"}</p></div>
        }
      </div>
    )
  }

  return (
    <div style={{marginBottom: 40}}>
    <h6 className="inputLabel">{props.label}</h6>
    <div style={{display: 'flex', flexWrap: 'wrap'}}>
      {(props.files.length > 0) &&
        previewImages()
      }
      {renderUploadImage()}
      </div>
    </div>
  )
}

export default MultiFileDropzone
