import React from "react";
import PropTypes from "prop-types";
import LoadingSection from '../../utils/LoadingSection'
import { PDFDownloadLink, usePDF } from '@react-pdf/renderer';
import ScrollCatalog from './ScrollCatalog'
import FileSaver from 'file-saver';
import catalogApiHelper from '../../utils/catalogApiHelper'

class CatalogPreview extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loaded: false,
      catalog: {},
      business_units: [],
      brands: [],
      user: {},
      pdf: '',
      docIsLoaded: false
    }

    this.generatePdf = this.generatePdf.bind(this)
    this.getFileFromUrl = this.getFileFromUrl.bind(this)
  }


 async getFileFromUrl(url, name, defaultType = 'image/jpeg'){
    const response = await fetch(url);
    const data = await response.blob();
    return new File([data], name, {
      type: response.headers.get('content-type') || defaultType,
    });
  }

  async generatePdf(urlBlob) {
    const formData = new FormData()
    formData.append("id", this.props.catalog.id)

    const file = await this.getFileFromUrl(urlBlob, 'pdfName.pdf');
    formData.append(`pdf`, file, `${this.props.catalog.name}`)

    await catalogApiHelper.publishPDF(formData)
    .then((success) => {
      this.props.goToCatalogView(this.props.catalog.id)
    })
    .catch((error) => {
      console.log(error)
    })
  }


  render() {
    if(this.props.business_units.length < 1) {
      return (<h1></h1>)
    }

    return(
      <div style={{height: '100vh'}}>
          <ScrollCatalog
            generatePdf={this.generatePdf}
            catalog={this.props.catalog}
            brands={this.props.brands}
            business_units={this.props.business_units}
            user={this.props.user}
            sushiMenuIncluded={this.props.sushiMenuIncluded}
            catalogPages={this.props.catalogPages}
            catalogPagesIncluded={this.props.catalogPagesIncluded}
          />
      </div>
    )
  }
}

// <div style={{maxWidth: 200, maxHeight: 200, textAlign: 'center'}}>
//   <PDFDownloadLink
//     document={doc}
//     fileName={`${this.props.catalog.name}.pdf`}>
//     {({ blob, url, loading, error }) => (
//       !loading && <p className="button_primary">Download PDF</p>)}
//   </PDFDownloadLink>
// </div>

export default CatalogPreview
