import React from "react"
import PropTypes from "prop-types"
import TextInput from '../../forms/TextInput'
import Select from "react-dropdown-select";

const RotaryProduct = (props) =>  {
  return(
    <div>
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        flexDirection: 'space-between'
      }}>
        <TextInput
          label="Diameter"
          placeholder="0.0"
          value={props.product.diameter}
          attribute={"diameter"}
          handleChange={props.updateValue}
        />
        <div style={{margin: 20}}/>
        <TextInput
          label="Head Length"
          placeholder="0.0"
          value={props.product.head_length}
          attribute={"head_length"}
          handleChange={props.updateValue}
        />
        <div style={{margin: 20}}/>
        <TextInput
          label="Overall Length"
          placeholder="0.0"
          value={props.product.length}
          attribute={"length"}
          handleChange={props.updateValue}
        />
        <div style={{margin: 20}}/>
        <TextInput
          label="Flutes"
          placeholder="0"
          value={props.product.flutes}
          attribute={"flutes"}
          handleChange={props.updateValue}
        />
      </div>
      <div>
        <TextInput
          label="Quantity"
          placeholder=""
          value={props.product.quantity}
          attribute={"quantity"}
          handleChange={props.updateValue}
        />
      </div>
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap'
      }}>
          <div>
            <h6 className="inputLabel">Shape</h6>
            <Select
              options={props.shapes}
              searchBy="name"
              labelField="name"
              valueField="id"
              placeholder="Select or type..."
              values={props.product.shape != undefined ? [props.shapes[props.shapes.findIndex(item => props.product.shape.id === item.id)]] : []}
              onChange={(value) => props.updateShape('shape', value[0])}
              create={true}
            />
          </div>
          <div style={{margin: 20}}/>
          <div>
            <h6 className="inputLabel">Grit</h6>
            <Select
              options={props.grits}
              searchBy="name"
              labelField="name"
              valueField="id"
              placeholder="Select or type..."
              values={props.product.grit != undefined ? [props.grits[props.grits.findIndex(item => props.product.grit.id === item.id)]] : []}
              onChange={(value) => props.updateGrit('grit', value[0])}
              create={true}
            />
          </div>
          <div style={{margin: 20}}/>
          <div>
            <h6 className="inputLabel">Shank</h6>
            <Select
              options={props.shanks}
              searchBy="name"
              labelField="name"
              valueField="id"
              placeholder="Select or type..."
              values={props.product.shank != undefined ? [props.shanks[props.shanks.findIndex(item => props.product.shank.id === item.id)]] : []}
              onChange={(value) => props.updateShank('shank', value[0])}
              create={true}
            />
          </div>
      </div>
    </div>
  )

}

export default RotaryProduct
