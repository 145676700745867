import React from "react"
import PropTypes from "prop-types"

import FormContainer from '../../forms/FormContainer'
import AddRecordButton from '../../forms/AddRecordButton'
import TextInput from '../../forms/TextInput'
import DropdownSelect from '../../forms/DropdownSelect'
import productApiHelper from '../../utils/productApiHelper'
import FileDropzone from '../../forms/FileDropzone'
import Select from "react-dropdown-select";
import RotaryProduct from './RotaryProduct'

class ProductFormSection extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
        id: '',
        name: '',
        description: '',
        logo: '',
        configuration_type: {},
        configuration_type_image: '',
        sectionOpen: false
    }

    this.updateValue = this.updateValue.bind(this)
    this.updateConfiguration = this.updateConfiguration.bind(this)
    this.updateSku = this.updateSku.bind(this)
    this.renderDistributors = this.renderDistributors.bind(this)
    this.updateFile = this.updateFile.bind(this)
    this.removeImage = this.removeImage.bind(this)
    this.additionalDistributor = this.additionalDistributor.bind(this)
    this.addDistributor = this.addDistributor.bind(this)
    this.updateShape = this.updateShape.bind(this)
    this.updateGrit = this.updateGrit.bind(this)
    this.updateShank = this.updateShank.bind(this)
    this.updateBrandConfigurationImage = this.updateBrandConfigurationImage.bind(this)
    this.updateConfigurationImage = this.updateConfigurationImage.bind(this)
    this.removeConfigurationImage = this.removeConfigurationImage.bind(this)
    this.renderToggleProduct = this.renderToggleProduct.bind(this)
    this.toggleConfiguration = this.toggleConfiguration.bind(this)
  }

  async updateConfiguration(attribute, value) {
    const configuration = value
    if(value != undefined) {
      if(typeof value.id == 'string') {
        const data = {
          name: value.name
        }
        const response = await productApiHelper.createConfiguration(data)
        let configuration = JSON.parse(response.data)
        await this.props.updateProduct(this.props.index, 'configuration_type', configuration)
        await this.props.updateProduct(this.props.index, 'configuration_type_id', configuration.id)
      } else {
        await this.props.updateProduct(this.props.index, 'configuration_type', configuration)
        await this.props.updateProduct(this.props.index, 'configuration_type_id', configuration.id)
      }
    } else {
      await this.props.updateProduct(this.props.index, 'configuration_type', [])
      await this.props.updateProduct(this.props.index, 'configuration_type_id', null)
    }
  }

  async updateShape(attribute, value) {
    const shape = value
    if(value != undefined) {
      if(typeof value.id == 'string') {
        const data = {
          name: value.name
        }
        const response = await productApiHelper.createShape(data)
        let shape = JSON.parse(response.data)
        let newShapes = [...this.props.shapes, shape]
        this.props.updateState('shapes', newShapes)
        await this.props.updateProduct(this.props.index, 'shape', shape)
        await this.props.updateProduct(this.props.index, 'shape_id', shape.id)
      } else {
        await this.props.updateProduct(this.props.index, 'shape', shape)
        await this.props.updateProduct(this.props.index, 'shape_id', shape.id)
      }
    } else {
      await this.props.updateProduct(this.props.index, 'shape', [])
      await this.props.updateProduct(this.props.index, 'shape_id', null)
    }
  }

  async updateGrit(attribute, value) {
    const shape = value
    if(value != undefined) {
      if(typeof value.id == 'string') {
        const data = {
          name: value.name
        }
        const response = await productApiHelper.createGrit(data)
        const grit = JSON.parse(response.data)
        let newGrits = [...this.props.grits, grit]
        this.props.updateState('grits', newGrits)
        await this.props.updateProduct(this.props.index, 'grit', grit)
        await this.props.updateProduct(this.props.index, 'grit_id', grit.id)
      } else {
        await this.props.updateProduct(this.props.index, 'grit', value)
        await this.props.updateProduct(this.props.index, 'grit_id', value.id)
      }
    } else {
      await this.props.updateProduct(this.props.index, 'grit', [])
      await this.props.updateProduct(this.props.index, 'grit_id', null)
    }
  }

  async updateShank(attribute, value) {
    const shape = value
    if(value != undefined) {
      if(typeof value.id == 'string') {
        const data = {
          name: value.name
        }
        const response = await productApiHelper.createShank(data)
        const shank = JSON.parse(response.data)
        let newShanks = [...this.props.shanks, shank]
        this.props.updateState('shanks', newShanks)
        await this.props.updateProduct(this.props.index, 'shank', shank)
        await this.props.updateProduct(this.props.index, 'shank_id', shank.id)
      } else {
        await this.props.updateProduct(this.props.index, 'shank', value)
        await this.props.updateProduct(this.props.index, 'shank_id', value.id)
      }
    } else {
      await this.props.updateProduct(this.props.index, 'shank', [])
      await this.props.updateProduct(this.props.index, 'shank_id', null)
    }
  }

  updateValue(attribute, value) {
    this.props.updateProduct(this.props.index, attribute, value)
  }

  updateFile(file) {
    this.props.updateProduct(this.props.index, 'product_image_url', file)
  }

  removeImage() {
    this.props.updateProduct(this.props.index, 'product_image_url', null)
  }

  updateSku(distributor_index, sku_value) {
    //product index, sku[distributor_index], sku_value
    this.props.updateSku(this.props.index, distributor_index, sku_value)
  }

  renderDistributors() {
    const { skus } = this.props.product
    if(skus.length > 0) {
      return skus.map((sku, i) => {
        return(
          <TextInput
            key={i}
            label={sku.distributor.name}
            placeholder="Part Number"
            value={sku.sku}
            attribute={i}
            handleChange={this.updateSku}
          />
        )
      })
    } else {
      return null
    }
  }

  additionalDistributor() {
    const availableDistributors =  []
    this.props.distributors.map((item, i) => {
      const inArray = this.props.product.skus.filter(sku => sku.distributor.id === item.id)
      if(inArray.length === 0) { availableDistributors.push(item) }
    })
    if(availableDistributors.length === 0) { return null }
    return(
      <div>
        <DropdownSelect
          attribute="distributor"
          options={availableDistributors}
          optionName={"name"}
          label="New Dealer"
          placeholder="Select a new dealer"
          selectOption={this.addDistributor}
          selected={''}
        />
      </div>
    )
  }

  updateConfigurationImage(file) {
    this.updateBrandConfigurationImage(file)
    this.props.updateProduct(this.props.index, 'configuration_image', file)
  }

  removeConfigurationImage(file) {
    this.updateBrandConfigurationImage("null")
    this.props.updateProduct(this.props.index, 'configuration_image', null)
  }

  updateBrandConfigurationImage(file) {
    const formData = new FormData()
    formData.append('brand_id', this.props.brandId)
    formData.append('configuration_type_id', this.props.product.configuration_type_id)
    formData.append('image', file)
    productApiHelper.updateBrandConfiguration(formData)
  }

  addDistributor(atrribute, value) {
    const skus = [...this.props.product.skus, {sku: '', distributor_id: value.id, distributor: value, product_id: this.props.product.id}]
    this.props.updateProduct(this.props.index, 'skus', skus)
  }

  renderToggleProduct(product) {
    return(
      <p
        style={{
          color: '#D79B81',
          textAlign: 'center',
          cursor: 'pointer',
          marginTop: -15
        }}
        onClick={() => this.setState({sectionOpen: !this.state.sectionOpen})}>{this.state.sectionOpen ? 'collapse' : 'expand'} {product.name} {this.state.sectionOpen ? '^' : 'v'}</p>
    )
  }

  toggleConfiguration(configuration_type_id) {
    const configurationArray = this.props.openConfigurations
    if(configurationArray.includes(configuration_type_id)) {
      const newArray = configurationArray.filter(item => item != configuration_type_id)
      this.props.updateOpenConfigurations(newArray)
    } else {
      configurationArray.push(configuration_type_id)
      this.props.updateOpenConfigurations(configurationArray)
    }
  }

  render() {
     const { configuration_types, product, business_unit, prevProduct } = this.props
     const isRotary = business_unit.name === "Rotary"
     const selected = this.props.product.configuration_type != undefined ? [this.props.configuration_types[this.props.configuration_types.findIndex(item => this.props.product.configuration_type.id === item.id)]] : []
     return (
       <div key={this.props.index}>
         {(product.configuration_type_id === null || prevProduct.configuration_type_id != product.configuration_type_id) && <div style={{marginTop: 20, marginBottom: 20}}>
           <h6 className="inputLabel" style={{borderTop: '1px solid rgba(0,0,0,.1)', paddingTop: 15}} >Configuration</h6>
             <Select
               options={configuration_types}
               searchBy="name"
               labelField="name"
               valueField="id"
               placeholder="Select or type..."
               values={(product.configuration_type_id != null && product.configuration_type != null) ? [configuration_types[configuration_types.findIndex(item => product.configuration_type_id === item.id)]] : []}
               onChange={(value) => this.updateConfiguration('configuration_type', value[0])}
               create={true}
             />
             <div style={{marginBottom: 10}}></div>
             {product.configuration_type_id != null &&
               <FileDropzone
                 size="small"
                 label="Optional: Image"
                 prompt="Click or drag image"
                 updateFile={this.updateConfigurationImage}
                 removeImage={this.removeConfigurationImage}
                 file={this.props.product.configuration_image}
               />
             }
           {product.configuration_type_id != null &&
             <h1
               style={{
                 color: '#D79B81',
                 textAlign: 'center',
                 cursor: 'pointer',
                 marginBottom: 45,
                 marginTop: -20
               }}
               onClick={() => this.toggleConfiguration(product.configuration_type_id)}>Toggle products</h1>}
         </div>}
         <h1>{this.props.openConfigurations.includes(product.configuration_type_id)}</h1>
         {(this.props.openConfigurations.includes(product.configuration_type_id) || product.configuration_type_id === null) && <div>
           <div style={{marginBottom: 10}}>
              {product.configuration_type_id != null &&
               <p
                 onClick={() => this.props.updateProduct(this.props.index, 'configuration_type_id', null)}
                 style={{
                   backgroundColor: 'rgb(215, 155, 129)',
                   color: 'white',
                   padding: 7,
                   borderRadius: 30,
                   float: 'right',
                   marginBottom: -40,
                   fontSize: 10,
                   cursor: 'pointer'
                 }}>Remove from configuration</p>
              }
             <TextInput
               label={"Product Name"}
               placeholder="Name of product"
               value={this.props.product.name}
               attribute={"name"}
               handleChange={this.updateValue}
             />
           </div>
           {this.renderToggleProduct(product)}
           {this.state.sectionOpen && <div>
             {isRotary && <RotaryProduct
               product={product}
               updateValue={this.updateValue}
               shapes={this.props.shapes}
               updateShape={this.updateShape}
               grits={this.props.grits}
               updateGrit={this.updateGrit}
               shanks={this.props.shanks}
               updateShank={this.updateShank}
             />}
             <div
               style={{
                 display: 'flex',
                 justifyContent: 'space-between',
                 flexDirection: 'row',
                 flexWrap: 'wrap'
               }}>
               {this.renderDistributors()}
             </div>
             <div>
               {this.additionalDistributor()}
             </div>
             <FileDropzone
               label="Optional: Product Image"
               prompt="Click or drag to upload your product image"
               updateFile={this.updateFile}
               removeImage={this.removeImage}
               file={this.props.product.product_image_url}
             />
           </div>}
         </div>}
       </div>
     );
   }
}


export default ProductFormSection
