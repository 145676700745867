import axios from 'axios'

let token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
axios.defaults.headers.common['X-CSRF-Token'] = token
axios.defaults.headers.common['Accept'] = 'application/json'

const customerApiHelper = {
  loadCustomers: () => {
    return axios.get('/load_customers')
    .then(response => {
        return response
    })
    .catch(error => console.log(error))
  },
  loadCustomer: (id) => {
    return axios.get(`/load_customer/${id}`)
    .then(response => {
        return response
    })
    .catch(error => console.log(error))
  },
  createOrUpdate: (data) => {
    const options = {
      method: 'post',
      url: '/create_or_update_customer',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: data,
      transformResponse: [(data) => {
        // transform the response
        return data;
      }]
    };
    return axios(options)
    .then(response => {
        return response
    })
    .catch(error => console.log(error))
  },
  quickCustomerUpdate: (data) => {
    const options = {
      method: 'post',
      url: '/quickCustomerUpdate',
      data: data,
      transformResponse: [(data) => {
        return data;
      }]
    };
    return axios(options)
    .then(response => {
        return response
    })
    .catch(error => console.log(error))
  },
  deleteCustomer: (data) => {
    const options = {
      method: 'post',
      url: '/deleteCustomer',
      data: data,
      transformResponse: [(data) => {
        return data;
      }]
    };
    return axios(options)
    .then(response => {
        return response
    })
    .catch(error => console.log(error))
  },
}

export default customerApiHelper
