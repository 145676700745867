import React from 'react';
import { Page, Text, View, Image } from '@react-pdf/renderer';
import BusinessUnitCoverPage from './BusinessUnitCoverPage'
import BrandPage from './BrandPage'

export default React.memo(BusinessUnits, (props, nextProps) => {
  return !nextProps.loaded
})



function BusinessUnits(props)  {
  return(
      <Page wrap style={{paddingBottom: 25}}>
        {props.business_unit.cover_pages_url.map((page, i) => {
            return(
              <BusinessUnitCoverPage
                key={i}
                url={page}
                key={page}
                name={props.business_unit.name}
                generateTOC={props.generateTOC}
              />
            )
          })
        }
        {props.brands.filter(brand => brand.business_unit_id === props.business_unit.id).map((brand, i) => {
            return(
              <View break={i != 0} key={i}>
                <BrandPage
                    brand={brand}
                    business_unit={props.business_unit}
                    catalog={props.catalog}
                    products={brand.products}
                    generateTOC={props.generateTOC}
                    setRef={props.setRef}
                  />
              </View>
            )

        })}
        <View
          fixed
          style={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            height: 40,
            marginTop: 10,
            clear: 'both',
            zIndex: 10
          }}
        >
          <Text
            style={{
              color: '#D4D4D7',
              fontSize: 10,
              textAlign: 'center',
              marginTop: 15,
            }}
            render={({ pageNumber, totalPages }) => (`${pageNumber-2} of ${totalPages-2}`)}
          />
        </View>
      </Page>
    )
}
