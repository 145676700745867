import React from "react"
import PropTypes from "prop-types"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCheck
} from '@fortawesome/free-solid-svg-icons'

const completed = function(props, tab, name) {
  return(
      <div
        onClick={() => props.changeTab(tab)}
        className="indicator_completed">
        <h5><FontAwesomeIcon
          icon={faCheck}
          />
        </h5>
        <h6>{name}</h6>
      </div>
  )
}

const indicator = function(props, tab, name) {
  return(
    <div
      className={props.tab == tab ? "indicator_selected" : "indicator_unselected"}>
      <h5>{tab+1}</h5>
      <h6>{name}</h6>
    </div>
  )
}


const ProgressIndicator = props => (
  <div className="progressIndicator">
    <h2 style={{marginTop: 40, marginBottom: 40}}>Create Catalog</h2>
    <div className="progressSection">
      {props.tab > 0 ? completed(props, 0, "Catalog Details") : indicator(props, 0, "Catalog Details")}
      <div className="indicator_divider"></div>
      {props.tab > 1 ? completed(props, 1, "Product Selection") : indicator(props, 1, "Product Selection")}
      <div className="indicator_divider"></div>
      {props.tab > 2 ? completed(props, 2, "Organize Catalog") : indicator(props, 2, "Organize Catalog")}
      <div className="indicator_divider"></div>
      {props.tab > 3 ? completed(props, 3, "Publish Catalog") : indicator(props, 3, "Publish Catalog")}
    </div>
  </div>
)

export default ProgressIndicator
