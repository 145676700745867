import axios from 'axios'
import qs from "qs";
let token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
axios.defaults.headers.common['X-CSRF-Token'] = token
axios.defaults.headers.common['Accept'] = 'application/json'

const productApiHelper = {
  loadProducts: (data) => {
    const options = {
      method: 'post',
      url: '/load_products',
      data: data,
    };
    return axios(options)
    .then(response => {
        return response
    })
    .catch(error => console.log(error))
  },
  loadBrand: (id) => {
    return axios.get(`/load_brand/${id}`)
    .then(response => {
        return response
    })
    .catch(error => console.log(error))
  },
  loadBrandProducts: (id) => {
    return axios.get(`/load_brand_products/${id}`)
    .then(response => {
        return response
    })
    .catch(error => console.log(error))
  },
  loadBrandFormFilters: () => {
    return axios.get('/load_brand_form_filters')
    .then(filters => {
        return filters
    })
    .catch(error => console.log(error))
  },
  getBrandShapeAndGrits: (data) => {
    const options = {
      method: 'post',
      url: '/get_brand_shape_and_grits',
      data: data,
    };
    return axios(options)
    .then(response => {
        return response
    })
    .catch(error => console.log(error))
  },
  loadFilters: (data) => {
    const options = {
      method: 'post',
      url: '/load_product_table_filters',
      data: data,
    };
    return axios(options)
    .then(response => {
        return response
    })
    .catch(error => console.log(error))
  },
  quickProductUpdate: (data) => {
    const options = {
      method: 'post',
      url: '/quickProductUpdate',
      data: data,
      transformResponse: [(data) => {
        return data;
      }]
    };
    return axios(options)
    .then(response => {
        return response
    })
    .catch(error => console.log(error))
  },
  createOrUpdate: (data) => {
    const options = {
      method: 'post',
      url: '/create_or_update_brand',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: data,
      transformResponse: [(data) => {
        // transform the response
        return data;
      }]
    };
    return axios(options)
    .then(response => {
        return response
    })
    .catch(error => console.log(error))
  },
  updateProductImage: (data) => {
    const options = {
      method: 'post',
      url: '/updateProductImage',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: data,
      transformResponse: [(data) => {
        // transform the response
        return data;
      }]
    };
    return axios(options)
    .then(response => {
        return response
    })
    .catch(error => console.log(error))
  },
  updateBrandConfiguration: (data) => {
    const options = {
      method: 'post',
      url: '/updateBrandConfiguration',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: data,
      transformResponse: [(data) => {
        // transform the response
        return data;
      }]
    };
    return axios(options)
    .then(response => {
        return response
    })
    .catch(error => console.log(error))
  },
  bulkSkuUpload: (data) => {
    const options = {
      method: 'post',
      url: '/bulk_upload_skus',
      data: data,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      validateStatus: function (status) {
        return status >= 200 && status < 400
      }
    };
    return axios(options)
    .then(response => {
        return response
    })
    .catch(error => {
      console.log(error)
      return error
    })
  },
  bulkUpload: (data) => {
    const options = {
      method: 'post',
      url: '/bulk_upload',
      data: data,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      validateStatus: function (status) {
        return status >= 200 && status < 400
      }
    };
    return axios(options)
    .then(response => {
        return response
    })
    .catch(error => {
      console.log(error)
      return error
    })
  },
  createConfiguration: (data) => {
    const options = {
      method: 'post',
      url: '/create_configuration',
      data: data,
      transformResponse: [(data) => {
        // transform the response
        return data;
      }]
    };
    return axios(options)
    .then(response => {
        return response
    })
    .catch(error => console.log(error))
  },
  createShape: (data) => {
    const options = {
      method: 'post',
      url: '/create_shape',
      data: data,
      transformResponse: [(data) => {
        // transform the response
        return data;
      }]
    };
    return axios(options)
    .then(response => {
        return response
    })
    .catch(error => console.log(error))
  },
  createGrit: (data) => {
    const options = {
      method: 'post',
      url: '/create_grit',
      data: data,
      transformResponse: [(data) => {
        // transform the response
        return data;
      }]
    };
    return axios(options)
    .then(response => {
        return response
    })
    .catch(error => console.log(error))
  },
  createShank: (data) => {
    const options = {
      method: 'post',
      url: '/create_shank',
      data: data,
      transformResponse: [(data) => {
        // transform the response
        return data;
      }]
    };
    return axios(options)
    .then(response => {
        return response
    })
    .catch(error => console.log(error))
  },
  checkCanDelete: (data) => {
    const options = {
      method: 'post',
      url: '/check_can_delete',
      data: data,
    };
    return axios(options)
    .then(response => {
        return response
    })
    .catch(error => console.log(error))
  },
  deleteProduct: (data) => {
    const options = {
      method: 'post',
      url: '/delete_product',
      data: data,
    };
    return axios(options)
    .then(response => {
        return response
    })
    .catch(error => console.log(error))
  },
  updateCategories: (data) => {
    const options = {
      method: 'post',
      url: '/updateCategories',
      data: data,
    };
    return axios(options)
    .then(response => {
        return response
    })
    .catch(error => console.log(error))
  },
  deleteCategory: (data) => {
    const options = {
      method: 'post',
      url: '/deleteCategory',
      data: data,
    };
    return axios(options)
    .then(response => {
        return response
    })
    .catch(error => console.log(error))
  },
  updateProductBrand: (data) => {
    const options = {
      method: 'post',
      url: '/updateProductBrand',
      data: data,
    };
    return axios(options)
    .then(response => {
        return response
    })
    .catch(error => console.log(error))
  },
  brandsWithoutProducts: () => {
    const options = {
      method: 'get',
      url: '/brandsWithoutProducts',
    };
    return axios(options)
    .then(response => {
        return response
    })
    .catch(error => console.log(error))
  },
  deleteBrand: (data) => {
    const options = {
      method: 'post',
      url: '/deleteBrand',
      data: data,
    };
    return axios(options)
    .then(response => {
        return response
    })
    .catch(error => console.log(error))
  },
}

export default productApiHelper
