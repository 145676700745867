import axios from 'axios'

let token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
axios.defaults.headers.common['X-CSRF-Token'] = token
axios.defaults.headers.common['Accept'] = 'application/json'

const distributorApiHelper = {
  loadDistributors: () => {
    return axios.get('/load_distributors')
    .then(response => {
        return response
    })
    .catch(error => console.log(error))
  },
  createOrUpdate: (data) => {
    const options = {
      method: 'post',
      url: '/create_or_update_distributor',
      data: data,
      transformResponse: [(data) => {
        // transform the response
        return data;
      }]
    };
    return axios(options)
    .then(response => {
        return response
    })
    .catch(error => console.log(error))
  },
  quickDistributorUpdate: (data) => {
    const options = {
      method: 'post',
      url: '/quickDistributorUpdate',
      data: data,
      transformResponse: [(data) => {
        return data;
      }]
    };
    return axios(options)
    .then(response => {
        return response
    })
    .catch(error => console.log(error))
  },
}

export default distributorApiHelper
