import React, { useState } from "react";
import { usePDF } from '@react-pdf/renderer';
import { Document, Page, Outline } from 'react-pdf/dist/esm/entry.webpack';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons'
import LoadingSection from '../../utils/LoadingSection'
import moment from 'moment'
import FileSaver from 'file-saver'

const SideContentContainer = (props) => {
  const [catalog, updateCatalog] = useState(props.catalog)
  const [brands, updateBrands] = useState(props.brands)

  const renderDistributors = () => {
    const length = catalog.distributors.length
    if(length > 0) {
      return catalog.distributors.map((distributor, i) => {
        if(i === length || i === 0) {
          return `${distributor.name}`
        }
        return `, ${distributor.name} `
      })
    }
  }

  const downloadPdf = () => {
    FileSaver.saveAs(`${catalog.pdf_url}`, `${catalog.name}.pdf`);
  }

  const renderBrands = () => {
    return brands.map((brand, i) => {
      return(
        <div
          key={i}
          style={{
            display: 'flex',
            justifyContent: 'row',
            alignItems: 'center',
            marginBottom: 10,
            height: 50
          }}>
            <img
              src={brand.brand_image_url}
              style={{
                width: 50,
                marginRight: 30,
                objectFit: 'contain',
                maxHeight: 50,
              }}/>
            <p className="bold">{brand.name}</p>
        </div>
      )
    })
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: '3%',
        marginTop: '5%',
        width: '40%',
        alignItems: 'flex-start'
      }}>
      <div style={{maxHeight: '75vh', overflow: 'scroll'}}>
        <h2>{catalog.name}</h2>
        <h6 className="bold" style={{marginTop: 40, marginBottom: 15}}>Catalog name</h6>
        <h6>{catalog.name}</h6>

        <h6 className="bold" style={{marginTop: 40, marginBottom: 15}}>Client</h6>
        <h6>{catalog.customer.name}</h6>

        <h6 className="bold" style={{marginTop: 40, marginBottom: 15}}>Distributor{catalog.distributors.length > 1 ? 's' : ''}</h6>
        <h6>{renderDistributors()}</h6>

        <h6 className="bold" style={{marginTop: 40, marginBottom: 15}}>Date</h6>
        <h6>{moment(catalog.date).format('MMMM, DD YYYY')}</h6>

        <h6 className="bold" style={{marginTop: 40, marginBottom: 15}}>Brands included</h6>
        {renderBrands()}
      </div>
      <div style={{alignSelf: 'flex-end', marginTop: '5%'}}>
        <button
          className="button_primary"
          onClick={downloadPdf}>Download</button>
      </div>
    </div>
  );
}


export default SideContentContainer
