import axios from 'axios'

let token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
axios.defaults.headers.common['X-CSRF-Token'] = token
axios.defaults.headers.common['Accept'] = 'application/json'

const catalogPagesApiHelper = {
  loadAllCatalogPages: () => {
    return axios.get('/load_all_catalog_pages')
    .then(response => {
        return response
    })
    .catch(error => console.log(error))
  },
  addBusinessUnitPage: (data) => {
    const options = {
      method: 'post',
      url: '/add_business_unit_page',
      data: data,
    };
    return axios(options)
    .then(response => {
        return response
    })
    .catch(error => console.log(error))
  },
  removeBusinessUnitPage: (data) => {
    const options = {
      method: 'post',
      url: '/remove_business_unit_page',
      data: data,
    };
    return axios(options)
    .then(response => {
        return response
    })
    .catch(error => console.log(error))
  },
  updateCatalogPage: (data) => {
    const options = {
      method: 'post',
      url: '/update_catalog_page',
      data: data,
    };
    return axios(options)
    .then(response => {
        return response
    })
    .catch(error => console.log(error))
  },
  addCatalogPageImage: (data) => {
    const options = {
      method: 'post',
      url: '/add_catalog_page_image',
      data: data,
    };
    return axios(options)
    .then(response => {
        return response
    })
    .catch(error => console.log(error))
  },
  removeCatalogPageImage: (data) => {
    const options = {
      method: 'post',
      url: '/remove_catalog_page_image',
      data: data,
    };
    return axios(options)
    .then(response => {
        return response
    })
    .catch(error => console.log(error))
  },
  removeCatalogPage: (data) => {
    const options = {
      method: 'post',
      url: '/delete_catalog_page',
      data: data,
    };
    return axios(options)
    .then(response => {
        return response
    })
    .catch(error => console.log(error))
  },


}

export default catalogPagesApiHelper
