import React from "react"
import PropTypes from "prop-types"
import userApiHelper from '../utils/userApiHelper'
import TextInput from '../forms/TextInput'
import UploadProfilePic from './UploadProfilePic'
import PhoneInputField from '../forms/PhoneInputField'
import AddRecordButton from '../forms/AddRecordButton'

class EditProfile extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      user: [],
      loaded: false,
      disabled: false,
      errorMessage: '',
      successMessage: ''
    }
    this.updateValue = this.updateValue.bind(this)
    this.updateFile = this.updateFile.bind(this)
    this.removeImage = this.removeImage.bind(this)
    this.submitForm = this.submitForm.bind(this)
    this.getUser = this.getUser.bind(this)
    this.formResponse = this
  }


  async componentDidMount() {
    await this.getUser()
    this.setState({
      loaded: true,
      errorMessage: '',
      successMessage: ''
    })
  }

  async getUser() {
    let response = await userApiHelper.loadCurrentUser()
    this.setState({
      user: JSON.parse(response.data.user),
    })
  }

  updateValue(attribute, value) {
    const user = {...this.state.user}

    user[attribute] = value
    this.setState({
      user: user
    })
  }

  updateFile(file) {
    this.updateValue('image_url', file)
  }

  removeImage() {
    this.updateValue('image_url', null)
  }

  async submitForm() {
    this.setState({
      errorMessage: '',
      successMessage: ''
    })
    const formData = new FormData()
    formData.append("first_name", this.state.user.first_name)
    formData.append("last_name", this.state.user.last_name)
    formData.append("phone", this.state.user.phone)

    if (typeof this.state.user.image_url === "object") {
      this.state.user.image_url === null ?
        formData.append('image_url', "null")
        : formData.append('image_url', this.state.user.image_url, 'image')
    }
    try {
      let response = await userApiHelper.updateUser(formData)
      this.setState({
        successMessage: 'Your changes are saved. Now go have a great day!'
      })
    } catch(err) {
      this.setState({
        errorMessage: 'Uh oh! Something went wrong and your changes were not saved.'
      })
    }

  }


  render(){
    if(!this.state.loaded) {
      return null
    }
    const {user} = this.state
    return(
      <div>
        <div style={{display: 'flex', flexDirection: 'column', maxWidth: 400, margin: 'auto'}}>
          <h2 style={{marginBottom: 20}}>Settings</h2>
          {this.state.errorMessage != '' &&
            <div style={{
              backgroundColor: '#D79B81',
              color: 'white',
              padding: 10,
              borderRadius: 10
            }}>{this.state.errorMessage}</div>
          }
          {this.state.successMessage != '' &&
            <div style={{
              backgroundColor: '#005AC3',
              color: 'white',
              padding: 10,
              borderRadius: 10
            }}>{this.state.successMessage}</div>
          }
          <UploadProfilePic
            label=""
            prompt=""
            updateFile={this.updateFile}
            removeImage={this.removeImage}
            file={this.state.user.image_url}
          />
          <div style={{display: 'flex', flexDirection: 'row'}}>
            <TextInput
              label="First Name"
              placeholder="First name"
              value={user.first_name === "undefined" ? "" : user.first_name}
              attribute={"first_name"}
              handleChange={this.updateValue}
            />
            <div style={{width: 15}}/>
            <TextInput
              label="Last Name"
              placeholder="Last name"
              value={user.last_name === "undefined" ? "" : user.last_name}
              attribute={"last_name"}
              handleChange={this.updateValue}
            />
          </div>

          <PhoneInputField
            label="Phone"
            placeholder="Phone number"
            value={user.phone === "undefined" ? "" : user.phone}
            attribute={"phone"}
            handleChange={this.updateValue}
          />
          <AddRecordButton
            text={"Update"}
            disabled={this.state.disabled}
            onClick={this.submitForm}
          />
        </div>
      </div>
    )
  }
}

// <TextInput
//   label="Email"
//   placeholder="email"
//   value={user.email === "undefined" ? "" : user.email}
//   attribute={"remail"}
//   handleChange={this.updateValue}
// />

export default EditProfile
