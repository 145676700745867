import React, { useState } from "react";
import { usePDF } from '@react-pdf/renderer';
import { Document, Page, Outline } from 'react-pdf/dist/esm/entry.webpack';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons'
import LoadingSection from '../../utils/LoadingSection'
import ScrollPublishedCatalog from './ScrollPublishedCatalog'
import TopNav from './TopNav'
import SideContentContainer from './SideContentContainer'
import catalogApiHelper from '../../utils/catalogApiHelper'


class ViewCatalog extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loaded: false
    }

    this.getCatalog = this.getCatalog.bind(this)
  }

  componentDidMount() {
    this.getCatalog()
  }

  async getCatalog() {
    let data = {
      catalog_id: this.props.catalogId
    }

    let response = await catalogApiHelper.getCatalog(data)
    this.setState({
      catalog: JSON.parse(response.data.catalog),
      //catalogPages: JSON.parse(response.data.catalog_pages),
      business_units: JSON.parse(response.data.business_units),
      brands: JSON.parse(response.data.brands),
      user: JSON.parse(response.data.user),
      loaded: true
    })
  }

  render() {
    if(!this.state.loaded) { return null }
    return(
      <div style={{
        zIndex: 11,
        backgroundColor: 'white',
        position: 'absolute',
        top: 0,
        width: '100vw',
        height: '100vh',
      }}>
        <TopNav
          backTab={this.props.closeCatalog}
        />
        <div className="create_catalog_container">
          <SideContentContainer
            catalog={this.state.catalog}
            brands={this.state.brands}
          />
          <ScrollPublishedCatalog
            doc={this.state.catalog.pdf_url}
          />
        </div>
      </div>
    )
  }

}

export default ViewCatalog
