import React from "react"
import PropTypes from "prop-types"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

const AddRecordButton = props => (
  <div style={{position: 'relative'}}>
    <button
      className="addRecordButton"
      onClick={() => props.onClick()}
      style={{
        opacity: props.disabled ? .5 : 1,
        backgroundColor: props.alert != true ? '#005AC3' : 'rgba(225, 54, 54, 0.63)',
        position: 'relative'}}
      disabled={props.disabled ? props.disabled : false}
    >
      {props.icon && <FontAwesomeIcon
        icon={faPlus}
        style={{marginRight: 10, color: 'white'}}
      />}
      {props.text}
      {props.disabled}
    </button>
    {props.dropdown}
  </div>
)

export default AddRecordButton;
