import React, {useCallback} from 'react'
import {useDropzone} from 'react-dropzone'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faImage, faTimesCircle } from '@fortawesome/free-solid-svg-icons'


function UploadProfilePic(props) {
  const onDrop = useCallback(acceptedFiles => {
    // Do something with the files
    props.updateFile(acceptedFiles[0])
  }, [])
  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})

  const previewImage = function() {
    return(
      <div style={{
        width:100,
        height:100,
        textAlign: 'center',
        borderRadius: 200
      }}>
        <img
          alt="Preview"
          src={(typeof props.file === "string") ? props.file : URL.createObjectURL(props.file) }
          style={{
            display: 'inline',
            width: 100,
            height: 100,
            borderRadius: 100,
            objectFit: 'cover'
          }}
        />
        <p
          style={{color: 'rgba(0, 90, 195, 1)', fontSize: 12, cursor: 'pointer'}}
          onClick={() => props.removeImage()}>Change photo</p>
      </div>
    )
  }

  const renderUploadImage = function() {
    return(
      <div {...getRootProps()}
        style={{
          width: 100,
          height: 100,
          borderRadius: '100%',
          border: '3px dashed #EEEEEE',
          boxSizing: 'border-box',
          cursor: 'pointer'
        }}>
        <input {...getInputProps()} />
        {
          isDragActive ?
            <div><p>Drop here...</p></div>
            :
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: '#A2A3A6',
                flexDirection: 'column',
                textAlign: 'center',
                padding: 15,
              }}>
              <FontAwesomeIcon
                icon={faImage}
                style={{color: '#A2A3A6', marginBottom: 10, fontSize: 24}}
              />
              <p>{props.prompt ? `${props.prompt}` : "Drag 'n' drop"}</p></div>
        }
      </div>
    )
  }

  return (
    <div style={{marginBottom: 40}}>
    <h6 className="inputLabel">{props.label}</h6>
    {(props.file != null) ?
      previewImage() : renderUploadImage()
    }

    </div>
  )
}

export default UploadProfilePic
