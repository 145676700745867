import React from "react"
import PropTypes from "prop-types"
import FormContainer from '../../forms/FormContainer'
import TextInput from '../../forms/TextInput'
import AddRecordButton from '../../forms/AddRecordButton'
import productApiHelper from '../../utils/productApiHelper'
import DropdownSelect from '../../forms/DropdownSelect'

class ManageCategories extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      categories: [],
      categoryToDelete: [],
      deleteCategoryOpen: false,
      newCategory: '',
      confirmDeleteCategoryOpen: false,
      deletingCategory: false
    }
    this.updateCategory = this.updateCategory.bind(this)
    this.saveCategories = this.saveCategories.bind(this)
    this.deleteCategory = this.deleteCategory.bind(this)
    this.selectNewCategory = this.selectNewCategory.bind(this)
    this.closeDeleteCategory = this.closeDeleteCategory.bind(this)
    this.confirmDeleteCategory = this.confirmDeleteCategory.bind(this)
    this.finalConfirmDeleteCategory = this.finalConfirmDeleteCategory.bind(this)
  }

  componentDidMount() {
    this.setState({
      categories: this.props.categories
    })
  }

  updateCategory(index, value) {
    const categories = this.state.categories
    categories[index].name = value
    this.setState({
      categories: categories
    })
  }

  async saveCategories() {
    let data = {
      categories: this.state.categories
    }

    let response = await productApiHelper.updateCategories(data)
    this.props.updateCategories(this.state.categories)
    this.props.toggleManageCategories()
  }

  deleteCategory(category) {
    this.setState({
      deleteCategoryOpen: true,
      categoryToDelete: category
    })
  }

  closeDeleteCategory() {
    this.setState({
      deleteCategoryOpen: false,
      categoryToDelete: [],
      newCategory: '',
      confirmDeleteCategoryOpen: false
    })
  }

  confirmDeleteCategory() {
    this.setState({
      confirmDeleteCategoryOpen: true
    })
  }


  renderCategories() {
    return this.state.categories.map((category, i) => {
      return(
        <div
          style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}
          key={i}
        >
          <div style={{width: '85%'}}>
            <TextInput
              value={category.name}
              attribute={i}
              handleChange={this.updateCategory}
              label=""
            />
          </div>
          <p
            style={{cursor: 'pointer', color: 'rgb(215, 155, 129)'}}
            onClick={() => this.deleteCategory(category)}>Delete</p>
        </div>
      )
    })
  }

  selectNewCategory(attribute, value) {
    this.setState({
      newCategory: value
    })
  }

  async finalConfirmDeleteCategory() {
    //delete category and reassign products
    this.setState({
      deletingCategory: true
    })
    let data = {
      categoryToDelete: this.state.categoryToDelete,
      newCategory: this.state.newCategory
    }

    let response = await productApiHelper.deleteCategory(data)
    this.props.toggleManageCategories()
  }

  render() {
    if(this.state.confirmDeleteCategoryOpen) {
      return(
        <FormContainer
          onClose={this.closeDeleteCategory}
        >
          <h1 style={{marginBottom: 20}}>You are about to delete the category <span style={{fontFamily: 'GothamBold'}}>{this.state.categoryToDelete.name}</span>. Any brands in this category will be reassign to the category {this.state.newCategory.name}. This can not be undone.</h1>
          <AddRecordButton
            text={`Delete ${this.state.categoryToDelete.name}`}
            disabled={this.state.deletingCategory}
            onClick={this.finalConfirmDeleteCategory}
          />
        </FormContainer>
      )
    } else if(this.state.deleteCategoryOpen) {
      return (
        <FormContainer
          onClose={this.closeDeleteCategory}
        >
          <h1>Delete {this.state.categoryToDelete.name}</h1>
          <DropdownSelect
            selectOption={this.selectNewCategory}
            options={this.state.categories.filter(category => category != this.state.categoryToDelete)}
            optionName={'name'}
            label="If there are brands with this category, what category should they be reassigned?"
            selected={this.state.newCategory.name}
          />
          <AddRecordButton
            text={`Delete ${this.state.categoryToDelete.name}`}
            disabled={this.state.newCategory === ''}
            onClick={this.confirmDeleteCategory}
          />
        </FormContainer>
      )
    }
    return (
      <FormContainer
        onClose={this.props.toggleManageCategories}
      >
        <h1>Manage Categories</h1>
        {this.renderCategories()}
        <AddRecordButton
          text={"Update categories"}
          disabled={false}
          onClick={this.saveCategories}
        />
      </FormContainer>
    )
  }
}


export default ManageCategories
