import React from "react"
import PropTypes from "prop-types"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

const SecondaryButton = props => (
  <div style={{position: 'relative'}}>
  <button
    className="button_secondary"
    onClick={() => props.onClick()}
    style={{
      opacity: props.disabled ? .5 : 1,
      position: 'relative'}}
    disabled={props.disabled ? props.disabled : false}
  >
    {props.icon && <FontAwesomeIcon
      icon={faPlus}
      style={{marginRight: 10, color: 'white'}}
    />}
    {props.text}
    {props.disabled}
  </button>
  {props.dropdown}
  </div>
)

export default SecondaryButton;
