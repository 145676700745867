import React, {useState} from "react"
import PropTypes from "prop-types"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowUp,
  faArrowDown,
  faPen,
  faEllipsisH,
  faArchive,
  faSlidersH,
  faTrash,
  faFile,
  faEye,
  faDownload
} from '@fortawesome/free-solid-svg-icons'
import GlobalFilter from '../tables/GlobalFilter'
import ArchivedFilter from '../tables/ArchivedFilter'
import CustomerFilter from '../tables/CustomerFilter'
import {
  useTable,
  useFilters,
  useGroupBy,
  useSortBy,
  useExpanded,
  usePagination,
  useGlobalFilter,
} from 'react-table'

  function CatalogsTable(props) {
    const data = React.useMemo(
      () => props.catalogs
    )

    const [subFilterIsToggled, setSubFilterIsToggled] = useState(false);
    const toggleSubFilter = () => setSubFilterIsToggled(!subFilterIsToggled);

    const [menuOpen, toggleMenuOpen] = useState('');
    const toggleMenu = React.useCallback((catalog) => toggleMenuOpen(menuOpen => catalog === menuOpen ? '' : catalog));

    const elipsisMenu = function(menuOpen, catalog) {
      if(menuOpen === catalog){
      return (
        <div className="button_dropdown_menu" style={{width: 150}}>
          <div className="button_dropdown_item">
            <FontAwesomeIcon
              icon={faPen}
              style={{
                color: '#005AC3',
                marginLeft: 5,
                padding: 8,
                borderRadius: 4,
              }}
              />
              <p onClick={() => {
                props.unpublishCatalog(catalog)
                props.editRecord(catalog)
              }}>Edit</p>
          </div>
          <div className="button_dropdown_item">
            <FontAwesomeIcon
              icon={faArchive}
              style={{
                color: '#005AC3',
                marginLeft: 5,
                padding: 8,
                borderRadius: 4,
              }}
              />
            <p onClick={() => props.toggleArchiveCatalog(catalog)}>{catalog.archived ? 'Unarchive' : 'Archive'}</p>
          </div>
          {catalog.zip_url != null && <div className="button_dropdown_item">
            <FontAwesomeIcon
              icon={faDownload}
              style={{
                color: '#005AC3',
                marginLeft: 5,
                padding: 8,
                borderRadius: 4,
              }}
              />
            <a href={catalog.zip_url} download>Download Zip</a>
          </div>}
          <div className="button_dropdown_item">
            <FontAwesomeIcon
              icon={faTrash}
              style={{
                color: '#005AC3',
                marginLeft: 5,
                padding: 8,
                borderRadius: 4,
              }}
              />
            <p onClick={() => {
              props.toggleDeleteCatalog(catalog)
              toggleMenu()
            }}>{'Delete'}</p>
          </div>
        </div>
      )}
      else {return null}
    }

    const columns = React.useMemo(
     () => [
       {
         Header: 'Catalog Name',
         accessor: 'name', // accessor is the "key" in the data
       },
       {
         Header: 'Client',
         accessor: 'customer.name',
       },
       {
         Header: 'Status',
         accessor: 'archived', // accessor is the "key" in the data
       },
       {
         Header: 'Status',
         Cell: ({ cell }) => (
           <div style={{
             borderRadius: 40,
             padding: 7,
             width: 100,
             margin: 'auto',

             backgroundColor: cell.row.original.pdf_url != null ? 'rgba(4, 208, 0, 0.2)' : 'rgba(135, 135, 135, 0.2)'
           }}>
            <p style={{
               textAlign: 'center',
               fontFamily: 'GothamBold',
               color: cell.row.original.pdf_url != null ? 'rgba(4, 208, 0, 1)' : 'rgba(135, 135, 135, 1)',
             }}>{cell.row.original.pdf_url != null ? 'Published' : cell.row.original.processing  ? 'Processing' : 'Draft'}</p></div>
         )
       },
       {
          Header: "Actions",
          Cell: ({ cell }) => (
            <div className="table_action_column">

                {cell.row.original.pdf_url != null &&
                  <FontAwesomeIcon
                    icon={faFile}
                    onClick={() => window.open(cell.row.original.pdf_url)}
                    style={{
                      color: '#005AC3',
                      border: '1px solid #EEEEEE',
                      marginLeft: 5,
                      padding: 8,
                      borderRadius: 4,
                    }}
                    />
                  }
                {cell.row.original.pdf_url != null &&
                  <FontAwesomeIcon
                    icon={faEye}
                    onClick={() => props.viewCatalog(cell.row.original)}
                    style={{
                      color: '#005AC3',
                      border: '1px solid #EEEEEE',
                      marginLeft: 5,
                      padding: 8,
                      borderRadius: 4,
                    }}
                    />
                  }
                  {(cell.row.original.processing != true && cell.row.original.pdf_url === null) && <FontAwesomeIcon
                    icon={faPen}
                    onClick={() => props.editRecord(cell.row.original)}
                    style={{
                      color: '#005AC3',
                      border: '1px solid #EEEEEE',
                      marginLeft: 5,
                      padding: 8,
                      borderRadius: 4,
                    }}
                    />
                  }
                  {<FontAwesomeIcon
                    icon={faEllipsisH}
                    onClick={() => toggleMenu(cell.row.original)}
                    style={{
                      color: '#005AC3',
                      marginLeft: 5,
                      padding: 8,
                      borderRadius: 4,
                    }}
                    />}
                  {elipsisMenu(menuOpen, cell.row.original)}
            </div>
          )
        }
     ],
     [menuOpen]
   )

    const tableInstance = useTable(
      {
        columns,
        data,
        initialState: {
          hiddenColumns: ['archived'],
          filters: [{id: "archived", value: "false"}]
        },
      },
      useGlobalFilter,
      useFilters,
      useGroupBy,
      useSortBy,
      useExpanded,
      usePagination,
    )

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
      preGlobalFilteredRows,
      setGlobalFilter,
      state,
      setFilter,
      setHiddenColumns
    } = tableInstance

    const setCustomFilter = function(accessor, value) {
      setFilter(accessor, value)
    }

    const rowStyle = function(row) {
      if(row.original.archived){
        return "trArchived"
      }
      return "trStandard"
    }

    //todo - list of all custoemrs


    return(
      <div style={{width: '100%'}}>
        <div className="tableFilterSection" >

          <div className="mainFilterContainer">
            <GlobalFilter
              preGlobalFilteredRows={preGlobalFilteredRows}
              globalFilter={state.globalFilter}
              setGlobalFilter={setGlobalFilter}
            />
            <FontAwesomeIcon
              icon={faSlidersH}
              onClick={toggleSubFilter}
              style={{
                color: subFilterIsToggled ? '#14467C' : 'white' ,
                backgroundColor: subFilterIsToggled ? 'white' : '#14467C',
                marginLeft: 5,
                padding: 10,
                borderRadius: 10,
                cursor: 'pointer',
                border: '#14467C 2px solid' ,
              }}
              />
          </div>
          {subFilterIsToggled && <div style={{display: 'flex', flexDirection: 'row', alignItems: 'flex-start', margin: 20}}>

            <ArchivedFilter
              setCustomFilter={setCustomFilter}
              filters={state.filters}
            />
            <CustomerFilter
              setCustomFilter={setCustomFilter}
              filters={state.filters}
              customers={props.customers}
            />
          </div>}
        </div>
      <table {...getTableProps()} className="tableStandard">
        <thead>
          {// Loop over the header rows
          headerGroups.map(headerGroup => (
            // Apply the header row props
            <tr {...headerGroup.getHeaderGroupProps()}>
              {// Loop over the headers in each row
              headerGroup.headers.map(column => (
                // Apply the header cell props
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {// Render the header
                  column.render('Header')}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? <FontAwesomeIcon
                          icon={faArrowUp}
                          style={{
                            color: '#D4D4D7',
                            marginLeft: 5,
                          }}
                          />
                        : <FontAwesomeIcon
                          icon={faArrowDown}
                          style={{
                            color: '#D4D4D7',
                            marginLeft: 5,
                          }}
                          />
                      : ''}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        {/* Apply the table body props */}
        <tbody {...getTableBodyProps()}>
          {// Loop over the table rows
          rows.map(row => {
            // Prepare the row for display
            prepareRow(row)
            return (
              // Apply the row props
              <tr {...row.getRowProps()} className={rowStyle(row)}>
                {// Loop over the rows cells
                row.cells.map(cell => {
                  // Apply the cell props
                  return (
                    <td {...cell.getCellProps()}>
                      {// Render the cell contents
                      cell.render('Cell')}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
    )
  }









export default CatalogsTable
